import React from 'react';
import { LABELS as _LABELS } from '../../Language';

const LABELS=_LABELS.Demo;
function Vehicle({ selectedVehicles, toggleSelectVehicle }) {
    const vehicleOptions = [
        { range: '1-5', value: 5 },
        { range: '6-10', value: 10 },
        { range: '11-20', value: 20 },
        { range: '21-50', value: 50 },
        { range: '51-60', value: 60 },
        { range: '61+', value: 61 }
    ];

    return (
        <>
            <h1 className="text-center font-medium text-3xl sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl py-4 sm:py-6 leading-tight sm:leading-snug md:leading-normal">
                {LABELS.NumberofVehiClesyouOperate}
            </h1>

            <section className='flex justify-center items-center'>
                <div className='grid grid-cols-3 gap-4 '>
                    {vehicleOptions.map((option, idx) => (
                        <div
                            key={idx}
                            onClick={() => toggleSelectVehicle(option.value)}
                            className={`relative w-28 h-28 bg-white  font-semibold rounded-full flex justify-center items-center cursor-pointer border ${selectedVehicles.includes(option.value) ? 'border-[#009ddd]' : ''} ${selectedVehicles.includes(option.value) ? 'bg-[#009ddd]' : ''} `}
                        >
                            {option.range}
                            {selectedVehicles.includes(option.value) && (
                                <span className="absolute top-0 right-0 transform translate-x-1/6 -translate-y-1/6 bg-green-500 rounded-full p-1 ">
                                     <svg  fill="none" viewBox="0 0 24 24" stroke="white" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                </span>
                            )}
                        </div>
                    ))}
                </div>
            </section>

        </>
    );
}

export default Vehicle;
