export const LABELS={
    Recruiting:"Recrutement",


    Stepstofollow:"Étapes à suivre :",
    SendTheapplicationlinktodriverviaSMS:"1. Envoyez le lien de l'application au conducteur via SMS.",
    Drivercanopenthelinkinmobileordesktop:"2. Le conducteur peut ouvrir le lien sur un téléphone portable ou un ordinateur de bureau.",
    Drivercanfillapplication:"3. Le conducteur peut remplir l'application.",
    Oncedriversubmittheapplicationownerwillreceivenotification:"4. Une fois que le conducteur soumet l'application, le propriétaire recevra une notification.",
    Ownerwillreviewthedriverapplication:"5. Le propriétaire examinera l'application du conducteur.",
    Vulcansolsmakes_driver_hiring_veryeasy_Driver_Hiring_application_process_is_easy_both_fordriver_and_companyIt_is_designed_togetallthenecessaryinformationofdriverineasywayThisapplicationisavailableforbothmobileanddesktop:"Vulcansols facilite beaucoup le recrutement de conducteurs. Le processus de candidature pour le recrutement de conducteurs est facile tant pour le conducteur que pour l'entreprise. Il est conçu pour obtenir toutes les informations nécessaires du conducteur de manière simple. Cette application est disponible à la fois pour les téléphones portables et les ordinateurs de bureau.",

}
