import React from 'react';
import EldcComplaince from "../../assets/images/EldComplaince.svg";
import GpstrackImage from "../../assets/images/GpsTrack.svg";
import CameraImage from "../../assets/images/Camera.svg";
import TrailerTrackingImage from "../../assets/images/Trailer.svg";
import ComplainceBoardImge from "../../assets/images/ComplainceBoard.svg";
import DispatchBoard from "../../assets/images/DispatchBoard.svg";
import VehicleMaintceImage from "../../assets/images/VehicleMaintce.svg";
import TempretureImage from "../../assets/images/tempreture.svg";
import CustomImage from "../../assets/images/customTruck.svg";
import { LABELS as _LABELS } from '../../Language';

const LABELS=_LABELS.Demo;

function Content({ selectedItems, toggleSelectItem }) {
    const features = [
        { icon: <img src={EldcComplaince} style={{ width: '50px', height: '50px' }} alt='EldComplaince' />, title: LABELS.ELDCompliance },
        { icon: <img src={GpstrackImage} style={{ width: '50px', height: '50px' }} alt='GpsTrack' />, title: LABELS.GPSTracking },
        { icon: <img src={CameraImage} style={{ width: '50px', height: '50px' }} alt='Camera'/>, title:LABELS.SmartDashcam },
        { icon: <img src={TrailerTrackingImage} style={{ width: '50px', height: '50px' }} alt='TrailerTrack'/>, title: LABELS.TrailerTracking },
        { icon: <img src={ComplainceBoardImge} style={{ width: '50px', height: '50px' }} alt='ComplainceBoard' />, title: LABELS.ComplianceBoard },
        { icon: <img src={DispatchBoard} style={{ width: '50px', height: '50px' }}  alt='DispatchBoard'/>, title: LABELS.DispatchBoard },
        { icon: <img src={VehicleMaintceImage} style={{ width: '50px', height: '50px' }} alt='VehicleMaint'/>, title: LABELS.VehicleMaintenance},
        { icon: <img src={TempretureImage} style={{ width: '50px', height: '50px' }} alt='Tempreture'/>, title: LABELS.ReeferTempratureMonitoring },
        { icon: <img src={CustomImage} style={{ width: '50px', height: '50px' }} alt='customImage' />, title: LABELS.CustomTruckingSolution },
    ];

    return (
        <>
            <h1 className="text-center font-medium text-3xl sm:text-4xl md:text-5xl lg:text-4xl xl:text-4xl py-4 sm:py-6 leading-tight sm:leading-snug md:leading-normal">
                {LABELS.Features_or_productsyouinterested_in}
            </h1>
            <section className="py-0">
                <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                    <div className="relative mt-2">
                        <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                            {features.map((item, idx) => (
                                <li
                                    key={idx}
                                    onClick={() => toggleSelectItem(item.title)}
                                    className={`relative bg-white space-y-3 p-2 border rounded-2xl flex flex-col items-center cursor-pointer ${selectedItems.includes(item.title) ? 'border-blue-500' : ''} `}
                                >
                                    <div className="text-indigo-600 ">
                                        {item.icon}
                                    </div>
                                    <h4 className="text-2xl leading-10 text-[#010205] text-center">
                                        {item.title}
                                    </h4>
                                    {selectedItems.includes(item.title) && (
                                        <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-green-500 rounded-full p-1 ">
                                            <svg  fill="none" viewBox="0 0 24 24" stroke="white" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </span>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Content;
