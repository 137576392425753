export const LABELS={
    carrier:"Transportista",
    eldConnect:"Conexión ELD",
    LoadTracking:"Seguimiento de Carga",
    LoadRouting:"Enrutamiento de Carga",
    Ifta:"IFTA",
    Rates:"Tarifas",
    Settlements:"Liquidaciones",
    Receivables:"Cuentas por Cobrar",
    WayMessaging:"Mensajería Bidireccional",
    Notification:"Notificación",
    LoadConfirmation:"Confirmación de Carga",
    ProofOfDelivery:"Comprobante de Entrega",
    LoadBoard:"Tablero de Carga",
    letsTalk:"Hablemos",

    Letconnectyour_existingELD_to_Vulcansols_to_track_HOS_location_at_realtime: "Conectemos su ELD existente a Vulcansols para rastrear HOS y ubicación en tiempo real.",
    OneClick_enabled_disable_live_load_tracking:"Habilitar/deshabilitar el seguimiento de carga en vivo con un clic.",
    Automaticallycalculates_empty_and_loadedmile_based_on_vehicle_current_location_shipper_and_consignee:"Calcula automáticamente las millas vacías y cargadas según la ubicación actual del vehículo, el remitente y el destinatario.",
    Automatically_calculates_states_miles_for_you_IFTA_tax_alongWithdispatchwith_thehelpofVehicleLocationShipperAndConsignee:"Calcula automáticamente las millas estatales para su impuesto IFTA junto con el despacho, con la ayuda de la ubicación del vehículo, el remitente y el destinatario.",
    StayCompetitiveWithourSpotMarketRateAnalyzerfor_a_comprehensiveLook_at_industry_trends:"Manténgase competitivo con nuestro analizador de tarifas de mercado spot para obtener una visión completa de las tendencias de la industria.",
    Createdriversettlements_Paychecks_with_one_click:"Cree liquidaciones de conductores (cheques de pago) con un clic.",
    DecideWhenAndHowYouWantTogetPaid_withoutthe_factoringservice_contracts_or_hiddenfees:"Decida cuándo y cómo desea que le paguen, sin contratos de servicios de factoraje ni tarifas ocultas.",
    WayMessaging_with_drivers_carriers_via_mobile_app_or_fall_backtoSMS_chat:"Mensajería bidireccional con conductores/transportistas a través de la aplicación móvil o mediante chat SMS de respaldo.",
    WebandPushNotificationforloads_at_shippercheckinout_deliveryetc:"Notificación web y por push para cargas, en el punto de carga, check in/out, entrega, etc.",
    Use_the_dependable_load_tracking_software_giving_you_handsfree_checkcalls:"Utilice el software confiable de seguimiento de carga que le proporciona llamadas de verificación manos libres.",
    MakingProofOfDeliveryseamlessdrivercandofromVulcansolsmobileappalongwithdocumentupload:"Haciendo la entrega del comprobante de entrega perfecta, el conductor puede hacerlo desde la aplicación móvil de Vulcansols junto con la carga de documentos.",
    Withoneclickpostavailabletruckafterdelivery:"Con un clic, publique el camión disponible después de la entrega.",

    Vulcansols_is_well_designed_TMS_for_carrier_to_ease_and_automate_day_to_day_activitiesStay_on_top_of_your_trucking_business_with_our_smart_artificialintelligencebasedcloudsolution:"Vulcansols es un TMS bien diseñado para transportistas para facilitar y automatizar las actividades diarias. Manténgase al tanto de su negocio de transporte con nuestra solución en la nube inteligente basada en inteligencia artificial.",
}
