import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import LoadInfoImage from "../../../assets/images/LoadInfo.png";
import { LABELS as _LABELS, LANGS } from '../../../Language';

const LABELS=_LABELS.DispatchPage

function LoadInformation() {
    const [showMore, setShowMore] = useState(false);

    const handleToggle = () => {
        setShowMore(!showMore);
    };
   
  return (
    <section className="py-14">
    <div className="max-w-screen-xl mx-auto px-4 text-gray-600 gap-x-12 items-start justify-between lg:flex md:px-8">
        <div className="sm:hidden lg:block lg:max-w-xl">
            <img src={LoadInfoImage} className="rounded-lg" alt="" />
        </div>
        <div className="mt-6 gap-12 sm:mt-0 md:flex lg:block">
            <div className="max-w-2xl">
                <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                {LABELS.DetailedLoadInformation}
                </h3>
                <p className="mt-3 max-w-xl">
               {LABELS.EachLoadmayhave_a_lot_of_data_toManageAndWiththehelpofVulcansolsSoftwareyoucanseedetailedinformationforeachload}
                </p>
                <ul className='mt-6 list-none flex-col space-y-6'>
                            <li className='flex items-center'>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                {LABELS.shipper_and_consingeeInformation}
                            </li>
                            <li className='flex items-center'>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                {LABELS.pickup_and_deliverylocation}
                            </li>
                            {showMore && (
                                <>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                        {LABELS.Route_Infomation}
                                    </li>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                        {LABELS.driver_and_vehicleInfomation}
                                    </li>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                        {LABELS.loadStatusUpdates}
                                    </li>
                                   
                                </>
                            )}
                        </ul>
                        <button
                            onClick={handleToggle}
                            className="mt-6 px-6 py-3.5 text-white bg-black rounded-full duration-150 hover:bg-indigo-500 active:bg-indigo-700"
                        >
                            {showMore ? LABELS.showLess : LABELS.seeMore}
                            <FontAwesomeIcon icon={showMore ? faArrowDown : faArrowRight} className="ml-2" />
                        </button>
            </div>
          
        </div>
    </div>
</section>
  )
}

export default LoadInformation