export const LABELS={
    eldIntegration:"ELD Integrations",
    SignUp:"Sign Up",

    compliance:"Compliance",
    Agreements:"Agreements",


    OurtrustedELDPartner_is_Smartelds:"Our trusted ELD partner is Vulcansols.",
    DriverTracking:"Driver Tracking",
    GenerateIFTAReport:"Generate IFTA Report",
    SmartDashcam:"Smart Dashcam",
    VehicleDiagnostics:"Vehicle Diagnostics",
    AssetTracking:"Asset Tracking",
    VehicleInspections:"Vehicle Inspections",

    DriverAgreement:"Driver Agreement",
    BrokerageAgreement:"Brokerage Agreement",
    ShipperConsigneeAgreement:"Shipper / Consignee Agreement",

    Vulcansols_has_integrated_leading_with_most_of_ELD_service_providers_Our_integration_helps_you_connect_to_your_ELD_provider_to_monitor_drivers_and_equipments_in_real_time:"Vulcansols has integrated leading with most of ELD service providers. Our integration helps you connect to your ELD provider to monitor drivers and equipments in real time.",

    
    VulcansolsSafety_not_only_offers_solutions_to_assist_you_in_maintaining_compliance_but_we_deliver_the_news_you_need_to_know_to_stay_in_the_know:"Vulcansols Safety not only offers solutions to assist you in maintaining compliance but we deliver the news you need to know to stay in the know. Vulcansols is designed to improve your freight and distribution channels by keeping the deliveries you manage under your control. Vulcansols helps you keep your dispatchers, equipment, and loads all on the same page. Vulcansols helps manage and track those deliveries for Carriers, Brokers, 3rd Party Logistics (3PLs), Suppliers and Manufacturers. Vulcansols is loaded with features that help you compete in today's logistics world by offering a higher level of service than your competitors. Created by people from the trucking industry that assure reliability, Vulcansols is continually improving to meet the needs of today's high demand Trucking and Brokerage professionals. Vulcansols is designed for customers advanced features of Tracking and Remote Immobilization that keeps the track of various features of vehicles like ignition, speed over limit etc.",
    Agreements_play_a_vital_role_in_the_trucking_business:"Agreements play a vital role in the trucking business. Agreements help you to avoid any potential dispute and also creates clarity in your business. Vulcansols Software provides you ready-made agreements that can help you in every aspect of your business. Enter the particulars and the agreement is ready with just one click of a button. Some of the agreement types are listed below:",

}


