export const LABELS={
    Streamlined_onboarding_for_a_seamless_start_with :"Streamlined onboarding for a seamless start with ",
    VulcansolsEld:"Vulcansols Eld",
    RequestDemo:"Request a Demo",


    accelerated_workforce_onboarding: "40% accelerated workforce onboarding",
    Speed_up_time_toProficiency: "Speed up time to proficiency",
    Consistent_training_for_every_shift:"Consistent training for every shift",
    Empower_workers_with_confidence:"Empower workers with confidence",
    Always_on_accessible_training:"Always-on accessible training",
    Training_with_real_work_examples:"Training with real work examples",
    Hear_from_our_customers:"Hear from our customers",

    upleveltheonboardingProcesstoensurenewhirefeelconfident:"Uplevel the onboarding process to ensure new hires feel confident",
    keep_new_hire_engaged_from_the_start_with_dynamic_etc:"Keep new hires engaged from the start with dynamic onboarding videos that capture - and keep - their attention.",

    ourCustomerExprienceSpeakVolumeAboutTheOfOurEldSolution:"Our customers' experiences speak volumes about the value of our ELD solution. Here's what they have to say:",
   
    Readyto_innovate_knowledge_retention_in_your_organization:"Ready to innovate knowledge retention in your organization",
    Request_a_demo_today_to_experience:"Request a demo today to experience the power of Vulcansols firsthand. For inquiries and consultations, reach out to us – we're here to help you thrive through knowledge preservation.",


     
      
}