export const LABELS={
    home: "Accueil",
    dispatch: "Envoi de l'IA",
    safety: "Sécurité du Conducteur",
    compliance: "Conformité",
    telematic: "Télémétrie",
    tracking: "Suivi de Flotte",
    resources: "Ressources",

    dashcam:"Sécurité du Conducteur",
    pricing:"Tarification",
    gps:"Suivi",
    eld:"Conformité",
    demo:"Démo",
    login:"Connexion",


    You_created_your_company_let_us_create_your_brand:"Conduisez le transport vers l'avant avec une technologie innovante",
    DrivingYourTransportBusinessForwardwithSmartTechnologySolutions:"Faites progresser votre entreprise de transport grâce à des solutions technologiques intelligentes.",
    PrecisionDispatch_PoweredbyAi:"Répartition de précision, optimisée par l'IA : redéfinir l'efficacité",
    Start_your_Free_Trail_Now:"Commencez votre essai gratuit maintenant",
    Request_Demo:"Demander une démo",
    OurTrustedPartners:"Nos partenaires de confiance",
    An_All_inOneTrucking:"Une Solution Tout-en-un pour le Transport Routier",
    ManagementSoftware:"Logiciel de Gestion",
    Solution:"Solution",
    Our_mission_is_to_revolutionize_the_transport_industry_through_innovative_technology_solutions_enhancing_efficiency_safety_and_sustainability:"Notre mission est de révolutionner l'industrie du transport grâce à des solutions technologiques innovantes, améliorant l'efficacité, la sécurité et la durabilité. Nous nous engageons à autonomiser nos clients avec des outils de pointe et un service exceptionnel pour stimuler leur réussite et façonner l'avenir du transport.",
    Firecamp_lets_you_choose_your_preferred_client_for_your_API_tack_to_cut_down_on_complexitiesAndTime:"Notre Solution Tout-en-un pour la Gestion du Transport Routier est conçue pour répondre aux besoins complets des entreprises de transport moderne. Des petites flottes aux opérations à grande échelle, notre plateforme intègre des fonctionnalités avancées pour rationaliser et optimiser chaque aspect de la gestion de flotte, garantissant efficacité, conformité et rentabilité.",
    seeMore:"Voir Plus",
    learnMore:"En savoir plus",


    OurEngineeringTeam:"Notre équipe d'ingénierie",
    AlogisticsDomainExpertEngineeringTeamis_a_Specialized_group_of_professionals_focused_on_designing_developing_and_implementing_solutions_within_the_Logistics_and_supplyChainManagementDomain:"Une équipe d'ingénierie d'experts du domaine logistique est un groupe spécialisé de professionnels axés sur la conception, le développement et la mise en œuvre de solutions dans le domaine de la logistique et de la gestion de la chaîne d'approvisionnement.",


    carriers:"Transporteurs",
    brokerage:"Courtage",
    recruiting:"Recrutement",
    accounting:"Comptabilité",
    gpsTracking:"Suivi GPS",
    Compliance:"Conformité",
    MintRead:"Lecture de 5 minutes",


    Stay_organized_Staypaid:"Restez organisé. Restez payé.",
    StreamlineYourbackOffice:"Optimisez votre back-office.",
    QuickDriverHiring:"Embauche rapide de conducteurs",
    OneClickPayablesAndReceivables:"Paiements et créances en un clic.",
    EasilyTrackYourTractororDriver:"Suivez facilement votre tracteur ou conducteur.",
    Stay_on_top_of_compliance:"Restez en conformité.",


    VulcansolsTelemetrics:"Télémétrie Vulcansols",
    letsTalk:"Parlons",
    fullName:"Nom complet *",
    emailAddress:"Adresse e-mail *",
    phone:"Téléphone *",
    How_can_i_assist_you:"Comment puis-je vous aider ? *",
    SendMessage:"Envoyer le message",
    Were_here_to_help_and_answer_any_question_you_might:"Chez Vulcansols Inc., nous nous engageons à vous fournir le meilleur support pour nos solutions ELD. Si vous avez des questions ou avez besoin d'aide, n'hésitez pas à nous contacter",


    MobileApplications:"Applications Mobiles",
    Easily_manage_your_business_with_our_mobile_apps_Access_all_your_loads_expenses_etc_on_theLiveDispatch_App:"Gérez facilement votre entreprise avec nos applications mobiles. Accédez à toutes vos charges, dépenses, etc. sur l'application Live Dispatch. Obtenez des mises à jour en temps réel via l'application mobile et restez connecté à votre entreprise. Live Dispatch est disponible pour iOS et Android.",
    Logistics_dispatch_Software_offers_a_wide_array_of_additional_benefits_beyond_its_core_functionality_of_managing_dispatch_operations:"Le logiciel de dispatch logistique offre une large gamme d'avantages supplémentaires au-delà de sa fonctionnalité principale de gestion des opérations de dispatch.",


    ExtraBenifits:"Avantages Supplémentaires",
    oneMonthfreeTrail:"Essai gratuit d'un mois",
    planAccordingYourNeeds:"Planifiez selon vos besoins",
    AnyTimeCancelation:"Annulation à tout moment",
    NoCreditCardDetailsRequired:"Aucun détail de carte de crédit requis",
    AndroidIos_Apps_for_drivers:"Applications Android et Ios pour les conducteurs",


    AvailableForMobile:"Disponible pour mobile (Android, iOS), PC (Windows, Mac) et tablette.",
    MultipleUserWithDifferentRoleandAcces:"Plusieurs utilisateurs avec des rôles et des accès différents.",
    NoAdditionalSoftwareRequire:"Aucun logiciel supplémentaire n'est requis.",
    AccesFromAnyWhere:"Accès de n'importe où.",


    CustomerServices:"Service Clientèle",
    EducationTraining:"Éducation et Formation",
    KnowledgeBase:"Base de Connaissances",
    DeveloperPortal:"Portail des Développeurs",
    Blog:"Blog",
    CustomerStories:"Témoignages de Clients",
    Guide:"Guide",
    ProductTours:"Visites de Produit",
    Aboutus:"À Propos de Nous",
    Careers:"Carrières",
    Webinaar:"Webinaire",

    Support:"Soutien",
    Resources:"Ressources",
    Event:"Événement",
    Contactus:"Contactez-nous",

    Manage_everything_from_IFTA_to_driver_ownerOperatorSettlementsAnd_invoicing_allWithin_one_web_platform:"Gérez tout, de l'IFTA aux règlements des conducteurs, des propriétaires exploitants et de la facturation, tout cela dans une seule plateforme web.",
    Manage_loads_carrier_payables_agent_offices_payroll_and_invoicing_to_be_professional_and_profitable:"Gérez les charges, les paiements des transporteurs, les bureaux d'agents, la paie et la facturation pour être professionnel et rentable.",
    GetAlltheNecessaryInformationof_a_driverandmanageallTheApplicationsOnASinglePage:"Obtenez toutes les informations nécessaires d'un conducteur et gérez toutes les applications sur une seule page.",
    After_creating_a_load_the_invoice_is_ready_or_youAnd_with_assigning_driver_or_carrier_settlement_is_ready:"Après avoir créé une charge, la facture est prête pour vous et avec l'affectation du conducteur ou le règlement du transporteur est prêt.",
    You_an_easily_connect_your_existing_ELD_GPS_racker_to_get_ealtime_tracking_nformation_or_use_our_mobile_app_to_rack_driver:"Vous pouvez facilement connecter votre ELD existant, GPS Tracker pour obtenir des informations de suivi en temps réel ou utiliser notre application mobile pour suivre le conducteur.",
    Vulconsols_Safety_not_only_offers_solutions_to_assist_you_in_maintaining_compliance_but_we_deliver_the_news_you_need_to_know:"Vulconsols Safety n'offre pas seulement des solutions pour vous aider à maintenir la conformité, mais nous vous livrons les nouvelles dont vous avez besoin de savoir !"

}
