export const LABELS={
    home: "Home",
    dispatch: "Envío de IA",
    safety: "Seguridad del conductor",
    compliance: "Cumplimiento",
    telematic: "Telematics",
    tracking: "Seguimiento de flotas",
    resources: "Recursos",

    dashcam:"Driver Safety",
    pricing:"Pricing",
    gps:"Tracking",
    eld:"Compliance",
    demo:"Manifestación",
    login:"Acceso",


    You_created_your_company_let_us_create_your_brand:"Impulsando el transporte hacia adelante con tecnología innovadora",
    DrivingYourTransportBusinessForwardwithSmartTechnologySolutions:"Impulsando su negocio de transporte con soluciones tecnológicas inteligentes.",
    PrecisionDispatch_PoweredbyAi:"Despacho de precisión, impulsado por IA: redefiniendo la eficiencia",
    Start_your_Free_Trail_Now:"Comience su recorrido gratuito ahora",
    Request_Demo:"Solicitar demostración",
    OurTrustedPartners:"Nuestros socios de confianza",
    An_All_inOneTrucking:"Un camión todo en uno",
    ManagementSoftware:"Software de gestión",
    Solution:"Solución",
    Our_mission_is_to_revolutionize_the_transport_industry_through_innovative_technology_solutions_enhancing_efficiency_safety_and_sustainability:"Nuestra misión es revolucionar la industria del transporte a través de soluciones tecnológicas innovadoras, mejorando la eficiencia, la seguridad y la sostenibilidad. Estamos comprometidos a brindar a nuestros clientes herramientas de vanguardia y un servicio excepcional para impulsar su éxito y dar forma al futuro del transporte.",
    Firecamp_lets_you_choose_your_preferred_client_for_your_API_tack_to_cut_down_on_complexitiesAndTime:"Nuestra solución de software de gestión de camiones todo en uno está diseñada para satisfacer las necesidades integrales de las empresas de transporte modernas. Desde flotas pequeñas hasta operaciones a gran escala, nuestra plataforma integra funciones avanzadas para agilizar y optimizar cada aspecto de la gestión de flotas, garantizando eficiencia, cumplimiento y rentabilidad.",
    seeMore:"Ver más",
    learnMore:"Learn More",


    OurEngineeringTeam:"Nuestro equipo de ingeniería",
    AlogisticsDomainExpertEngineeringTeamis_a_Specialized_group_of_professionals_focused_on_designing_developing_and_implementing_solutions_within_the_Logistics_and_supplyChainManagementDomain:"Un equipo de ingeniería experto en el ámbito de la logística es un grupo especializado de profesionales centrados en diseñar, desarrollar e implementar soluciones dentro del ámbito de la logística y la gestión de la cadena de suministro.",


    carriers:"Transportistas",
    brokerage:"Corretaje",
    recruiting:"Reclutamiento",
    accounting:"Contabilidad",
    gpsTracking:"Seguimiento GPS",
    Compliance:"Cumplimiento",
    MintRead:"5 minutos de lectura",


    Stay_organized_Staypaid:"Manténgase organizado. Manténgase pagado.",
    StreamlineYourbackOffice:"Optimice su back office.",
    QuickDriverHiring:"Contratación rápida de conductores",
    OneClickPayablesAndReceivables:"Cuentas por pagar y por cobrar con un solo clic.",
    EasilyTrackYourTractororDriver:"Rastree fácilmente su tractor o conductor.",
    Stay_on_top_of_compliance:"Manténgase al tanto del cumplimiento.",


    VulcansolsTelemetrics:"Vulcansols Telemétricos",
    letsTalk:"Hablemos",
    fullName:"Nombre completo *",
    emailAddress:"Dirección de correo electrónico *",
    phone:"Teléfono *",
    How_can_i_assist_you:"¿Cómo puedo ayudarle? *",
    SendMessage:"Enviar mensaje",
    Were_here_to_help_and_answer_any_question_you_might:"En Vulcansols Inc., estamos comprometidos a brindarle el mejor soporte para nuestras soluciones ELD. Si tiene alguna pregunta o necesita ayuda, no dude en comunicarse",


    MobileApplications:"Aplicaciones móviles",
    Easily_manage_your_business_with_our_mobile_apps_Access_all_your_loads_expenses_etc_on_theLiveDispatch_App:"Administre fácilmente su negocio con nuestras aplicaciones móviles. Accede a todas tus cargas, gastos, etc. en la App Live Dispatch. Obtenga actualizaciones en tiempo real mediante la aplicación móvil y manténgase conectado con su negocio. Live Dispatch está disponible para iOS y Android.",
    Logistics_dispatch_Software_offers_a_wide_array_of_additional_benefits_beyond_its_core_functionality_of_managing_dispatch_operations:"El software de despacho logístico ofrece una amplia gama de beneficios adicionales más allá de su funcionalidad principal de gestionar las operaciones de despacho.",


    ExtraBenifits:"Beneficios adicionales",
    oneMonthfreeTrail:"Un mes de prueba gratis",
    planAccordingYourNeeds:"Planifica según tus necesidades",
    AnyTimeCancelation:"Cancelación en cualquier momento",
    NoCreditCardDetailsRequired:"No se requieren datos de tarjeta de crédito",
    AndroidIos_Apps_for_drivers:"Aplicaciones de Android e iOS para conductores",


    AvailableForMobile:"Disponible para dispositivos móviles (Android, iOS), PC (Windows, Mac) y tabletas.",
    MultipleUserWithDifferentRoleandAcces:"Múltiples usuarios con roles y accesos diferentes.",
    NoAdditionalSoftwareRequire:"No se requiere software adicional.",
    AccesFromAnyWhere:"Acceso desde cualquier lugar.",


    CustomerServices:"Servicio al Cliente",
    EducationTraining:"Educación y Capacitación",
    KnowledgeBase:"Base de Conocimientos",
    DeveloperPortal:"Portal del Desarrollador",
    Blog:"Blog",
    CustomerStories:"Historias de Clientes",
    Guide:"Guía",
    ProductTours:"Recorridos del Producto",
    Aboutus:"Acerca de Nosotros",
    Careers:"Carreras",
    Webinaar:"Webinario",

    Support:"Apoyo",
    Resources:"Recursos",
    Event:"Evento",
    Contactus:"Contacta con nosotras",

    Manage_everything_from_IFTA_to_driver_ownerOperatorSettlementsAnd_invoicing_allWithin_one_web_platform:"Administre todo, desde IFTA hasta conductores, acuerdos con operadores propietarios y facturación, todo dentro de una plataforma web.",
    Manage_loads_carrier_payables_agent_offices_payroll_and_invoicing_to_be_professional_and_profitable:"Administre cargas, cuentas por pagar de transportistas, oficinas de agentes, nómina y facturación para ser profesional y rentable.",
    GetAlltheNecessaryInformationof_a_driverandmanageallTheApplicationsOnASinglePage:"Obtén toda la información necesaria de un conductor y gestiona todas las aplicaciones en una sola página.",
    After_creating_a_load_the_invoice_is_ready_or_youAnd_with_assigning_driver_or_carrier_settlement_is_ready:"Después de crear una carga, la factura está lista para usted y con la asignación del conductor o transportista la liquidación está lista.",
    You_an_easily_connect_your_existing_ELD_GPS_racker_to_get_ealtime_tracking_nformation_or_use_our_mobile_app_to_rack_driver:"Puede conectar fácilmente su rastreador GPS ELD existente para obtener información de seguimiento en tiempo real o utilizar nuestra aplicación móvil para rastrear al conductor.",
    Vulconsols_Safety_not_only_offers_solutions_to_assist_you_in_maintaining_compliance_but_we_deliver_the_news_you_need_to_know:"Vulconsols Safety no solo ofrece soluciones para ayudarlo a mantener el cumplimiento, sino que también le brindamos las noticias que necesita saber."

}
