import * as cookie from "react-cookie";

const cookies = new cookie.Cookies();

let language = "en";

try {
  language = cookies.get("language", {
    path: "/",
  });

  if (!language || language === "undefined") {
    // const _language = "en-US";
    // const _language = "es-US";
    const _language = navigator.language || "";
    language = _language.split("-")[0];
  }
} catch (e) {}

export function updateLang(_lang) {
  cookies.set("language", _lang, {
    path: "/",
  });
  window.location.reload();
}

export const LANGS = [
  {
    id: "en",
    name: "English",
  },
  {
    id: "es",
    name: "Español",
  },
  {
    id: "fr",
    name: "French",
  },
];

if (language !== "en" && language !== "es" && language !== "fr") {
  language = "en";
}

export const selectedLang = language;

function getLabels(language) {
    const HomePage = require(`./${language}/HomePage`);
    const DispatchPage = require(`./${language}/DispatchPage`);
    const FleetTracking = require(`./${language}/FleetTracking`);
    const Compliance = require(`./${language}/Compliance`);
    const Demo = require(`./${language}/Demo`);
    const Carrier = require(`./${language}/Carrier`);
    const Brokerage = require(`./${language}/Brokerage`);
    const Recruiting = require(`./${language}/Recruiting`);
    const Accounting = require(`./${language}/Accounting`);
    const DriverSafety = require(`./${language}/DriverSafety`);
    const CustomerService = require(`./${language}/CustomerService`);
    const AboutPage = require(`./${language}/AboutUsPage`);
    const HelpSupport = require(`./${language}/HelpSupport`);
    const params = {
        language: language,
        HomePage: HomePage.LABELS,
        DispatchPage: DispatchPage.LABELS,
        FleetTracking: FleetTracking.LABELS,
        Compliance: Compliance.LABELS,
        Demo: Demo.LABELS,
        Carrier:Carrier.LABELS,
        Brokerage:Brokerage.LABELS,
        Recruiting:Recruiting.LABELS,
        Accounting:Accounting.LABELS,
        DriverSafety:DriverSafety.LABELS,
        CustomerService:CustomerService.LABELS,
        AboutPage:AboutPage.LABELS,
        HelpSupport:HelpSupport.LABELS
    };
    return params;
}
export const LABELS = getLabels(language);
