import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { LANGS, selectedLang, updateLang } from '../../Language';

const LanguageSelector = () => {
  const handleChange = (event) => {
    updateLang(event.target.value);
  };

  return (
    <div className="flex items-center space-x-2 select-transparent">
      <FontAwesomeIcon icon={faGlobe} className="text-white text-xl" />
      <select
        className="select-transparent w-full max-w-20 border-none  text-white bg-black"
        value={selectedLang}
        onChange={handleChange}
      >
        {LANGS.map(lang => (
          <option key={lang.id} value={lang.id}>{lang.name}</option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
