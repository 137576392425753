import React from "react";
import smartEldlogo from "../../assets/images/logo-vulconsols.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Iphonelogo from "../../assets/images/images.png";
import { LABELS as _LABELS } from "../../Language";

const LABELS = _LABELS.Compliance;

function Compliance() {
  return (
    <>
      <section className="py-14 ">
        <div className="max-w-screen-xl mx-auto px-4 text-center md:px-8 flex  flex-col gap-2.5">
          <div className="max-w-xl mx-auto">
            <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
              {`FMCSA registered ELD`}
            </h3>
            <h4 className="text-gray-800 mt-3">
              {`Vulcansols empowers you to manage compliance more effectively.`}
            </h4>
            <p className="text-gray-600 mt-3">
              {`Manage HOS, DVIR, IFTA, and FMCSA all in one place with Vulcansols.`}
            </p>
          </div>
          <section className="py-14 border bg-[#FFFFFF] rounded-3xl ">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 gap-x-12 items-center justify-center  lg:flex md:px-8">
              <div className="mt-6 gap-12 sm:mt-0 md:flex lg:block mb-4 sm:flex justify-center">
                <div className="max-w-2xl">
                  {/*<h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                    {LABELS.OurtrustedELDPartner_is_Smartelds}
                  </h3>*/}
                  <div className="flex justify-center pt-3">
                    <img src={smartEldlogo} alt="smartEld" className="w- 52 " />
                  </div>
                  <div className="flex justify-center">
                    <ul className="list-none flex-col space-y-6  w-60 p-6">
                      <li className="flex items-center">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="mr-2 text-2xl"
                        />
                        {LABELS.DriverTracking}
                      </li>
                      <li className="flex items-center">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="mr-2 text-2xl"
                        />
                        {LABELS.GenerateIFTAReport}
                      </li>
                      <li className="flex items-center">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="mr-2 text-2xl"
                        />
                        {LABELS.SmartDashcam}
                      </li>
                      <li className="flex items-center">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="mr-2 text-2xl"
                        />
                        {LABELS.VehicleDiagnostics}
                      </li>
                      <li className="flex items-center">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="mr-2 text-2xl"
                        />
                        {LABELS.AssetTracking}
                      </li>
                      <li className="flex items-center">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="mr-2 text-2xl"
                        />
                        {LABELS.VehicleInspections}
                      </li>
                    </ul>
                  </div>

                  <button className="px-6 py-3.5 text-white bg-black rounded-full duration-150 hover:bg-indigo-500 active:bg-indigo-700">
                    {LABELS.SignUp}
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                  </button>
                </div>
              </div>
              <div className="lg:block lg:max-w-xl sm:flex justify-center">
                <img src={Iphonelogo} alt="iPhone Logo" className="w-90 " />
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

export default Compliance;
