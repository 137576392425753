export const LABELS={
    aboutVulcanslsInc:"À propos de Vulcansols Inc.",
    At_Vulcansols_Incwe_are_dedicated_to_revolutionizing_fleet_management_solutions_acrossThe_USA:"Chez Vulcansols Inc., nous nous consacrons à révolutionner les solutions de gestion de flotte à travers les États-Unis. Spécialisés dans des services complets incluant la gestion de la conformité, le suivi GPS, des solutions comptables robustes et la gestion des transporteurs, nous permettons aux entreprises d'optimiser leurs opérations avec une technologie de pointe et une expertise inégalée.",
    OurCommitment_to_Excellence:"Notre Engagement envers l'Excellence",
    With_a_deep_commitment_to_innovation_and_reliability:"Avec un engagement profond envers l'innovation et la fiabilité, Vulcansols Inc. se place à l'avant-garde de l'industrie en fournissant des solutions sur mesure qui répondent aux besoins uniques de chaque client. Notre approche intégrée garantit des opérations de flotte sans faille, améliore la conformité réglementaire et fournit des informations en temps réel grâce à une technologie avancée de suivi GPS.",
    WhyChooseVulcansolsInc: "Pourquoi Choisir Vulcansols Inc. ?",
    ComprehensiveFleetManagement:"Gestion Complète de Flotte :",
    From_vehicle_tracking_to_maintenance_scheduling_we_streamline_operations_to_maximize_efficiency_and_reduce_costs:"Du suivi des véhicules à la planification de la maintenance, nous rationalisons les opérations pour maximiser l'efficacité et réduire les coûts.",
    Industryleading_Compliance_Solutions:"Solutions de Conformité de Premier Plan :",
    Stay_aheadofregulatory_requirements_with_our_compliance_management_etc:"Restez en avance sur les exigences réglementaires avec nos outils de gestion de la conformité adaptés aux demandes spécifiques du secteur du transport.",
    PrecisionGPSTracking:"Suivi GPS Précis :",
    Monitorfleet_movementsin_realtime_optimize_routes_and_improve_driver_safety_with:"Surveillez les mouvements de la flotte en temps réel, optimisez les trajets et améliorez la sécurité des conducteurs avec nos systèmes avancés de suivi GPS.",
    EfficientAccountingIntegration:"Intégration Comptable Efficace :",
    Simplifyfinancial_processes_with_our_integrated_accounting_solutions:"Simplifiez les processus financiers avec nos solutions comptables intégrées conçues pour gérer en toute fluidité les dépenses, les factures et la paie.",
    SupportforCarriers:"Support pour les Transporteurs :",
    Facilitate_seamless_communication_and_operations_between_carriers_and_businesses:"Facilitez la communication et les opérations sans interruption entre les transporteurs et les entreprises, assurant une expérience de gestion de chaîne d'approvisionnement cohérente.",
    Discover_theVulcansolsInc_advantage_and_transform_yourfleet_management_operations_today:"Découvrez l'avantage de Vulcansols Inc. et transformez dès aujourd'hui vos opérations de gestion de flotte. Rejoignez un nombre croissant d'entreprises à travers les États-Unis qui comptent sur notre expertise pour stimuler le succès, améliorer la conformité et atteindre l'excellence opérationnelle.",
    seeHowVulcansolsIncsimplifiesfleetmanagement:"Découvrez comment Vulcansols Inc. simplifie la gestion de flotte",
    Schedule_a_demo_or_start_free_trail:"Programmez une démonstration et/ou commencez un essai gratuit de notre logiciel de maintenance de flotte",
    RequestforDemo:"Demande de Démonstration",
    Contactus:"Contactez-nous",
}
