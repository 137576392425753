export const LABELS={

    ELDCompliance:"ELD Compliance",
    GPSTracking:"GPS Tracking",
    SmartDashcam:"Smart Dashcam",
    TrailerTracking:"Trailer Tracking",
    ComplianceBoard:"Compliance Board",
    DispatchBoard:"Dispatch Board",
    VehicleMaintenance:"Vehicle Maintenance",
    ReeferTempratureMonitoring:"Reefer Temprature Monitoring",
    CustomTruckingSolution:"Custom Trucking Solution",


    next:"Next",
    back:"Back",
    submit:"Submit",

    fullName:"Full Name",
    phone:"Phone",
    EmailAddress:"Email Address",
    CompanyName:"Company Name",

    
    Features_or_productsyouinterested_in:"Features or products you interested in",
    NumberofVehiClesyouOperate:"Number of vehicles you operate",
}