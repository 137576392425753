import React from "react";
import "./App.css";
import Header from "./Components/Header/Header";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Components/Home/Home";
import Dispatch from "./Components/Dispatch/Dispatch";
import FleetTracking from "./Components/FleetTracking/FleetTracking";
import Compliance from "./Components/Compliance/Compliance";
import Demo from "./Components/Demo/Demo";
import DriverSafety from "./Components/DriverSafety/DriverSafety";
import Footer from "./Components/FooterSection/Footer";
import { Helmet } from "react-helmet";
import CustomerService from "./Components/Resources/CustomerServices/CustomerService";
import Education from "./Components/Resources/Education&Training/Education";
import Contact from "./Components/Home/Container/Contact";
import KnowledgeBase from "./Components/Resources/KnowledgeBase/KnowledgeBase";
import Carrier from "./Components/Carriers/Carrier";
import Recruiting from "./Components/Recruiting/Recruiting";
import Accounting from "./Components/Accounting/Accounting";
import GpsTracking from "./Components/GPSTracking/GpsTracking";
import Privacy from "./Components/Privacy/Privacy";
import Brokerage from "./Components/Brokerage/Brokerage";
import Complain from "./Components/Complian/Complain";
import ExtraBenifitsDetails from "./Components/Home/Container/ExtraBenifitsDetails";
import About from "./Components/Resources/AboutUs/About";
import Career from "./Components/Resources/Career/Career";
import AddFormModal from "./Components/Resources/Career/AddFormModal";
import SupportAvailable from "./Components/Home/Container/Help&Support/SupportAvailable";
import AndroidIosApp from "./Components/Home/Container/Help&Support/Android&IosApp";
import PlanInformation from "./Components/Home/Container/Help&Support/PlanInformation";
import CancelAnytime from "./Components/Home/Container/Help&Support/CancelAnytime";

function App() {
  const Location = useLocation();
  const footerPaths = [
    "/",
    "/dispatch",
    "/demo",
    "/tracking",
    "/compliance",
    "/customerService",
    "/education",
    "/carrier",
    "/brokerage",
    "/recruiting",
    "/complian",
    "/accounting",
    "/safety",
    "/extrabenifitsDetails",
    "/contact",
    "/aboutus",
    "/career",
    "/privacy",
  ];
  return (
    <>
      <Header title={"Vulcansols"} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dispatch" element={<Dispatch />} />
        <Route path="/safety" element={<DriverSafety />} />
        <Route path="/tracking" element={<FleetTracking />} />
        <Route path="/compliance" element={<Compliance />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/customerService" element={<CustomerService />} />
        <Route path="/education" element={<Education />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/knowledgeBase" element={<KnowledgeBase />} />
        <Route path="/carrier" element={<Carrier />} />
        <Route path="/recruiting" element={<Recruiting />} />
        <Route path="/accounting" element={<Accounting />} />
        <Route path="/gpsTracking" element={<GpsTracking />} />
        <Route path="/brokerage" element={<Brokerage />} />
        <Route path="/complian" element={<Complain />} />
        <Route
          path="/extrabenifitsDetails"
          element={<ExtraBenifitsDetails />}
        />
        <Route path="/aboutus" element={<About />} />
        <Route path="/career" element={<Career />} />
        <Route path="/addformModal" element={<AddFormModal />} />
        <Route path="/supportAvailable" element={<SupportAvailable />} />
        <Route path="/androidIosAppSupport" element={<AndroidIosApp />} />
        <Route path="/planInfomation" element={<PlanInformation />} />
        <Route path="/cancelAnytime" element={<CancelAnytime />} />
      </Routes>
      <div className="">
        {footerPaths.includes(Location.pathname) && <Footer />}
      </div>
    </>
  );
}

export default App;
