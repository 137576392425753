export const LABELS={
    home: "Home",
    dispatch: "AI Dispatch",
    safety: "Driver Safety",
    compliance: "Compliance",
    telematic: "Telematics",
    tracking: "Fleet Tracking",
    resources: "Resources",

    dashcam:"Driver Safety",
    pricing:"Pricing",
    gps:"Tracking",
    eld:"Compliance",
    demo:"Demo",
    login:"Log In",


    You_created_your_company_let_us_create_your_brand:"Driving Transport Forward with Innovative Technology",
    DrivingYourTransportBusinessForwardwithSmartTechnologySolutions:"Driving Your Transport Business Forward with Smart Technology Solutions.",
    PrecisionDispatch_PoweredbyAi:"Precision Dispatch, Powered by AI: Redefining Efficiency",
    Start_your_Free_Trail_Now:"Start your Free Trail Now",
    Request_Demo:"Request Demo",
    OurTrustedPartners:"Our Trusted partners",
    An_All_inOneTrucking:"An All-in-One Trucking",
    ManagementSoftware:"Management Software",
    Solution:"Solution",
    Our_mission_is_to_revolutionize_the_transport_industry_through_innovative_technology_solutions_enhancing_efficiency_safety_and_sustainability:"Our mission is to revolutionize the transport industry through innovative technology solutions, enhancing efficiency, safety, and sustainability. We are committed to empowering our clients with cutting-edge tools and exceptional service to drive their success and shape the future of transportation.",
    Firecamp_lets_you_choose_your_preferred_client_for_your_API_tack_to_cut_down_on_complexitiesAndTime:"Our All-in-One Trucking Management Software Solution is designed to meet the comprehensive needs of modern trucking companies. From small fleets to large-scale operations, our platform integrates advanced features to streamline and optimize every aspect of fleet management, ensuring efficiency, compliance, and profitability.",
    seeMore:"See More",
    learnMore:"Learn More",


    OurEngineeringTeam:"Our engineering team",
    AlogisticsDomainExpertEngineeringTeamis_a_Specialized_group_of_professionals_focused_on_designing_developing_and_implementing_solutions_within_the_Logistics_and_supplyChainManagementDomain:"A logistics domain expert engineering team is a specialized group of professionals focused on designing, developing, and implementing solutions within the logistics and supply chain management domain.",


    carriers:"Carriers",
    brokerage:"Brokerage",
    recruiting:"Recruiting",
    accounting:"Accounting",
    gpsTracking:"GPS Tracking",
    Compliance:"Compliance",
    MintRead:"5 min read",


    Stay_organized_Staypaid:"Stay organized. Stay paid.",
    StreamlineYourbackOffice:"Streamline your back office.",
    QuickDriverHiring:"Quick Driver Hiring",
    OneClickPayablesAndReceivables:"One click payables and receivables.",
    EasilyTrackYourTractororDriver:"Easily track your tractor or driver.",
    Stay_on_top_of_compliance:"Stay on top of compliance.",


    VulcansolsTelemetrics:"Vulcansols Telemetric",
    letsTalk:"Let’s Talk",
    fullName:"Full name *",
    emailAddress:"Email Address *",
    phone:"Phone *",
    How_can_i_assist_you:"How can i assist you? *",
    SendMessage:"Send Message",
    Were_here_to_help_and_answer_any_question_you_might:"At Vulcansols Inc., we're committed to providing you with the best support for our ELD solutions. If you have any questions or need assistance, don't hesitate to reach out.",


    MobileApplications:"Mobile Applications",
    Easily_manage_your_business_with_our_mobile_apps_Access_all_your_loads_expenses_etc_on_theLiveDispatch_App:"Easily manage your business with our mobile apps. Access all your loads, expenses, etc. on the Live Dispatch App. Get real-time updates by mobile app and stay connected to your business. Live Dispatch is available for both iOS and Android.",
    Logistics_dispatch_Software_offers_a_wide_array_of_additional_benefits_beyond_its_core_functionality_of_managing_dispatch_operations:"Logistics dispatch software offers a wide array of additional benefits beyond its core functionality of managing dispatch operations.",


    ExtraBenifits:"Extra Benefits",
    oneMonthfreeTrail:"24/7 Support Available",
    planAccordingYourNeeds:"Plan according your needs",
    AnyTimeCancelation:"Any time cancellation",
    NoCreditCardDetailsRequired:"No credit card details required",
    AndroidIos_Apps_for_drivers:"Android & iOS apps for drivers",


    AvailableForMobile:"Available for Mobile(Android, iOS), PC (Windows, Mac) and Tablet.",
    MultipleUserWithDifferentRoleandAcces:"Multiple users with different role and access",
    NoAdditionalSoftwareRequire:"No additional software require.",
    AccesFromAnyWhere:"Access from anywhere.",


    CustomerServices:"Customer Services",
    EducationTraining:"Education & Training",
    KnowledgeBase:"Knowledge Base",
    DeveloperPortal:"Developer Portal",
    Blog:"Blog",
    CustomerStories:"Customer Stories",
    Guide:"Guide",
    ProductTours:"Product Tours",
    Aboutus:"About us",
    Careers:"Careers",
    Webinaar:"Webinaar",
    
    Support:"Support",
    Resources:"Resources",
    Event:"Event",
    Contactus:"Contact us",


    Manage_everything_from_IFTA_to_driver_ownerOperatorSettlementsAnd_invoicing_allWithin_one_web_platform:"Manage everything from IFTA to driver, owner operator settlements and invoicing, all within one web platform.",
    Manage_loads_carrier_payables_agent_offices_payroll_and_invoicing_to_be_professional_and_profitable:"Manage loads, carrier payables, agent offices, payroll, and invoicing to be professional and profitable.",
    GetAlltheNecessaryInformationof_a_driverandmanageallTheApplicationsOnASinglePage:"Get all the necessary information of a driver and manage all the applications on a single page.",
    After_creating_a_load_the_invoice_is_ready_or_youAnd_with_assigning_driver_or_carrier_settlement_is_ready:"After creating a load, the invoice is ready for you and with assigning driver or carrier settlement is ready.",
    You_an_easily_connect_your_existing_ELD_GPS_racker_to_get_ealtime_tracking_nformation_or_use_our_mobile_app_to_rack_driver:"You can easily connect your existing ELD, GPS Tracker to get realtime tracking information or use our mobile app to track driver.",
    Vulconsols_Safety_not_only_offers_solutions_to_assist_you_in_maintaining_compliance_but_we_deliver_the_news_you_need_to_know:"Vulconsols Safety not only offers solutions to assist you in maintaining compliance but we deliver the news you need to know!"

}
