import React from 'react'
import Broker from "./broker.svg";
import { LABELS as _LABELS } from '../../Language';

const LABELS=_LABELS.Brokerage;

function Brokerage() {
  return (
    <>
      <section className="py-14">
        <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
          <div className="relative max-w-2xl mx-auto sm:text-center">
            <div className="relative z-10">
              <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                {LABELS.Brokerage}
              </h3>
              <p className="mt-3">
                {LABELS.TheVulcansolsEldSoftwarecanhelpyoutomanagetediouspaperworkandnotifications}
              </p>
            </div>
            <div className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
          </div>
        </div>

      </section>
      <div className="sm:flex items-center max-w-screen-xl">
        <div className="sm:w-1/2 p-10">
          <div className="image object-center text-center">
            <img src={Broker} alt='brokerage'/>
          </div>
        </div>
        <div className="sm:w-1/2 p-5">
          <div className="text">

            <p className="text-gray-700">
             {LABELS.TheVulcansolssoftwareeliminatesalltheguessworkfrompayrollandinvoicing}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Brokerage
