import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import eldConnector from "./icons/EldIntegration.png";
import loadtrackig from "./icons/LoadTracking.png";
import loadRouting from "./icons/Onboarding.png";
import rates from "./icons/Oneclick.png";
import Settlement from "./icons/Loadpayment.png";
import message from "./icons/Chat.png";
import notification from "./icons/Notification.png";
import proofOfDelevery from "./icons/MobileService.png";
import { LABELS as _LABELS } from '../../Language';

const LABELS=_LABELS.Carrier;

function Carrier() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
      }, []);

  const features = [
    {
        icon:<img src={eldConnector} alt='eldConnector'/>
            ,
        title: LABELS.eldConnect,
        desc: LABELS.Letconnectyour_existingELD_to_Vulcansols_to_track_HOS_location_at_realtime
    },
    {
        icon:
           <img src={loadtrackig} alt='loadtracking'/>,
        title: LABELS.LoadTracking,
        desc: LABELS.OneClick_enabled_disable_live_load_tracking
    },
    {
        icon:
            <img src={loadRouting} alt='loadrooting'/>,
        title: LABELS.LoadRouting,
        desc: LABELS.Automaticallycalculates_empty_and_loadedmile_based_on_vehicle_current_location_shipper_and_consignee
    },
    {
        icon:
            <img src={loadRouting} alt='ifta'/>,
        title: LABELS.Ifta,
        desc: LABELS.Automatically_calculates_states_miles_for_you_IFTA_tax_alongWithdispatchwith_thehelpofVehicleLocationShipperAndConsignee
    },
    {
        icon:
          <img src={rates} alt='rates'/>,
        title: LABELS.Rates,
        desc: LABELS.StayCompetitiveWithourSpotMarketRateAnalyzerfor_a_comprehensiveLook_at_industry_trends
    },
    {
        icon:
           <img src={Settlement} alt='settlement'/>,
        title: LABELS.Settlements,
        desc:LABELS.Createdriversettlements_Paychecks_with_one_click
    },
    {
      icon:
         <img src={loadRouting} alt='receiveable'/>,
      title: LABELS.Receivables,
      desc: LABELS.DecideWhenAndHowYouWantTogetPaid_withoutthe_factoringservice_contracts_or_hiddenfees
  },
  {
      icon:
          <img src={message} alt='message'/>,
      title: LABELS.WayMessaging,
      desc: LABELS.WayMessaging_with_drivers_carriers_via_mobile_app_or_fall_backtoSMS_chat
  },
  {
      icon:
          <img src={notification} alt='notification'/>,
      title: LABELS.Notification,
      desc:LABELS.WebandPushNotificationforloads_at_shippercheckinout_deliveryetc
  },
  {
    icon:
       <img src={loadtrackig} alt='loadConfirmation'/>,
    title: LABELS.LoadConfirmation,
    desc: LABELS.Use_the_dependable_load_tracking_software_giving_you_handsfree_checkcalls
},
{
    icon:
        <img src={proofOfDelevery} alt='proofofdelevery'/>,
    title: LABELS.ProofOfDelivery,
    desc:LABELS.MakingProofOfDeliveryseamlessdrivercandofromVulcansolsmobileappalongwithdocumentupload
},
{
    icon:
    <img src={rates} alt='LoadBoard'/>,
    title: LABELS.LoadBoard,
    desc:LABELS.Withoneclickpostavailabletruckafterdelivery,
},
]
  return (
    <div>
        <section className="py-14">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                <div className="relative max-w-2xl mx-auto sm:text-center">
                    <div className="relative z-10">
                        <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                        {LABELS.carrier}
                        </h3>
                        <p className="mt-3">
                       {LABELS.Vulcansols_is_well_designed_TMS_for_carrier_to_ease_and_automate_day_to_day_activitiesStay_on_top_of_your_trucking_business_with_our_smart_artificialintelligencebasedcloudsolution}
                        </p>
                    </div>
                    <div className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
                </div>
                <div className="relative mt-12">
                    <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {
                            features.map((item, idx) => (
                                <li key={idx} className="bg-white space-y-3 shadow-md p-4 border rounded-lg flex  flex-col  items-center justify-center hover:bg-[#00A56D]  hover:text-white font-medium">
                                    <div className="text-indigo-600 pb-3 ">
                                        {item.icon}
                                    </div>
                                    <h4 className="text-lg text-gray-800 font-semibold">
                                        {item.title}
                                    </h4>
                                    <p>
                                        {item.desc}
                                    </p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>
        <div className="flex justify-center mb-3">
  <Link to="/contact"
    className="px-6 py-3 text-white duration-100 bg-indigo-600 rounded-lg shadow-md focus:shadow-none ring-offset-2 ring-indigo-600 focus:ring-2"
  >
    {LABELS.letsTalk}
  </Link>
  </div>
    </div>
  )
}

export default Carrier
