import React from 'react'
import CancelAnytimeimage from "../../../../assets/images/home/cancelanytime.jpg";
import {LABELS as _LABELS} from "../../../../Language";

const LABELS=_LABELS.HelpSupport;


function CancelAnytime() {
  return (
    <main className="py-14 bg-white">
    <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
        <div className="max-w-lg mx-auto gap-12 justify-between lg:flex lg:max-w-none">
            <div className="max-w-lg space-y-3">
                <h3 className="text-red-600 font-semibold text-xl">
                    {LABELS.AnytimeCancellation}
                </h3>
                <p className="text-gray-800 text-3xl font-bold sm:text-4xl">
                    {LABELS.Flexibilityfor_Your_Fleet}
                </p>
                <p className="mt-4 text-lg">
                   {LABELS.At_Vulcansols_Inc_we_understand_that_business_needs_can_change_rapidly}
                </p>
                <p className="mt-4 text-lg">
                 {LABELS.Our_ELD_solutions_are_designed_to_provide_maximum_flexibility_and_ease_of_use}
                </p>
                <p className="mt-4 text-lg">
                    {LABELS.Join_Vulcansols_Inc_and_experience_the_freedom_and_flexibility_that_comes_with_our_industryleading_ELD_solutions}
                </p>
            </div>
            <div className="flex-1 mt-12 sm:max-w-lg lg:max-w-md">
                <img src={CancelAnytimeimage} alt="Cancellation" className="rounded-2xl shadow-lg"/>
            </div>
        </div>
    </div>
</main>
  )
}

export default CancelAnytime