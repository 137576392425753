import React from 'react';
import { LABELS as _LABELS } from '../../Language';

const LABELS=_LABELS.Demo;

function AddForm({ formData, setFormData }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      <h1 className="text-center font-medium text-3xl sm:text-4xl md:text-5xl lg:text-4xl xl:text-4xl py-4 sm:py-6 leading-tight sm:leading-snug md:leading-normal">
        {LABELS.NumberofVehiClesyouOperate}
      </h1>

      <section className=" dark:bg-gray-900">
        <div className="flex flex-col items-center ">

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-4 space-y-4 md:space-y-4 sm:p-8">

              <form className="space-y-4 md:space-y-4" >
                <div>
                  <label htmlFor="text" className="block mb-2 text-xs text-gray-900 dark:text-white">{LABELS.fullName} *</label>
                  <input type="text" name="name" id="text" value={formData.name} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Name..." required="" />
                </div>
                <div>
                  <label htmlFor="number" className="block mb-2 text-xs text-gray-900 dark:text-white">{LABELS.phone} *</label>
                  <input type="number" name="number" id="number" value={formData.number} onChange={handleChange} placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                </div>
                <div>
                  <label htmlFor="email" className="block mb-2 text-xs text-gray-900 dark:text-white">{LABELS.EmailAddress} *</label>
                  <input type="email" name="email" id="email" value={formData.email} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="support@vulcansols.com" required="" />
                </div>
                <div>
                  <label htmlFor="textarea" className="block mb-2 text-xs text-gray-900 dark:text-white">{LABELS.CompanyName}</label>
                  <textarea name="company" id="company" value={formData.company} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="typing..."></textarea>
                </div>

              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddForm;
