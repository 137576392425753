import React, { useEffect } from "react";
import Recruit from "./recruiting.svg";
import { LABELS as _LABELS } from "../../Language";

const LABELS = _LABELS.Recruiting;

function Recruiting() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <div className="sm:flex items-center max-w-screen  justify-center gap-x-10  p-10 ">
        <div className=" ">
          <div className="image object-center text-center flex justify-center ">
            <img src={Recruit} alt="brokerage" className="w-96 h-64 " />
          </div>
        </div>
        <div className="sm:w-1/2 p-5 ">
          <div className="text">
            <h2 class="my-4 font-bold text-3xl text-center sm:text-4xl ">
              {LABELS.Recruiting}
            </h2>
            <p className="text-gray-700">
              {
                LABELS.Vulcansolsmakes_driver_hiring_veryeasy_Driver_Hiring_application_process_is_easy_both_fordriver_and_companyIt_is_designed_togetallthenecessaryinformationofdriverineasywayThisapplicationisavailableforbothmobileanddesktop
              }
            </p>
          </div>
        </div>
      </div>
      <div className="flex  justify-center  bg-[#f9f9f9] p-10">
        <ul className="flex flex-col w-3/6 justify-start">
          <li className="bg-white space-y-3 shadow-md p-4 border rounded-lg flex  flex-col  items-center justify-center hover:text-white font-medium">
            <h4 className="text-lg text-gray-800 font-semibold">
              {LABELS.Stepstofollow}
            </h4>
          </li>
          <li className="bg-white space-y-3 shadow-md p-4 border rounded-lg flex  flex-col  items-center justify-center font-medium">
            <h4 className="text-lg text-gray-800 font-semibold">
              {LABELS.SendTheapplicationlinktodriverviaSMS}
            </h4>
          </li>
          <li className="bg-white space-y-3 shadow-md p-4 border rounded-lg flex  flex-col  items-center justify-center font-medium">
            <h4 className="text-lg text-gray-800 font-semibold">
              {LABELS.Drivercanopenthelinkinmobileordesktop}
            </h4>
          </li>
          <li className="bg-white space-y-3 shadow-md p-4 border rounded-lg flex  flex-col  items-center justify-center font-medium">
            <h4 className="text-lg text-gray-800 font-semibold">
              {LABELS.Drivercanfillapplication}
            </h4>
          </li>
          <li className="bg-white space-y-3 shadow-md p-4 border rounded-lg flex  flex-col  items-center justify-center font-medium">
            <h4 className="text-lg text-gray-800 font-semibold">
              {
                LABELS.Oncedriversubmittheapplicationownerwillreceivenotification
              }
            </h4>
          </li>
          <li className="bg-white space-y-3 shadow-md p-4 border rounded-lg flex  flex-col  items-center justify-center font-medium">
            <h4 className="text-lg text-gray-800 font-semibold">
              {LABELS.Ownerwillreviewthedriverapplication}
            </h4>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Recruiting;
