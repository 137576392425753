import {LABELS as _LABELS} from "../../Language";
const LABELS=_LABELS.HomePage;

export const dropdownNavs = [
    {
        label: LABELS.Support, navs: [
            {
                title: LABELS.CustomerServices,
                path: "/customerService",
                
            },
            {
                title: LABELS.EducationTraining,
                path: "/education",

            },
            {
                title: LABELS.KnowledgeBase,
                path: "",
 
            },
            // {
            //     title: LABELS.DeveloperPortal,
            //     path: ""
            // }
        ]
    }, {
        label: LABELS.Event, navs: [
            {
                title:LABELS.Aboutus,
                path: "/aboutus",
            },
            {
                title: LABELS.Careers,

                path: "/career",
            },
            {
                title:LABELS.Contactus,

                path: "/contact",
            },
           
        ]
    }
  ]