export const LABELS={
    TheAccountingSOftwareWithEasytoUseTools:"EL SOFTWARE DE CONTABILIDAD CON HERRAMIENTAS FÁCILES DE USAR.",
    FlexibleAccurateBilling:"Facturación Flexible y Precisa",
    BudgetReporting:"Reporte de Presupuesto",
    DriverSettlements:"Liquidaciones de Conductores",
    OneclickInvoice:"Facturación con un Clic",
    Create1099:"Crear 1099",
    TracktotalCosts:"Seguimiento de Costos Totales",
    CheckPrinting:"Impresión de Cheques",
    PaystubPrinting:"Impresión de Talones de Pago",


    VulcansolsSoftwareEliminatesalltheguessworkfrompayroll_and_invoicingYou_will_know_exactly_what_you_owe_your_employees_and_how_much_your_customers_owe_you_and_everything_will_be_broken_down:"El Software de Vulcansols elimina toda la incertidumbre de la nómina y la facturación. Sabrás exactamente lo que debes a tus empleados y cuánto te deben tus clientes, y todo estará desglosado. Tus despachos, gastos y facturas serán completamente precisos con un esfuerzo mínimo de tu parte.",
    Customizable_software_to_meet_the_workflow_needs_of_nearly_any_organization:"Software personalizable para satisfacer las necesidades de flujo de trabajo de casi cualquier organización. Asientos de usuario ilimitados, brindándote la flexibilidad para utilizar aplicaciones en todos los departamentos y evitar silos. Herramientas intuitivas proporcionadas en una solución para ejecutar todo tu negocio, ofreciendo automatización sin complejidad.",
    Eliminate_errors_and_cut_the_timeittakesto_generate_andpayyourstaff:"Elimina errores y reduce el tiempo que lleva generar y pagar a tu personal, conductores. Siempre es desmoralizante discutir con un conductor sobre cuántas millas condujeron y cuánto les debes. El Software de Vulcansols eliminará toda la incertidumbre y te permitirá saber exactamente cuánto debes a tu conductor y cuántas millas condujo cada conductor.",
    Vulcansols_Software_includes_accountingmodulesthatallows_you_to_quickly_send_edit_andcreate_invoices_with_one_click:"El Software de Vulcansols incluye módulos de contabilidad que te permiten enviar, editar y crear facturas rápidamente con un clic. Puedes crear una factura para una o varias cargas con solo un clic.",
    VulcansolsSoftware_helps_you_to_quickly_compile_all_the_information_required_to_submit_government_forms_like1099_withtheclick_of_a_button:"El Software de Vulcansols te ayuda a compilar rápidamente toda la información requerida para enviar formularios gubernamentales como el 1099 con un clic.",
    VulcansolsSoftware_helps_you_to_generate_reports_with_one_click_of_a_button_and_you_track_all_your_expenses_and_earnings_at_one_place:"El Software de Vulcansols te ayuda a generar informes con un clic de un botón y rastreas todos tus gastos y ganancias en un solo lugar.",
    VulcansolsSoftware_allows_you_to_generate_all_your_reports_with_one_click_and_then_you_can_easily_download_and_print_each_and_every_report_for_your_record:"El Software de Vulcansols te permite generar todos tus informes con un clic y luego puedes descargar e imprimir fácilmente cada informe para tu registro.",
    You_can_easily_generate_Pay_stubs_and_pay_checks_for_each_driver_by_selecting_one_or_multiple_loads_delivered_by_the_driver:"Puedes generar fácilmente talones de pago y cheques de pago para cada conductor seleccionando una o varias cargas entregadas por el conductor. Después de generar los cheques de pago y los talones de pago, simplemente puedes descargarlos e imprimirlos.",
}
