import React, { useState } from 'react';
import AddFormModal from './AddFormModal';

function AboutDescription({ showDescription, toggleDescription, jobDetails }) {
    const [showApplicationModal, setShowApplicationModal] = useState(false);
    
    const toggleApplicationModal = () => {
        setShowApplicationModal(!showApplicationModal);
    };

    return (
        <>
            {showDescription && (
                <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <div className="relative p-4 w-full max-w-md max-h-full overflow-auto">
                        <div className="relative bg-white rounded-lg shadow">
                            <div className="flex items-center justify-between p-4 border-b rounded-t">
                                <h3 className="text-2xl font-bold text-blue-600 text-center">
                                    {jobDetails.title}
                                </h3>
                                <button
                                    type="button"
                                    onClick={toggleDescription} // Close job details modal
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg w-8 h-8 flex justify-center items-center"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="p-4">
                                <p className="text-gray-700 mb-4">
                                    <span className="font-semibold">Skills Required:</span>{" "}
                                    {jobDetails.skills.join(", ")}
                                </p>
                                <p className="text-gray-700 mb-4">
                                    <span className="font-semibold">Experience:</span>{" "}
                                    {jobDetails.experience}
                                </p>
                                <p className="text-gray-700 mb-4">
                                    <span className="font-semibold">Number of Positions:</span>{" "}
                                    {jobDetails.numberOfPositions}
                                </p>
                                <p className="text-gray-700 mb-4">
                                    <span className="font-semibold">Qualification:</span>{" "}
                                    {jobDetails.qualification}
                                </p>
                                <button
                                    onClick={toggleApplicationModal} // Open application modal
                                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                                >
                                    Apply Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showApplicationModal && (
                <AddFormModal 
                    showModal={showApplicationModal}
                    toggleModal={toggleApplicationModal}
                />
            )}
        </>
    );
}

export default AboutDescription;
