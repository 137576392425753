export const LABELS={
    Recruiting:"Recruiting",


    Stepstofollow:"Steps to follow :",
    SendTheapplicationlinktodriverviaSMS:"1. Send the application link to driver via SMS.",
    Drivercanopenthelinkinmobileordesktop:"2. Driver can open the link in mobile or desktop.",
    Drivercanfillapplication:"3. Driver can fill application.",
    Oncedriversubmittheapplicationownerwillreceivenotification:"4. Once driver submit the application owner will receive notification.",
    Ownerwillreviewthedriverapplication:"5. Owner will review the driver’s application.",
    Vulcansolsmakes_driver_hiring_veryeasy_Driver_Hiring_application_process_is_easy_both_fordriver_and_companyIt_is_designed_togetallthenecessaryinformationofdriverineasywayThisapplicationisavailableforbothmobileanddesktop:"Vulcansols makes driver hiring very easy.Driver Hiring application’s process is easy both for driver and company. It is designed to get all the necessary information of driver in easy way. This application is available for both mobile and desktop.",

}