export const LABELS={
    SupportAvailable:" Soporte disponible 24 horas al día, 7 días a la semana",
    ContactSupport:"Soporte de contacto",
    For_the_fastest_response_please_submit_a_case_online_through_your_Vulcansols_Inc:'Para obtener la respuesta más rápida, envíe un caso en línea a través de Vulcansols Inc. Navegue a la sección "Contáctenos" y complete el formulario para enviar un ticket. Nuestro equipo de soporte está disponible las 24 horas del día, los 7 días de la semana para ayudarlo con cualquier problema que pueda encontrar.',
    Administration:"Administración",
    hours_a_day_7_days_a_week:"Las 24 horas del día, los 7 días de la semana.",
    Quick_Contact_Form:"Formulario de contacto rápido",
    Name:"Nombre",
    Email:"Correo electrónico",
    Message:"Mensaje",
    SendMessage:"Enviar mensaje",
    Submitting:"Sumisión...",
    Message_sent_successfully:"¡Mensaje enviado con éxito!",
    Something_went_wrong_Please_try_again:"Algo salió mal. Inténtalo de nuevo.",



    AnytimeCancellation:"Cancelación en cualquier momento",
    Flexibilityfor_Your_Fleet:"Flexibilidad para su flota",
    At_Vulcansols_Inc_we_understand_that_business_needs_can_change_rapidly:"En Vulcansols Inc., entendemos que las necesidades comerciales pueden cambiar rápidamente. Es por eso que ofrecemos una política de cancelación en cualquier momento para nuestros servicios ELD. Tienes la libertad de cancelar tu plan en cualquier momento sin penalizaciones.",
    Our_ELD_solutions_are_designed_to_provide_maximum_flexibility_and_ease_of_use:"Nuestras soluciones ELD están diseñadas para proporcionar la máxima flexibilidad y facilidad de uso. Ya sea que esté ampliando o reduciendo su escala, nuestra política de cancelación en cualquier momento garantiza que usted mantenga el control de sus operaciones.",
    Join_Vulcansols_Inc_and_experience_the_freedom_and_flexibility_that_comes_with_our_industryleading_ELD_solutions:"Únase a Vulcansols Inc. y experimente la libertad y flexibilidad que ofrecen nuestras soluciones ELD líderes en la industria.",



    Available_on_Android_and_iOS:"Disponible en Android e iOS",
    ELD_Solutions_for_Every_Driver:"Soluciones ELD para cada conductor",
    Our_ELD_application_is_designed_to_meet_the_needs_of_drivers_on_both_Android_and_iOS_platforms:"Nuestra aplicación ELD está diseñada para satisfacer las necesidades de los conductores en plataformas Android e iOS. Cumpla con las regulaciones de la FMCSA y administre sus horas de servicio sin esfuerzo con nuestra aplicación fácil de usar.",
    Download_on_the:"Descargar en el",
}