import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import vulcansolslogo from "../../../assets/images/logo-vulconsols.svg";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { LABELS as _LABELS } from "../../../Language";
import { Helmet } from "react-helmet";

const LABELS = _LABELS.HomePage;

function Contact() {
  const [formData, setFormData] = useState({
    fullName: "",
    emailAddress: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(
          "https://osritapi.com/api/issues/contactus",
          formData
        );
        // console.log("response___",response.data);
        // console.log("Form Data:", formData);

        setFormData({
          fullName: "",
          emailAddress: "",
          phone: "",
          message: "",
        });
        setErrors({});
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!formData.fullName.trim()) {
      formIsValid = false;
      errors.fullName = "Full Name is required";
    }

    // Validate emailAddress
    if (!formData.emailAddress.trim()) {
      formIsValid = false;
      errors.emailAddress = "Email Address is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.emailAddress)) {
      formIsValid = false;
      errors.emailAddress = "Email Address is invalid";
    }

    // Validate phone
    if (!formData.phone.trim()) {
      formIsValid = false;
      errors.phone = "Phone is required";
    } else if (!/^\d+$/.test(formData.phone)) {
      formIsValid = false;
      errors.phone = "Phone is invalid";
    }

    // Validate message
    if (!formData.message.trim()) {
      formIsValid = false;
      errors.message = "Message is required";
    }

    setErrors(errors);
    return formIsValid;
  };

  const contactMethods = [
    {
      icon: <FontAwesomeIcon icon={faFacebook} size="2x" />,
      url: "https://www.facebook.com/people/",
    },
    {
      icon: <FontAwesomeIcon icon={faInstagram} size="2x" />,
      url: "https://www.instagram.com",
    },
    {
      icon: <FontAwesomeIcon icon={faTwitter} size="2x" />,
      url: "https://www.twitter.com",
    },
  ];

  return (
    <>
      <main className="py-6">
        <div className="max-w-screen-xl mx-auto px-4 text-black-600 md:px-8 ">
          <div className="max-w-lg mx-auto justify-between lg:flex lg:max-w-none">
            <div className="max-w-lg space-y-3 mt-9 ">
              <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                {"Contact Us"}
              </p>
              <p>
                {LABELS.Were_here_to_help_and_answer_any_question_you_might}
              </p>
              <div>
                <ul className="mt-6 flex flex-wrap gap-x-10 gap-y-6 items-center mb-20">
                  {contactMethods.map((item, idx) => (
                    <li key={idx} className="flex items-center gap-x-3">
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex-none text-gray-400 hover:text-gray-600"
                      >
                        {item.icon}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="pt-10 flex gap-y-6 flex-col">
                <div className="flex flex-row justify-items-center items-center">
                  <div className="border-t-4 border-black ... w-16 mr-3"></div>
                  <p>+1 (925) 744-5005</p>
                </div>
                <div className="flex flex-row justify-items-center items-center">
                  <div className="border-t-4 border-black ... w-16 mr-3"></div>
                  <p>support@vulcansols.com</p>
                </div>
              </div>
            </div>
            <div className="flex-1 mt-12 sm:max-w-lg lg:max-w-md ">
              <form onSubmit={handleSubmit} className="space-y-5">
                <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                  {LABELS.letsTalk}
                </p>
                <div>
                  <label className="font-normal text-xs">
                    {LABELS.fullName}
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    className={`w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg ${
                      errors.fullName ? "border-red-500" : ""
                    }`}
                  />
                  {errors.fullName && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.fullName}
                    </p>
                  )}
                </div>
                <div>
                  <label className="font-normal text-xs">
                    {LABELS.emailAddress}
                  </label>
                  <input
                    type="email"
                    name="emailAddress"
                    value={formData.emailAddress}
                    onChange={handleChange}
                    className={`w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg ${
                      errors.emailAddress ? "border-red-500" : ""
                    }`}
                  />
                  {errors.emailAddress && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.emailAddress}
                    </p>
                  )}
                </div>
                <div>
                  <label className="font-normal text-xs">{LABELS.phone}</label>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className={`w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg ${
                      errors.phone ? "border-red-500" : ""
                    }`}
                  />
                  {errors.phone && (
                    <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
                  )}
                </div>
                <div>
                  <label className="font-normal text-xs">
                    {LABELS.How_can_i_assist_you}
                  </label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className={`w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg ${
                      errors.message ? "border-red-500" : ""
                    }`}
                  ></textarea>
                  {errors.message && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.message}
                    </p>
                  )}
                </div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 text-white font-medium bg-black hover:bg-indigo-500 active:bg-indigo-600 rounded-full duration-150"
                >
                  {LABELS.SendMessage}
                  <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Contact;
