export const LABELS={
    ELDCompliance: "Cumplimiento ELD",
    GPSTracking: "Seguimiento GPS",
    SmartDashcam: "Cámara de Tablero Inteligente",
    TrailerTracking: "Seguimiento de Remolques",
    ComplianceBoard: "Tablero de Cumplimiento",
    DispatchBoard: "Tablero de Despacho",
    VehicleMaintenance: "Mantenimiento de Vehículos",
    ReeferTempratureMonitoring: "Monitoreo de Temperatura del Refrigerador",
    CustomTruckingSolution: "Solución de Transporte Personalizada",
  
    next: "Siguiente",
    back: "Atrás",
    submit: "Enviar",
  
    fullName: "Nombre Completo",
    phone: "Teléfono",
    EmailAddress: "Correo Electrónico",
    CompanyName: "Nombre de la Empresa",
  
    Features_or_productsyouinterested_in: "Características o productos en los que estás interesado",
    NumberofVehiClesyouOperate: "Número de vehículos que operas"
  };