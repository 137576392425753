import React, { useState } from 'react';
import {DriverSafetyNav,footerNavs} from "./Constant"




function AccordionItem({ id, title, content }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <h2 id={id}>
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
          onClick={toggleAccordion}
          aria-expanded={isOpen}
          aria-controls={`${id}-body`}
        >
          <span>{title}</span>
          <svg
            className={`w-3 h-3 rotate-${isOpen ? '0' : '180'} shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id={`${id}-body`}
        className={`${
          isOpen ? '' : 'hidden'
        } p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900`}
        aria-labelledby={id}
      >
        {content}
      </div>
    </div>
  );
}

function  Accordion() {
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <AccordionItem
        id="accordion-collapse-heading-1"
        title=" Get Started"
        content={
          <>
           <h1 className='font-bold'>Set up Vulcansols Eld for the First Time</h1>
           <p>Welcome to Vulcansols Eld!</p>
           <ul>
            <li >Step 1: Set Up Your Organization on the Vulcansols Cloud</li>
            <li>Step 2: Configure your Vulcansols Dashboard for the First Time</li>
            <li>Step 3: Download Vulcansols Apps</li>
            <li>Step 4: Activate Vulcansols Hardware and Accessories</li>
            <li>Step 5: Install Vulcansols Hardware and Accessories</li>
           </ul>
          </>
        }
      />
      <AccordionItem
        id="accordion-collapse-heading-2"
        title="Administration"
        content={
          <>
          <h1 className='font-bold'>Dashboard Overview</h1>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
            Access the Vulcansols Dashboard
            </p>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
            Page Favorites
            </p>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
            Customizable Fleet Overview Data
            </p>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
            Vulcansols Reports
            </p>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
            Map Customization
            </p>
            <p className="mb-2 text-gray-500 dark:text-gray-400">Find Your Organization ID</p>
          </>
        }
      />
      <AccordionItem
        id="accordion-collapse-heading-3"
        title="Hardware and Accessories"
        content={
          <>
            <div className="flex-1 mt-16 space-y-6 justify-between sm:flex md:space-y-0">
                    {
                        footerNavs.map((item, idx) => (
                            <ul
                                className="space-y-4 text-gray-600"
                                key={idx}
                            >
                                <h4 className="text-gray-800 font-semibold sm:pb-2">
                                    {item.label}
                                </h4>
                                {
                                    item.items.map(((el, idx) => (
                                        <li key={idx}>
                                            <a
                                                href={el.href}
                                                className="hover:text-gray-800 duration-150"

                                            >
                                                {el.name}
                                            </a>
                                        </li>
                                    )))
                                }
                            </ul>
                        ))
                    }
                </div>
          </>
        }
      />
        <AccordionItem
        id="accordion-collapse-heading-3"
        title="Driver Safety"
        content={
          <>
           <div className="flex-1 mt-16 space-y-6 justify-between sm:flex md:space-y-0">
                    {
                        DriverSafetyNav.map((item, idx) => (
                            <ul
                                className="space-y-4 text-gray-600"
                                key={idx}
                            >
                                <h4 className="text-gray-800 font-semibold sm:pb-2">
                                    {item.label}
                                </h4>
                                {
                                    item.items.map(((el, idx) => (
                                        <li key={idx}>
                                            <a
                                                href={el.href}
                                                className="hover:text-gray-800 duration-150"

                                            >
                                                {el.name}
                                            </a>
                                        </li>
                                    )))
                                }
                            </ul>
                        ))
                    }
                </div>
          </>
        }
      />
       <AccordionItem
        id="accordion-collapse-heading-3"
        title="Driver Work flow"
        content={
          <>
          <div className="flex-1 mt-16 space-y-6 justify-between sm:flex md:space-y-0">
                    {
                        footerNavs.map((item, idx) => (
                            <ul
                                className="space-y-4 text-gray-600"
                                key={idx}
                            >
                                <h4 className="text-gray-800 font-semibold sm:pb-2">
                                    {item.label}
                                </h4>
                                {
                                    item.items.map(((el, idx) => (
                                        <li key={idx}>
                                            <a
                                                href={el.href}
                                                className="hover:text-gray-800 duration-150"

                                            >
                                                {el.name}
                                            </a>
                                        </li>
                                    )))
                                }
                            </ul>
                        ))
                    }
                </div>
          </>
        }
      />
    </div>
    
  );
}

export default Accordion;
