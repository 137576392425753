import React from "react";

function Privacy() {
  return (
    <>
      <section
        className="py-14"
        style={{ padding: 45, maxWidth: 1000, margin: "10px auto" }}
      >
        <div className="max-w-screen-xl mx-auto px-4 text-left md:px-8 flex  flex-col gap-2.5">
          <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
            {`Privacy Policy`}
          </h3>
          <div>{"Effective Date: 4 September 2024"}</div>

          <div style={{ marginTop: 25 }}>
            <div>
              <div>
                This Privacy Policy governs the collection, use, maintenance,
                and disclosure of information by Vulconsols, Inc. (“Vulconsols,”
                “we,” “our,” or “us”) from users (“you” or “your”) of our
                website, products, and services (“Services”). This Policy
                applies to the collection and processing of personal data in
                accordance with applicable laws and regulations, including the
                Data Protection Act.
              </div>
            </div>
          </div>

          <div style={{ marginTop: 25 }}>
            <div>
              <strong>Personal Information </strong> refers to any information
              that identifies, relates to, describes, or is capable of being
              associated with, directly or indirectly, an individual user.
            </div>

            <div style={{ marginTop: 5 }}>
              <strong>Device Data </strong> includes information about your
              device, such as its unique device identifier, IP address,
              operating system, and browser type.
            </div>

            <div style={{ marginTop: 5 }}>
              <strong>Internet Activity </strong> encompasses data related to
              your online activity, such as browsing history, interactions with
              our website, and internet protocol (IP) addresses.
            </div>

            <div style={{ marginTop: 5 }}>
              <strong>Location Data </strong>
              refers to information about your device’s geographic location,
              such as GPS coordinates or Wi-Fi access points.
            </div>

            <div style={{ marginTop: 25 }}>
              <strong>Severability: </strong>
              <br />
              Should any provision of this Privacy Policy be determined to be
              invalid, illegal, or unenforceable by a court of competent
              jurisdiction, the remaining provisions shall remain in full force
              and effect. The invalid, illegal, or unenforceable provision shall
              be modified to the extent necessary to render it valid, legal, and
              enforceable while preserving its intent to the maximum extent
              permitted by law.
            </div>

            <div style={{ marginTop: 25 }}>
              <strong>Governing Law:</strong>
              <br />
              This Privacy Policy and any dispute or claim arising out of or in
              connection with it (including non-contractual disputes or claims)
              shall be governed by and construed in accordance with the laws of
              the State of California, without regard to its conflict of law
              provisions. Any legal action or proceeding relating to this
              Privacy Policy shall be instituted exclusively in the federal or
              state courts located in the County of Alameda, California. Each
              party irrevocably submits to the exclusive jurisdiction of such
              courts in any such action or proceeding.
            </div>

            <div style={{ marginTop: 25 }}>
              <strong>Entire Agreement:</strong>
              <br />
              This Privacy Policy constitutes the entire agreement between you
              and Vulconsols regarding the collection, use, and disclosure of
              personal information pertaining to the use of our application and
              website. Any prior agreements, understandings, communications,
              representations, warranties, or commitments, whether oral or
              written, between you and Vulconsols regarding such matters are
              superseded by this Privacy Policy.
            </div>

            <div style={{ marginTop: 25 }}>
              <strong>Modification of Terms:</strong>
              <br />
              Vulconsols Inc reserves the right to modify, amend, or update this
              Privacy Policy at any time without prior notice. Any changes to
              the policy will be effective immediately upon posting on this
              page. It is your responsibility to review this Privacy Policy
              periodically to stay informed of updates. Your continued use of
              our Android application and website after any modifications to the
              Privacy Policy constitutes acceptance of those changes.
            </div>

            <div style={{ marginTop: 25 }}>
              <strong>Disclaimer of Liability:</strong>
              <br />
              Vulconsols Inc, its affiliates, officers, directors, employees,
              agents, and licensors shall not be liable to you or any third
              party for any damages of any kind arising out of or relating to
              the use of our application or website, including but not limited
              to any indirect, incidental, consequential, special, or punitive
              damages, or damages for loss of profits, revenue, data, or use,
              incurred by you or any third party, whether in an action in
              contract or tort, arising from your access to, or use of, our
              application or website.
            </div>

            <div style={{ marginTop: 25 }}>
              <strong>Language Interpretation:</strong>
              <br />
              This Privacy Policy may be translated into multiple languages for
              the convenience of our users. In the event of any conflict or
              discrepancy between the English version and a translated version,
              the English language version shall prevail and be controlling to
              the fullest extent permitted by law.
            </div>

            <div style={{ marginTop: 25 }}>
              <strong>Information Collection:</strong>
              <br />
              This section outlines the various types of information collected
              from users of the Vulconsols application (“App”), the methods
              employed for collection, such as website forms, cookies, and
              analytics tools, and elucidates the purposes for which such
              information is gathered. It serves to inform users comprehensively
              about the nature of data being collected and the rationale behind
              its collection.
            </div>

            <div style={{ marginTop: 25 }}>
              <strong>Types of Information Collected:</strong>
              <br />
              Vulconsols Inc (“Company” or “We”) collects diverse categories of
              information from users (“you” or “your”) through multiple
              channels, including but not limited to the App’s website forms,
              cookies, and analytics tools. This encompasses personal data such
              as names, contact details, Commercial Driving License (CDL)
              numbers, along with non-identifiable information and internet
              usage details.
            </div>

            <div style={{ marginTop: 25 }}>
              <strong>Methods of Collection:</strong>
              <br />
              Information is collected through various means, including user
              inputs in website forms, automated data capture via cookies, and
              analytical tools tracking user interactions with the App. These
              methods are utilized to ensure the efficiency and functionality of
              the services provided by Vulconsols Inc
            </div>

            <div style={{ marginTop: 25 }}>
              <strong>Purposes of Collection:</strong>
              <br />
              The data collected is employed for several purposes, including but
              not limited to enhancing user experience, providing personalized
              services, and optimizing the performance of the App. Additionally,
              data may be utilized for analytical insights, improving service
              offerings, and complying with legal obligations
            </div>
          </div>

          <div style={{ marginTop: 25 }}>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at support@Vulconsols.com.
          </div>
        </div>
      </section>
    </>
  );
}

export default Privacy;
