import React, { useState } from "react";
import truckdriver from "./imagesDriver/driversafty.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import "./Driver.css";
import ManageSafety from "./ManageSafety";
import Faqs from "./Faqs";
import { LABELS as _LABELS } from "../../Language";

const LABELS = _LABELS.DriverSafety;

function DriverSafety() {
  const [showVideo, setShowVideo] = useState(false);

  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };
  return (
    <>
      <div className="bg-white">
        <section className="p-4 mx-auto max-w-screen-xl pb-12 px-4 items-center lg:flex md:px-8">
          <div className="space-y-4 flex-1 sm:text-center lg:text-left">
            <h1 className="underline decoration-solid">
              {LABELS.ProductsDriverSafety}
            </h1>
            <h1 className="text-black font-bold text-4xl xl:text-4xl">
              {
                LABELS.All_in_one_security_solution_for_fleet_protection_and_profits
              }
              {/* <span className="text-indigo-400">  Safety Solutions</span> */}
            </h1>
            <p className="text-gray-700 max-w-xl leading-relaxed sm:mx-auto lg:ml-0">
              {
                LABELS.EfficientlyOverseeFleetSafetyAchieveTangibleResults_and_Gain_Valuable_Time_Back_in_Your_Schedule
              }
            </p>
            <div className="pt-10 items-center justify-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex lg:justify-start">
              <div className="rounded-md shadow">
                <Link
                  to="/demo"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 md:py-4 md:text-lg md:px-10"
                >
                  {LABELS.Getstarted}
                </Link>
              </div>
            </div>
            <div>
              <div onClick={toggleVideo} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  className="mr-2 text-2xl text-blue-700"
                />
                <span className="font-bold hover:text-blue-600 ">
                  {LABELS.See_how_it_works}
                  <span className="text-gray-400">(0:20)</span>
                </span>
              </div>
              {showVideo && (
                <div>
                  {/*<video controls>
                                        <source src={videoMp4} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>*/}
                </div>
              )}
            </div>
          </div>

          <div className="flex-1 text-center mt-7 lg:mt-0 lg:ml-3">
            <img
              src={truckdriver}
              className="w-full mx-auto sm:w-10/12  lg:w-full rounded-xl"
              alt="truckdriver"
            />
          </div>
        </section>
      </div>
      {/* -----banner----- */}
      {/*<div className="bg-indigo-600">
                <div className="max-w-screen-xl mx-auto px-4 py-3 items-center gap-x-4 justify-center text-white sm:flex md:px-8 banner-content">
                    <p className="py-2 font-medium">
                        {LABELS.FreshFindings}
                    </p>
                    <Link className="flex-none inline-block w-full mt-3 py-2 px-3 text-center text-indigo-600 font-medium bg-white duration-150 hover:bg-gray-100 active:bg-gray-200 rounded-lg sm:w-auto sm:mt-0 sm:text-sm">
                        Learn more
                    </Link>
                </div>
            </div>*/}
      <ManageSafety />
      <Faqs />
    </>
  );
}

export default DriverSafety;
