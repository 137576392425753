import React from "react";
// import TrustedPartner from "../Dispatch/TrustedPartner";
import Contact from "./Container/Contact";
import ExtraBenifits from "./Container/ExtraBenifits";
import FeatureSection from "./Container/FeatureSection";
import NewBenifits from "./Container/NewBenifits";
import MobileApplication from "./Container/MobileApplication";
import MainSection from "./Container/MainSection";

function Home() {
  return (
    <>
      <MainSection />
      {/*<TrustedPartner />*/}
      <FeatureSection />
      <NewBenifits />
      <MobileApplication />
      <ExtraBenifits />
      <Contact />
    </>
  );
}

export default Home;
