import React from 'react';
import LineingImage from "../../assets/images/Line 28.svg"
import HubsPOTlogo from "../../assets/icons/HubSpotLogo.svg";
import Starlogo from "../../assets/icons/StarLogo.svg";
import Zoomlogo from "../../assets/icons/ZoomLogo.svg";
import GoogleMeetLogo from "../../assets/icons/GooglemeetLogo.svg";
import ZendeskLogo from "../../assets/icons/ZendeskLogo.svg";
import { LABELS as _LABELS } from '../../Language';

const LABELS=_LABELS.HomePage;

function TrustedPartner() {

  return (
    <div   className="  h-auto flex justify-center flex-col items-center gap-4  bg-[#E6E6E6]"
    // style={{ backgroundImage: `url(${BackgroundNoise})` }}
    >
      <h2 className='font-semibold  text-2xl leading-10 mt-7'>{LABELS.OurTrustedPartners}</h2>
      <img src={LineingImage} alt='liningmark'/>

      <div className="py-10 ">
            <div className="max-w-screen-xl mx-auto px-4 md:px-8">

                <div className="mt-2">
                    <ul className="flex gap-x-10 gap-y-6 flex-wrap items-center justify-center md:gap-x-16">
                        {/* LOGO 1 */}
                      <li className=' w-56 h-20 bg-white rounded-lg flex justify-center'>
                      <img src={HubsPOTlogo} width={"100px"} alt='HubsPOTlogo'/>
                      </li>

                        {/* LOGO 2 */}

                        <li className=' w-36 h-20 bg-white rounded-lg flex justify-center'>
                      <img src={Starlogo} width={"60px"} height={"1px"} alt='Starlogo'/>
                      </li>
                        {/* LOGO 3 */}
                        <li className=' w-56 h-20 bg-white rounded-lg flex justify-center'>
                      <img src={Zoomlogo} width={"100px"} alt='Zoomlogo'/>
                      </li>

                        {/* LOGO 4 */}
                        <li className=' w-36 h-20 bg-white rounded-lg flex justify-center'>
                      <img src={GoogleMeetLogo} width={"100px"} alt='GoogleMeetLogo'/>
                      </li>
                       {/* LOGO 5*/}
                       <li className=' w-56 h-20 bg-white rounded-lg flex justify-center'>
                      <img src={ZendeskLogo} width={"100px"} alt='ZendeskLogo'/>
                      </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TrustedPartner
