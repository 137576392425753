export const LABELS={
    GPSFleetTracking:"GPS - Fleet Tracking",
    RealTimeGPSTracking:"Real-Time GPS Tracking",
    DailyRouteMaps:"Daily Route Maps",
    LiveAlertNotifications:"Live Alert Notifications",
    CreateGeofences:"Create Geo-fences",
    DriverBehaviour:"Driver Behaviour",
    ReportsAnalytics:"Reports & Analytics",

    WhyShouldYouUseReal_timeGPSTracking:"Why Should You Use Real-time GPS Tracking?",
    Save_Money_Increase_Productivity_Improve_Service:"Save Money. Increase Productivity. Improve Service.",

    TrackinrealtimeEverything_isAccessed_over_the_web_and_mobile_APP:"Track in real time. Everything is accessed over the web and mobile APP.",
    ViewalltheJourneysAndRoutesTakenon_a_day:"View all the journeys and routes taken on a day.",
    BealertedToJustthe_eventsyouConsiderImportant:"Be alerted to just the events you consider important.",
    CompleteControlandprotectyourassetsAndBusiness:"Complete control and protect your assets and business.",
    SeeSpeedingHarshAccelerationBrakingSharp_turn_unnecessary_idling_etc:"See speeding, harsh acceleration & braking, sharp turn, unnecessary idling, etc.",
    DataRichreportsGenerate_analytics_to_help_saveyou_time_and_money:"Data-rich reports generate analytics to help save you time and money.",


    Vulcansols_is_continually_improving_to_meet_the_needs_of_todays_high_demand_for_trucking_and_brokerage_professionals_Vulcansols_is_designed_to_provide_advanced_features_of_Tracking_and_Remote_Immobilization_toourCustomers_that_keeps_theTrack_of_various_features_of_vehicles_like_ignitionSpeed_over_limitEtc:"Vulcansols is continually improving to meet the needs of today's high demand for trucking and brokerage professionals. Vulcansols Eld is designed to provide advanced features of Tracking and Remote Immobilization to our customers that keeps the track of various features of vehicles like ignition, speed over limit etc.",
}