import React from "react";
import { Link } from "react-router-dom";
import VulcansolsLogo from "../../assets/images/Vulcansolslgo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageSelector from "../SelectLanguage/LanguageSelect";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <div className="py-6 border-t bg-black flex flex-col sm:flex-row items-center justify-between text-white">
      <img
        src={VulcansolsLogo}
        alt="Vulcansols"
        className="mx-auto sm:ml-16 h-10"
      />
      <div className="flex flex-wrap items-center gap-4 mt-6 sm:mt-0 text-white mx-auto sm:mr-16 sm:text-sm text-center">
        <span>
          <FontAwesomeIcon icon={faShieldAlt} className="text-white text-xl" />
          <Link to="/privacy">
            <span style={{ fontSize: 16 }}>{" Privacy Policy"}</span>
          </Link>
        </span>
      </div>
      <div className="flex flex-wrap items-center gap-4 mt-6 sm:mt-0 text-white mx-auto sm:mr-16 sm:text-sm text-center">
        <span>
          <LanguageSelector />
        </span>
        <span> |</span>
        <p>@ 2024 Vulcansols Inc.</p>
      </div>
    </div>
  );
}

export default Footer;
