import React from 'react'
import { Link } from 'react-router-dom';
import dashcamimage from "./imagesDriver/cp4.png";
import { LABELS as _LABELS } from '../../Language';

 const LABELS=_LABELS.DriverSafety;

function ManageSafety() {
    return (
        <>
            <section className="sm:mt-6 lg:mt-8 mt-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

                <div
                    className="my-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 flex gap-3 lg:flex-justify lg:flex flex-col lg:flex-row">
                    <div className="sm:text-center lg:text-left">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-800 sm:text-4xl md:text-4xl">
                            <span className="block xl:inline">{LABELS.Proactively_manage_safety}</span>
                            {/* <span className="block text-indigo-600 xl:inline">online business</span> */}
                        </h1>
                        <p
                            className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                           {LABELS.DriveTowardsEnhancedFleetSafetyandReducedRisk_withVulcansols}
                        </p>
                        <ul className=' ml-6 p-2'>
                            <li>{LABELS.ReliableAI}</li>
                            <li>{LABELS.Collisiondetection}</li>
                            <li>{LABELS.PrivacyMode}</li>
                        </ul>
                        {/* <!-- Button Section --> */}
                        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                            <div className="rounded-md shadow">
                                <Link to="/demo"
                                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 md:py-4 md:text-lg md:px-10">
                                    {LABELS.Getstarted}
                                </Link>
                            </div>
                           
                        </div>
                        {/* <!-- End of Button Section --> */}
                    </div>

                    {/* <!--   Image Section     --> */}
                    <div className="lg:inset-y-0 lg:right-0 lg:w-1/2 my-4">
                        <img src={dashcamimage} alt="dashcame" />
                    </div>
                    {/* <!--   End of Image Section     --> */}

                </div>
                

            </section>
        </>
    )
}

export default ManageSafety