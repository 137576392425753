export const LABELS={
    TheAccountingSOftwareWithEasytoUseTools:"LE LOGICIEL DE COMPTABILITÉ AVEC DES OUTILS FACILES À UTILISER.",
    FlexibleAccurateBilling:"Facturation Flexible et Précise",
    BudgetReporting:"Rapport Budgétaire",
    DriverSettlements:"Règlements des Conducteurs",
    OneclickInvoice:"Facture en un Clic",
    Create1099:"Créer 1099",
    TracktotalCosts:"Suivi des Coûts Totaux",
    CheckPrinting:"Impression de Chèques",
    PaystubPrinting:"Impression des Bulletins de Paie",


    VulcansolsSoftwareEliminatesalltheguessworkfrompayroll_and_invoicingYou_will_know_exactly_what_you_owe_your_employees_and_how_much_your_customers_owe_you_and_everything_will_be_broken_down:"Le logiciel Vulcansols élimine toutes les conjectures de la paie et de la facturation. Vous saurez exactement ce que vous devez à vos employés et combien vos clients vous doivent, et tout sera décomposé. Vos affectations, dépenses et factures seront totalement précises avec un effort minimal de votre part.",
    Customizable_software_to_meet_the_workflow_needs_of_nearly_any_organization:"Logiciel personnalisable pour répondre aux besoins de flux de travail de presque n'importe quelle organisation. Sièges utilisateur illimités, vous offrant la flexibilité d'utiliser des applications dans tous les départements et d'éviter les silos. Des outils intuitifs fournis dans une solution pour exécuter l'ensemble de votre entreprise, offrant une automatisation sans complexité.",
    Eliminate_errors_and_cut_the_timeittakesto_generate_andpayyourstaff:"Éliminez les erreurs et réduisez le temps nécessaire pour générer et payer votre personnel, conducteurs. Il est toujours décourageant pour tout le monde de discuter avec un conducteur du nombre de kilomètres parcourus et du montant que vous lui devez. Le logiciel Vulcansols éliminera toutes les conjectures et vous permettra de savoir exactement ce que vous devez à votre conducteur et combien de kilomètres chaque conducteur a parcourus.",
    Vulcansols_Software_includes_accountingmodulesthatallows_you_to_quickly_send_edit_andcreate_invoices_with_one_click:"Le logiciel Vulcansols comprend des modules de comptabilité qui vous permettent d'envoyer, de modifier et de créer rapidement des factures en un clic. Vous pouvez créer une facture pour une ou plusieurs charges en un seul clic.",
    VulcansolsSoftware_helps_you_to_quickly_compile_all_the_information_required_to_submit_government_forms_like1099_withtheclick_of_a_button:"Le logiciel Vulcansols vous aide à compiler rapidement toutes les informations requises pour soumettre des formulaires gouvernementaux tels que le 1099 en un clic.",
    VulcansolsSoftware_helps_you_to_generate_reports_with_one_click_of_a_button_and_you_track_all_your_expenses_and_earnings_at_one_place:"Le logiciel Vulcansols vous aide à générer des rapports en un clic et vous suivez toutes vos dépenses et vos gains en un seul endroit.",
    VulcansolsSoftware_allows_you_to_generate_all_your_reports_with_one_click_and_then_you_can_easily_download_and_print_each_and_every_report_for_your_record:"Le logiciel Vulcansols vous permet de générer tous vos rapports en un clic, puis vous pouvez facilement les télécharger et les imprimer pour votre enregistrement.",
    You_can_easily_generate_Pay_stubs_and_pay_checks_for_each_driver_by_selecting_one_or_multiple_loads_delivered_by_the_driver:"Vous pouvez facilement générer des bulletins de paie et des chèques de paie pour chaque conducteur en sélectionnant une ou plusieurs charges livrées par le conducteur. Après avoir généré les chèques de paie et les bulletins de paie, vous pouvez simplement les télécharger et les imprimer.",
}
