export const LABELS={
    Streamlined_onboarding_for_a_seamless_start_with :"Intégration simplifiée pour un démarrage sans faille avec ",
    VulcansolsEld:"Vulcansols Eld",
    RequestDemo:"Demander une démo",


    accelerated_workforce_onboarding: "40% d'accélération de l'intégration de la main-d'œuvre",
    Speed_up_time_toProficiency: "Accélérer le temps jusqu'à la maîtrise",
    Consistent_training_for_every_shift:"Formation cohérente pour chaque quart de travail",
    Empower_workers_with_confidence:"Donner confiance aux travailleurs",
    Always_on_accessible_training:"Formation toujours accessible",
    Training_with_real_work_examples:"Formation avec des exemples de travail réels",
    Hear_from_our_customers:"Écoutez ce que disent nos clients",

    upleveltheonboardingProcesstoensurenewhirefeelconfident: "Rehaussez le processus d'intégration pour que les nouveaux employés se sentent confiants",
    keep_new_hire_engaged_from_the_start_with_dynamic_etc: "Gardez les nouveaux employés engagés dès le début avec des vidéos dynamiques d'intégration qui capturent - et maintiennent - leur attention.",


    ourCustomerExprienceSpeakVolumeAboutTheOfOurEldSolution:"Les expériences de nos clients témoignent de la valeur de notre solution ELD. Voici ce qu'ils ont à dire :",
   
    Readyto_innovate_knowledge_retention_in_your_organization:"Prêt à innover dans la rétention des connaissances dans votre organisation",
    Request_a_demo_today_to_experience:"Demandez une démo dès aujourd'hui pour découvrir la puissance de Vulcansols de première main. Pour des questions et des consultations, contactez-nous, nous sommes là pour vous aider à prospérer grâce à la préservation des connaissances.",
}