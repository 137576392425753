import React, { useEffect, useState } from "react";
import vulcansolsLogo from "../../assets/images/logo-vulconsols.svg";
import { Link } from "react-router-dom";
import { LABELS as _LABELS } from "../../Language";
import { dropdownNavs } from "./Dropdown";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const LABELS = _LABELS.HomePage;

function Header() {
    const [state, setState] = useState(false);
    const [drapdownState, setDrapdownState] = useState({ isActive: false, idx: null })
    const navigation = [
        { title: LABELS.dispatch, path: "/dispatch", isDrapdown: false },
        { title: LABELS.compliance, path: "/compliance", isDrapdown: false },
        { title: LABELS.safety, path: "/safety", isDrapdown: false },
        { title: LABELS.tracking, path: "/tracking", isDrapdown: false },
        { title: LABELS.resources, path: "/resources", isDrapdown: true, navs: dropdownNavs },
    ];

    useEffect(() => {
        document.onclick = (e) => {
            const target = e.target;
            if (!target.closest(".nav-menu")) setDrapdownState({ isActive: false, idx: null });
        };
    }, [])

    const handleNavigationClick = () => {
        setState(false);
        setDrapdownState({ isActive: false, idx: null }); // Reset dropdown state
    };

    return (
        <>
            <nav className={`sticky top-0 bg-white z-50 md:text-sm ${state
                    ? "shadow-lg rounded-xl border mx-2 mt-2 md:shadow-none md:border-none md:mx-2 md:mt-0"
                    : ""
                }`}>
                <div className="items-center gap-x-14 px-4 max-w-screen-xl mx-auto lg:flex md:px-8 stricky top-0">
                    <div className="flex items-center justify-between py-3 lg:py-5 lg:block">
                        <Link to="/">
                            <img
                                src={vulcansolsLogo}
                                alt="vulcansolsLogo"
                                className="h-10 "
                            />
                        </Link>
                        <div className="lg:hidden">
                            <button className="text-gray-500 hover:text-gray-800"
                                onClick={() => setState(!state)}
                            >
                                {
                                    state ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                            <path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                                        </svg>

                                    )
                                }
                            </button>
                        </div>
                    </div>
                    <div className={`nav-menu flex-1 pb-3 mt-8 lg:block lg:pb-0 lg:mt-0 ${state ? 'block' : 'hidden'}`}>
                        <ul className="items-center space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
                            {
                                navigation.map((item, idx) => {
                                    return (
                                        <li key={idx}>
                                            {
                                                item.isDrapdown ? (
                                                    <button className="w-full flex items-center justify-between gap-1 text-gray-700 hover:text-indigo-600"
                                                        onClick={() => setDrapdownState({ idx, isActive: !drapdownState.isActive })}
                                                    >
                                                        {item.title}
                                                        {
                                                            drapdownState.idx === idx && drapdownState.isActive ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                                    <path fillRule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clipRule="evenodd" />
                                                                </svg>

                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                                                </svg>
                                                            )
                                                        }
                                                    </button>
                                                ) : (
                                                    <Link to={item.path} className="block text-gray-700 hover:text-indigo-600" onClick={handleNavigationClick}>
                                                        {item.title}
                                                    </Link>
                                                )
                                            }
                                            {
                                                item.isDrapdown && drapdownState.idx === idx && drapdownState.isActive ? (
                                                    <div className="mt-6 inset-x-0 top-20 w-1/2 rounded-xl m-auto md:absolute md:border-y md:shadow-md md:mt-0 bg-white z-20">
                                                        <ul className=' mx-auto grid items-center gap-4 md:p-8 md:grid-cols-2 '>
                                                            {item?.navs.map((dropdownItem, idx) => (
                                                                <li key={idx}>
                                                                    <p className="text-indigo-600 text-sm font-bold">{dropdownItem.label}</p>
                                                                    <ul className='mt-5 space-y-6'>
                                                                        {dropdownItem.navs.map((navItem, idx) => (
                                                                            <li key={idx} className="group">
                                                                                <Link to={navItem.path} className='flex gap-3 items-center group-hover:underline'>

                                                                                    <div onClick={handleNavigationClick}>
                                                                                        <span className="text-gray-800 duration-200 group-hover:text-indigo-600 text-sm font-medium md:text-base">{navItem.title}</span>
                                                                                        <span className="opacity-0 group-hover:opacity-100 transition-opacity ml-1"><FontAwesomeIcon icon={faArrowRight} className="text-indigo-600" /></span>
                                                                                    </div>
                                                                                </Link>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ) : ""
                                            }
                                        </li>
                                    )
                                })
                            }
                            <div className='flex-1 items-center justify-end gap-x-6 space-y-3 lg:flex lg:space-y-0'>
                                <li>
                                    <Link to={"/demo"} className="block bg-transparent text-center hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                        {LABELS.demo}
                                    </Link>
                                </li>
                                <li>
                                    <a href="https://www.portal.vulcansols.com/" className="block bg-white text-center hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow" target="_blank" rel="noopener noreferrer">
                                        {LABELS.login}
                                    </a>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </nav>
            {
                state ? (
                    <div
                        className="z-10 fixed top-0 w-screen h-screen bg-black/20 backdrop-blur-sm md:hidden"
                        onClick={() => setState(false)}></div>
                ) : ""
            }
        </>
    );
}

export default Header;
