export const LABELS={
 GPSFleetTracking: "Seguimiento de Flota GPS",
  RealTimeGPSTracking: "Seguimiento GPS en Tiempo Real",
  DailyRouteMaps: "Mapas de Ruta Diaria",
  LiveAlertNotifications: "Notificaciones de Alerta en Vivo",
  CreateGeofences: "Crear Geovallas",
  DriverBehaviour: "Comportamiento del Conductor",
  ReportsAnalytics: "Informes y Análisis",



  WhyShouldYouUseReal_timeGPSTracking:"¿Por qué debería utilizar el seguimiento GPS en tiempo real?",
  Save_Money_Increase_Productivity_Improve_Service:"Ahorrar dinero. Aumentar la productividad. Mejorar el Servicio.",


  TrackinrealtimeEverything_isAccessed_over_the_web_and_mobile_APP: "Rastrear en tiempo real. Todo se accede a través de la web y la aplicación móvil.",
  ViewalltheJourneysAndRoutesTakenon_a_day: "Ver todos los viajes y rutas realizadas en un día.",
  BealertedToJustthe_eventsyouConsiderImportant: "Reciba alertas solo de los eventos que considere importantes.",
  CompleteControlandprotectyourassetsAndBusiness: "Control completo y protección de sus activos y negocio.",
  SeeSpeedingHarshAccelerationBrakingSharp_turn_unnecessary_idling_etc: "Ver exceso de velocidad, aceleración brusca, frenado brusco, giros bruscos, ralentí innecesario, etc.",
  DataRichreportsGenerate_analytics_to_help_saveyou_time_and_money: "Informes ricos en datos generan análisis para ayudarlo a ahorrar tiempo y dinero.",

  Vulcansols_is_continually_improving_to_meet_the_needs_of_todays_high_demand_for_trucking_and_brokerage_professionals_Vulcansols_is_designed_to_provide_advanced_features_of_Tracking_and_Remote_Immobilization_toourCustomers_that_keeps_theTrack_of_various_features_of_vehicles_like_ignitionSpeed_over_limitEtc: "Vulcansols está mejorando continuamente para satisfacer las necesidades de los profesionales del transporte y la intermediación de hoy en día. Vulcansols está diseñado para proporcionar características avanzadas de seguimiento e inmovilización remota a nuestros clientes que mantienen el seguimiento de varias características de los vehículos como la ignición, velocidad sobre el límite, etc."
};