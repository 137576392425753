import React, { useState } from 'react';
import axios from 'axios';
import supportAvailableImage from "../../../../assets/images/home/supportAvailable.jpg";
import {LABELS as _LABELS } from '../../../../Language';

    const LABELS=_LABELS.HelpSupport;

const contactMethods = [
    {
        icon:
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
            </svg>,
        contact: "support@vulcansols.com"
    },
    {
        icon:
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
            </svg>,
        contact: "+1 (925) 744-5005"
    },
];

function SupportAvailable() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [formStatus, setFormStatus] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormStatus('loading');

        try {
            const response = await axios.post('https://osritapi.com/api/issues/contactus', formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                setFormStatus('success');
                setFormData({ name: '', email: '', message: '' });
            } else {
                setFormStatus('error');
            }
        } catch (error) {
            setFormStatus('error');
        }
    };

    return (
        <main className="py-14 ">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                <div className="max-w-lg mx-auto gap-12 justify-between lg:flex lg:max-w-none">
                    <div className="max-w-lg space-y-3">
                        <h3 className="text-indigo-600 font-semibold text-xl">
                            {LABELS.SupportAvailable}
                        </h3>
                        <p className="text-gray-800 text-3xl font-bold sm:text-4xl">
                            {LABELS.ContactSupport}
                        </p>
                        <p className="mt-4 text-lg">
                           {LABELS.For_the_fastest_response_please_submit_a_case_online_through_your_Vulcansols_Inc}
                        </p>
                        <p className="mt-2 text-black">
                            {LABELS.Administration}: <span className="font-bold">Hours:</span> {LABELS.hours_a_day_7_days_a_week}
                        </p>
                        <ul className="mt-6 flex flex-wrap gap-x-10 gap-y-6 items-center">
                            {contactMethods.map((item, idx) => (
                                <li key={idx} className="flex items-center gap-x-3">
                                    <div className="flex-none text-gray-400">
                                        {item.icon}
                                    </div>
                                    <p>{item.contact}</p>
                                </li>
                            ))}
                        </ul>
                        <div className="mt-6">
                            <h4 className="text-xl font-semibold">{LABELS.Quick_Contact_Form}</h4>
                            <form onSubmit={handleSubmit} className="mt-4 space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">{LABELS.Name}</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">{LABELS.Email}</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">{LABELS.Message}</label>
                                    <textarea
                                        name="message"
                                        rows="4"
                                        value={formData.message}
                                        onChange={handleChange}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    ></textarea>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {LABELS.SendMessage}
                                    </button>
                                </div>
                            </form>
                            {formStatus === 'loading' && <p className="text-blue-500 mt-4">{LABELS.Submitting}</p>}
                            {formStatus === 'success' && <p className="text-green-500 mt-4">{LABELS.Message_sent_successfully}</p>}
                            {formStatus === 'error' && <p className="text-red-500 mt-4">{LABELS.Something_went_wrong_Please_try_again}</p>}
                        </div>
                    </div>
                    <div className="flex-1 mt-12 sm:max-w-lg lg:max-w-md">
                        <img src={supportAvailableImage} alt='support' className='rounded-2xl'/>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default SupportAvailable;
