import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import CarrierIcon from "../../../assets/icons/Carrier.svg";
import BrokerageIcon from "../../../assets/icons/Brokerage.svg";
import RecruitingIcon from "../../../assets/icons/Recruiting.svg";
import AccountingIcon from "../../../assets/icons/AccountingIcon.svg";
import LocationIcon from "../../../assets/icons/LocationIcon.svg";
import CompalainceIcon from "../../../assets/icons/ComplainceIcon.svg";
import { LABELS as _LABELS } from '../../../Language';
import { Link } from 'react-router-dom';

const LABELS=_LABELS.HomePage;

function FeatureSection() {
    const features = [
        {
            icon:
                <img src={CarrierIcon} alt='CarrierLogo' />,
            title: LABELS.carriers,
            desc: LABELS.Stay_organized_Staypaid,
            path:"/carrier",
            Paragraph: LABELS.Manage_everything_from_IFTA_to_driver_ownerOperatorSettlementsAnd_invoicing_allWithin_one_web_platform
        },
        {
            icon:
                <img src={BrokerageIcon} alt='Brokerage' />,
            title: LABELS.brokerage,
            desc: LABELS.StreamlineYourbackOffice,
            path:"/brokerage",
            Paragraph: LABELS.Manage_loads_carrier_payables_agent_offices_payroll_and_invoicing_to_be_professional_and_profitable
        },
        {
            icon:
                <img src={RecruitingIcon} alt='Recruiting' />,
            title: LABELS.recruiting,
            desc: LABELS.QuickDriverHiring,
            path:"/recruiting",
            Paragraph: LABELS.GetAlltheNecessaryInformationof_a_driverandmanageallTheApplicationsOnASinglePage,
        },
        {
            icon:
                <img src={AccountingIcon} alt='Accounting' />,
            title: LABELS.accounting,
            desc: LABELS.OneClickPayablesAndReceivables,
            path:"/accounting",
            Paragraph: LABELS.After_creating_a_load_the_invoice_is_ready_or_youAnd_with_assigning_driver_or_carrier_settlement_is_ready,
        },
        {
            icon:
                <img src={LocationIcon} alt='GPS tracking' />,
            title: LABELS.gpsTracking,
            desc: LABELS.EasilyTrackYourTractororDriver,
            path:"/tracking",
            Paragraph: LABELS.You_an_easily_connect_your_existing_ELD_GPS_racker_to_get_ealtime_tracking_nformation_or_use_our_mobile_app_to_rack_driver
        },
        {
            icon:
                <img src={CompalainceIcon} alt='Complainceicon' />,
            title: LABELS.Compliance,
            desc: LABELS.Stay_on_top_of_compliance,
            path:"/complian",
            Paragraph: LABELS.Vulconsols_Safety_not_only_offers_solutions_to_assist_you_in_maintaining_compliance_but_we_deliver_the_news_you_need_to_know,
        },
    ]
    return (
        <>
            <section className="py-16 relative">
                <div className="relative z-10 max-w-screen-xl mx-auto px-4 justify-between items-center gap-12 md:px-8 md:flex">
                    <div className="flex-1 max-w-lg">
                        <h3 className="text-3xl font-bold">
                            {LABELS.An_All_inOneTrucking} <br />
                            {LABELS.ManagementSoftware} <br />
                            {LABELS.Solution}

                        </h3>
                    </div>
                    <div className="flex-1 mt-6 md:mt-0">
                        <p className='leading-7 text-base font-medium'>{LABELS.Firecamp_lets_you_choose_your_preferred_client_for_your_API_tack_to_cut_down_on_complexitiesAndTime}</p>
                        <button className="mt-6 px-6 py-3.5 text-white bg-black rounded-full duration-150 hover:bg-indigo-500 active:bg-indigo-700"
                        >{LABELS.seeMore}
                            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                        </button>
                    </div>
                </div>
                <div className="absolute inset-0 w-full h-full" style={{ background: "linear-gradient(137.92deg, rgba(192, 132, 252, 0) 20.43%, rgba(232, 121, 249, 0.26) 49.66%, rgba(204, 171, 238, 0) 92.38%)" }}></div>
                <div>
                    <section className="py-14">
                        <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                            <div className="relative max-w-2xl mx-auto sm:text-center">

                                <div className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
                            </div>
                            <div className="relative mt-12">
                                <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                                    {
                                        features.map((item, idx) => (
                                            <li key={idx} className="bg-white space-y-3 p-4 border rounded-lg">
                                                <div className="text-indigo-600 pb-3 flex justify-between">
                                                    {item.icon}
                                                    <p>{LABELS.MintRead}</p>
                                                </div>
                                                <h4 className="text-lg text-gray-800 font-semibold">
                                                    {item.title}
                                                </h4>
                                                <p>
                                                    {item.desc}
                                                </p>
                                                <p>
                                                    {item.Paragraph}
                                                </p>
                                                <Link to={item.path}>
                                                <button className=" mt-2 px-6 py-3.5 text-white bg-black rounded-full duration-150 hover:bg-indigo-500 active:bg-indigo-700 "
                                                >
                                                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                                </button>
                                                </Link>
                                                
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div> 
                        </div>
                    </section>
                </div>
            </section>

        </>
    )
}

export default FeatureSection