export const LABELS={
    PlanningDispatch:"Planification & Dispatch",
    OurTrustedPartners:"Nos Partenaires de Confiance",
    EasyLoadManagement:"Gestion Facile des Chargements",
    DetailedLoadInformation:"Informations Détaillées sur les Chargements",
    WeatherReports:"Rapports Météorologiques",
    RealtimeLoadUpdates:"Mises à Jour des Chargements en Temps Réel",
    LocationTracking:"Suivi de Localisation",
    VehicleManagement:"Gestion de Véhicules",
    DriverManagement:"Gestion des Conducteurs",
    CustomReport:"Rapport Personnalisé",
    RevenueExpenseManagement:"Gestion des Revenus / Dépenses",
    seeMore:"Voir Plus",
    showLess:"Voir Moins",


    RealTimeGpsTracking:"Suivi GPS en Temps Réel",
    AutomatedLoadOptimization:"Optimisation Automatisée des Chargements",
    InstantIFTA_Reporting:"Rapport IFTA Instantané",
    AI_PoweredDriverCoaching:"Formation de Conducteur Alimentée par l'IA",
    FleetMaintenanceAlerts:"Alertes de Maintenance de Flotte",
    EfficientRoutePlanning:"Planification Efficace des Itinéraires",
    DriverTracking:"Suivi des Conducteurs",
    generateIftaReprt:"Générer un Rapport IFTA",
    SmartDashcam:"Dashcam Intelligente",
    VehicleDiagnostics: "Diagnostics du Véhicule",
    AssetTracking:"Suivi des Actifs",
    VehicleInspections:"Inspections de Véhicules",
    report:"RAPPORTS",
    tracking:"SUIVI",
    document:"DOCUMENT",
    profitloss:"BÉNÉFICES & PERTES",
    stateMilage:"KILOMÉTRAGE PAR ÉTAT",
    driverpayment:"PAIEMENT DES CONDUCTEURS",
    TruckMilage:"KILOMÉTRAGE DU CAMION",

    TotalOverview:"Aperçu total",
    Tracking:"Suivi",
    TotalVehicleManagement:"Gestion totale des véhicules",
    SinceLastMonth:"3 % depuis le mois dernier",
    CustomerSatisfaction:"Satisfaction du client",
    DashboardReport:"Rapport du tableau de bord",
    Profileviews:"Vues du profil",
    ProjectDonethisyear:"Projet réalisé cette année",




    shipper_and_consingeeInformation:"Informations sur l'expéditeur et le destinataire",
    pickup_and_deliverylocation:"Lieu de ramassage et de livraison",
    Route_Infomation:"Informations sur l'itinéraire",
    driver_and_vehicleInfomation:"Informations sur le conducteur et le véhicule",
    loadStatusUpdates:"Mises à jour de l'état du chargement",

    driversafetyTips:"Consejos de seguridad para el conductor",
    weatherImpactAnalysis:"Análisis de impacto climático",
    weatherRelatedRoutesOptimize:"Optimización de rutas relacionadas con el clima",

    OurLogisticDispatchSoftware_is_a_comprehensive_solution_designToOptimizeAndAutomateTheDispatchingProcessForLogisticsCompanies:"Notre logiciel de dispatch logistique est une solution complète conçue pour optimiser et automatiser le processus de dispatching pour les entreprises de logistique. De la gestion des commandes à l'attribution des itinéraires et au suivi des livraisons, notre logiciel rationalise les opérations, réduit les coûts et améliore l'efficacité globale. Avec des fonctionnalités avancées et un design intuitif, c'est l'outil ultime pour la gestion moderne de la logistique.",
    BeginningWith_a_strong_core_of_functionality_that_every_trucking_company_needs_the_Vulcansols_system_comes_with_the_ability_to_add_multiple_functional_modules_fully_tested_integration_products_for_other_transportation_managementAnd_trucking_industry_solutions_ranging_from_mobile_CommunicationsTovehicle_maintenance_management:"Commençant par un solide noyau de fonctionnalités dont chaque entreprise de transport a besoin, le système Vulcansols est capable d'ajouter plusieurs modules fonctionnels entièrement testés, des produits d'intégration pour d'autres solutions de gestion des transports et de l'industrie du transport, allant des communications mobiles à la gestion de la maintenance des véhicules.",
    VulcansolsSoftwarehelpsYouToManageAllYourLoadsEasilyWhether_its_FTLor_LTL:"Le logiciel Vulcansols vous aide à gérer tous vos chargements facilement, qu'il s'agisse de FTL ou de LTL. Vous pouvez facilement gérer et dispatcher plusieurs chargements d'un seul client ou de plusieurs clients sur un seul camion avec l'aide du logiciel Vulcansols. Vous pouvez facilement gérer le paiement des chargements, le statut de livraison, le conducteur unique ou en équipe, les prises ou les livraisons uniques ou multiples, et bien plus encore pour chacun de vos chargements sur une seule page.",

    EachLoadmayhave_a_lot_of_data_toManageAndWiththehelpofVulcansolsSoftwareyoucanseedetailedinformationforeachload:"Chaque chargement peut comporter beaucoup de données à gérer et avec l'aide du logiciel Vulcansols, vous pouvez voir des informations détaillées pour chaque chargement. Vous pouvez facilement vérifier combien de prises et de livraisons il y a dans un seul chargement ou s'il y a un conducteur unique ou en équipe pour chaque chargement. De plus, vous pouvez voir des informations sur l'entreprise, l'affacturage, le véhicule et la prévision météorologique pour chacun de vos chargements.",
    Weather_reports_are_very_important_in_trucking_ItIsNotPossible_forhumans_to_controlWeather_and_to_overcome_that_VulcansolsSoftwarewillprovideyou_10_daysweatherforecasting_soyou_can_plan_your_loads_accordingly :"Les rapports météorologiques sont très importants dans le transport routier. Il n'est pas possible pour les humains de contrôler la météo et pour surmonter cela, le logiciel Vulcansols vous fournira une prévision météorologique de 10 jours afin que vous puissiez planifier vos chargements en conséquence. Le logiciel Vulcansols vous donne une prévision météorologique de 10 jours pour chacune de vos adresses de prise et de livraison.",
    LoadUpdatesarereallyImportantwhenitcomestotruckingbusiness:"Les mises à jour des chargements sont vraiment importantes dans le transport routier. Il est impossible de contrôler l'erreur humaine ou la nature, cependant, le logiciel Vulcansols vous donnera une mise à jour en cas de retard afin que vous puissiez planifier d'autres choses en conséquence. De plus, avec le logiciel Vulcansols, vous pouvez spécifier si vous souhaitez recevoir une notification ou non pour chaque chargement et vous pouvez facilement activer et désactiver votre notification.",
    ItisveryImportanttoTrack_the_locationofvehiclesintransportbusiness:"Il est très important de suivre la localisation des véhicules dans le secteur du transport. Le logiciel Vulcansols vous aide à suivre les marchandises sur la route pour vous aider à planifier facilement vos futurs chargements. Grâce au suivi en temps réel de vos marchandises, vous pouvez prendre de meilleures décisions pour votre entreprise et cela vous aidera également à fournir un meilleur service client.",
    VulcansolsCanHelpyouinManaging_theInformation_of_all_your_tractors_and_trailers_in_one_place:"Vulcansols peut vous aider à gérer les informations de tous vos tracteurs et remorques en un seul endroit. Vous pouvez facilement voir et mettre à jour les informations de licence, de gage, de service, etc. pour n'importe quel véhicule d'un seul clic. Vous pouvez également télécharger des documents sur notre stockage cloud afin de les gérer en temps réel de n'importe où. Certains des avantages sont énumérés ci-dessous:",
    Managing_driver_isas_important_as_Vehicle_Management_Vulcansols_can_help_you_in_managing_the_informationof_all_your_driver:"La gestion des conducteurs est aussi importante que la gestion des véhicules. Vulcansols peut vous aider à gérer les informations de tous vos conducteurs. Vous pouvez facilement consulter et mettre à jour les informations du permis de conduire, de l'examen médical, etc. Vous pouvez également gérer facilement tous les documents de chacun de vos conducteurs avec notre stockage cloud.",
    Our_AI_PoweredDispatchSolutionprovidesthefeatureofcustomReports:"Notre solution de dispatch alimentée par l'IA offre la fonctionnalité de rapports personnalisés. Ces rapports peuvent vous aider à comprendre facilement chaque aspect de votre entreprise. Vous pouvez vérifier si vous tirez un profit d'un client particulier ou non et également comparer les rapports de différents véhicules pour vérifier combien vous gagnez avec chacun de vos véhicules. Vous pouvez facilement créer des rapports sur votre entreprise, vos conducteurs, vos camions, etc. Certains des rapports sont énumérés ci-dessous:",
    Our_AI_PoweredDispatchSolutionmakesitEasierToTrackAllTheRevenuesAndExpenses:"Notre solution de dispatch alimentée par l'IA facilite le suivi de tous les revenus et dépenses. Vous pouvez facilement ajouter toutes vos dépenses et revenus générés par différentes sources pour calculer le profit / la perte. Vous pouvez créer des rapports mensuels, trimestriels et annuels ou même créer un rapport pour une période personnalisée selon vos besoins pour mieux comprendre vos revenus et dépenses.",
    OurAI_PoweredDispatchSolutionhelps_you_toManageAll_your_loads_easily: "Notre solution de dispatch alimentée par l'IA vous aide à gérer tous vos chargements facilement, qu'il s'agisse de FTL ou de LTL. Vous pouvez facilement gérer et dispatcher plusieurs chargements d'un seul client ou de plusieurs clients sur un seul camion avec l'aide du logiciel Vulcansols. Vous pouvez facilement gérer le paiement des chargements, le statut de livraison, le conducteur unique ou en équipe, les prises ou les livraisons uniques ou multiples, et bien plus encore pour chacun de vos chargements sur une seule page.",

    OurAIPoweredDispatchSolution_can_help_you_inManaging_theInformation_of_all_your_tractors_and_trailers_in_one_place:"Notre solution de dispatch alimentée par l'IA peut vous aider à gérer les informations de tous vos tracteurs et remorques en un seul endroit. Vous pouvez facilement consulter et mettre à jour les informations de licence, de gage, de service, etc. pour n'importe quel véhicule d'un seul clic. Vous pouvez également télécharger des documents sur notre stockage cloud afin de les gérer en temps réel de n'importe où. Certains des avantages sont énumérés ci-dessous:"
}
