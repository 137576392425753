import React from "react";
import Stepper from "./Stepper";

function Demo() {
  return (
    <div style={{ paddingBottom: 45 }}>
      <Stepper />
    </div>
  );
}

export default Demo;
