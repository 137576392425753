import React from "react";
import Testimonials from "./Testimonials";
import education_trainingImage from "../../../../src/assets/images/educatiion_training.jpg";

function Education() {
  const features = [
    {
      title: "25% task performance improvement",
    },
    {
      title: "Training with real work examples",
    },
    {
      title: "Standardize the job",
    },
    {
      title: "Empower workers with confidence",
    },
    {
      title: "AI-powered assessment",
    },
    {
      title: "Always-on video accessibility",
    },
  ];
  return (
    <>
      <section className="py-14">
        <div className="max-w-screen-xl mx-auto md:px-8">
          <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">
            <div className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
              <h3 className="text-indigo-600 font-semibold  text-center text-3xl">
                Training at Vulcansols Eld
              </h3>
              <p className="text-gray-800 text-3xl font-semibold sm:text-2xl text-center">
                Tools and resources for every part of your journey
              </p>
              <p className="mt-3 text-gray-600">
                Embark on a transformative journey at Vulcansols Eld, where
                innovation meets expertise. Discover a dynamic curriculum
                curated to hone your skills and propel your career forward.
                Immerse yourself in hands-on learning experiences, guided by
                industry leaders, and unlock your full potential. Join a vibrant
                community of learners dedicated to excellence and seize every
                opportunity to grow. Your success starts here.
              </p>
            </div>
            <div className="flex-1 sm:hidden lg:block">
              <img
                src={education_trainingImage}
                className="md:max-w-lg sm:rounded-lg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-14">
        <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
          <div className="relative max-w-2xl mx-auto sm:text-center">
            <div className="relative z-10">
              <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                Comprehensive on-the-job training to uplevel your workforce
              </h3>
              <p className="mt-3">
                Today’s digital and global workforce are being challenged like
                never before. Keep them up-to-date on the latest innovations
                with the right platform.
              </p>
            </div>
            <div
              className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]"
              style={{
                background:
                  "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
              }}
            ></div>
          </div>
          <div className="relative mt-12">
            <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((item, idx) => (
                <li
                  key={idx}
                  className="bg-white space-y-3 h-44 border rounded-3xl flex justify-center items-center hover:bg-[#00A56D]"
                >
                  <h4 className="text-lg text-gray-800 font-semibold">
                    {item.title}
                  </h4>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <Testimonials />
    </>
  );
}

export default Education;
