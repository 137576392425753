export const LABELS={
    Streamlined_onboarding_for_a_seamless_start_with:"Proceso de incorporación optimizado para un inicio sin problemas con ",
    VulcansolsEld:"Vulcansols Eld",
    RequestDemo:"Solicitar una Demo",


    accelerated_workforce_onboarding: "40% de aceleración en la incorporación de la fuerza laboral",
    Speed_up_time_toProficiency: "Acelerar el tiempo hasta la competencia",
    Consistent_training_for_every_shift:"Entrenamiento consistente para cada turno",
    Empower_workers_with_confidence:"Empoderar a los trabajadores con confianza",
    Always_on_accessible_training:"Entrenamiento siempre accesible",
    Training_with_real_work_examples:"Entrenamiento con ejemplos de trabajo reales",
    Hear_from_our_customers:"Escucha lo que dicen nuestros clientes",

    upleveltheonboardingProcesstoensurenewhirefeelconfident: "Mejora el proceso de incorporación para asegurar que los nuevos empleados se sientan seguros",
    keep_new_hire_engaged_from_the_start_with_dynamic_etc: "Mantén a los nuevos empleados comprometidos desde el principio con videos dinámicos de incorporación que capturan - y mantienen - su atención.",
   

    ourCustomerExprienceSpeakVolumeAboutTheOfOurEldSolution:"Las experiencias de nuestros clientes hablan volumes sobre el valor de nuestra solución ELD. Esto es lo que ellos tienen que decir:",
   
    Readyto_innovate_knowledge_retention_in_your_organization:"Listo para innovar en la retención de conocimiento en tu organización",
    Request_a_demo_today_to_experience:"Solicita una demostración hoy mismo para experimentar el poder de Vulcansols de primera mano. Para consultas y asesoramiento, contáctanos, estamos aquí para ayudarte a prosperar a través de la preservación del conocimiento.",
}
