export const LABELS={
     PlanningDispatch: "Planificación y Despacho",
  OurTrustedPartners: "Nuestros Socios de Confianza",
  EasyLoadManagement: "Gestión Fácil de Cargas",
  DetailedLoadInformation: "Información Detallada de la Carga",
  WeatherReports: "Informes del Tiempo",
  RealtimeLoadUpdates: "Actualizaciones en Tiempo Real de la Carga",
  LocationTracking: "Seguimiento de Ubicación",
  VehicleManagement: "Gestión de Vehículos",
  DriverManagement: "Gestión de Conductores",
  CustomReport: "Informe Personalizado",
  RevenueExpenseManagement: "Gestión de Ingresos / Gastos",
  seeMore: "Ver Más",
  showLess: "Mostrar Menos",


  RealTimeGpsTracking:"Seguimiento GPS en tiempo real",
  AutomatedLoadOptimization:"Optimización de carga automatizada",
  InstantIFTA_Reporting:"Informes IFTA instantáneos",
  AI_PoweredDriverCoaching:"Entrenamiento de conductores impulsado por IA",
  FleetMaintenanceAlerts:"Alertas de mantenimiento de flotas",
  EfficientRoutePlanning:"Planificación de rutas eficiente",
  DriverTracking:"Seguimiento del conductor",
  generateIftaReprt:"Generar informe IFTA",
  SmartDashcam:"Cámara de salpicadero inteligente",
  VehicleDiagnostics: "Diagnóstico del vehículo",
  AssetTracking:"Seguimiento de activos",
  VehicleInspections:"Inspecciones de vehículos",

  report:"INFORMES",
  tracking:"SEGUIMIENTO",
  document:"DOCUMENTO",
  profitloss:"PÉRDIDA DE BENEFICIOS",
  stateMilage:"Millas del estado",
  driverpayment:"PAGO DEL CONDUCTOR",
  TruckMilage:"KILOMETRAJE DEL CAMIÓN",

   TotalOverview:"Resumen total",
    Tracking:"Seguimiento",
    TotalVehicleManagement:"Gestión total de vehículos",
    SinceLastMonth:"3% desde el mes pasado",
    CustomerSatisfaction:"La satisfacción del cliente",
    DashboardReport:"Informe del panel",
    Profileviews:"Visitas al perfil",
    ProjectDonethisyear:"Proyecto realizado este año",

    shipper_and_consingeeInformation:"Información del remitente y destinatario",
    pickup_and_deliverylocation:"Lugar de recogida y entrega",
    Route_Infomation:"Información de ruta",
    driver_and_vehicleInfomation:"Información del conductor y del vehículo",
    loadStatusUpdates:"Actualizaciones de estado de carga",


    driversafetyTips:"Consejos de seguridad para el conductor",
    weatherImpactAnalysis:"Análisis de impacto climático",
    weatherRelatedRoutesOptimize:"Optimización de rutas relacionadas con el clima",


    OurLogisticDispatchSoftware_is_a_comprehensive_solution_designToOptimizeAndAutomateTheDispatchingProcessForLogisticsCompanies:"Nuestro software de despacho logístico es una solución integral diseñada para optimizar y automatizar el proceso de despacho para empresas de logística. Desde la gestión de pedidos hasta la asignación de rutas y el seguimiento de entregas, nuestro software agiliza las operaciones, reduce los costos y mejora la eficiencia general. Con funciones avanzadas y diseño intuitivo, es la herramienta definitiva para la gestión logística moderna.",
    BeginningWith_a_strong_core_of_functionality_that_every_trucking_company_needs_the_Vulcansols_system_comes_with_the_ability_to_add_multiple_functional_modules_fully_tested_integration_products_for_other_transportation_managementAnd_trucking_industry_solutions_ranging_from_mobile_CommunicationsTovehicle_maintenance_management:"Comenzando con un sólido núcleo de funcionalidades que toda empresa de transporte necesita, el sistema Vulcansols viene con la capacidad de agregar múltiples módulos funcionales, productos de integración completamente probados para otras soluciones de gestión de transporte y de la industria de transporte que van desde comunicaciones móviles hasta gestión de mantenimiento de vehículos.",
    VulcansolsSoftwarehelpsYouToManageAllYourLoadsEasilyWhether_its_FTLor_LTL:"El software Vulcansols te ayuda a gestionar todas tus cargas fácilmente, ya sean FTL o LTL. Puede gestionar y enviar fácilmente múltiples cargas de uno o varios clientes en un solo camión con la ayuda del software Vulcansols. Puede administrar fácilmente el pago de cargas, el estado de entrega, la conducción con un solo conductor o en equipo, recogidas o entregas únicas o múltiples y mucho más para cada una de sus cargas en una sola página.",

    EachLoadmayhave_a_lot_of_data_toManageAndWiththehelpofVulcansolsSoftwareyoucanseedetailedinformationforeachload:"Cada carga puede tener muchos datos que gestionar y con la ayuda del software Vulcansols puedes ver información detallada de cada carga. Puede comprobar fácilmente cuántas recogidas y entregas hay en una sola carga o si hay conducción individual o en equipo para cada carga. Además, podrás ver información de la empresa, factoring, vehículo y pronóstico del tiempo para cada una de tus cargas.",
    Weather_reports_are_very_important_in_trucking_ItIsNotPossible_forhumans_to_controlWeather_and_to_overcome_that_VulcansolsSoftwarewillprovideyou_10_daysweatherforecasting_soyou_can_plan_your_loads_accordingly :"Los informes meteorológicos son muy importantes en el transporte por carretera. No es posible que los humanos controlen el clima y, para superarlo, Vulcansols Software le proporcionará un pronóstico del tiempo de 10 días para que pueda planificar sus cargas en consecuencia. El software Vulcansols le brinda un pronóstico del tiempo de 10 días para cada una de sus direcciones de recogida y entrega.",
    LoadUpdatesarereallyImportantwhenitcomestotruckingbusiness:"Las actualizaciones de carga son realmente importantes cuando se trata de negocios de transporte por carretera. Es imposible controlar el error humano o la Madre Naturaleza; sin embargo, Vulcansols Software le brindará una actualización cuando haya algún retraso para que pueda planificar otras cosas en consecuencia. Además, con el software Vulcansols puedes especificar si deseas recibir notificaciones o no para cada carga y puedes activar y desactivar fácilmente tus notificaciones.",
    ItisveryImportanttoTrack_the_locationofvehiclesintransportbusiness:"Es muy importante rastrear la ubicación de los vehículos en el negocio del transporte. El software Vulcansols le ayuda a realizar un seguimiento de las cargas que están en camino para ayudarle a planificar fácilmente sus cargas futuras. Con el seguimiento en tiempo real de tu carga podrás tomar mejores decisiones para tu empresa y también te ayudará a brindar un mejor servicio al cliente.",
    VulcansolsCanHelpyouinManaging_theInformation_of_all_your_tractors_and_trailers_in_one_place:"Vulcansols puede ayudarle a gestionar la información de todos sus tractores y remolques en un solo lugar. Puede ver y actualizar fácilmente información de licencia, gravamen, servicio, etc. para cualquiera de sus vehículos con un solo clic. También podrás subir los documentos del vehículo a nuestro almacenamiento en la nube para poder gestionarlos en tiempo real desde cualquier lugar. Algunos de los beneficios se enumeran a continuación:",
    Managing_driver_isas_important_as_Vehicle_Management_Vulcansols_can_help_you_in_managing_the_informationof_all_your_driver:"La gestión del conductor es tan importante como la gestión del vehículo. Vulcansols puede ayudarle a gestionar la información de todos sus conductores. Puede ver y actualizar fácilmente información de licencia de conducir, información médica, etc. También puede administrar fácilmente todos los documentos de cada uno de sus conductores con nuestro almacenamiento en la nube.",
    Our_AI_PoweredDispatchSolutionprovidesthefeatureofcustomReports:"Nuestra solución de despacho impulsada por IA ofrece la función de informes personalizados. Estos informes pueden ayudarle a comprender fácilmente todos y cada uno de los aspectos de su negocio. Puede verificar si está obteniendo ganancias de un cliente en particular o no y también comparar informes de diferentes vehículos para verificar cuánto está ganando con cada uno de sus vehículos. Puede crear fácilmente informes de su empresa, conductores, camiones, etc. Algunos de los informes se enumeran a continuación:",
    Our_AI_PoweredDispatchSolutionmakesitEasierToTrackAllTheRevenuesAndExpenses:"Nuestra solución de despacho impulsada por IA facilita el seguimiento de todos los ingresos y gastos. Puede agregar fácilmente todos sus gastos e ingresos generados de diferentes fuentes para calcular la ganancia/pérdida del estado de cuenta. Puede crear informes mensuales, trimestrales y anuales o incluso puede crear informes para períodos de tiempo personalizados según su necesidad de tener una mejor comprensión de sus ingresos y gastos.",
    OurAI_PoweredDispatchSolutionhelps_you_toManageAll_your_loads_easily: "Nuestra solución de despacho impulsada por IA lo ayuda a administrar todas sus cargas fácilmente, ya sea FTL o LTL. Puede administrar y enviar fácilmente múltiples cargas de uno o varios clientes en un solo camión con la ayuda del software Vulcansols. Puede administrar fácilmente el pago de cargas, el estado de entrega, la conducción con un solo conductor o en equipo, recogidas o entregas únicas o múltiples y mucho más para cada una de sus cargas en una sola página.",

    OurAIPoweredDispatchSolution_can_help_you_inManaging_theInformation_of_all_your_tractors_and_trailers_in_one_place:"Nuestra solución de despacho impulsada por IA puede ayudarlo a administrar la información de todos sus tractores y remolques en un solo lugar. Puede ver y actualizar fácilmente información de licencia, gravamen, servicio, etc. para cualquiera de sus vehículos con un solo clic. También podrás subir los documentos del vehículo a nuestro almacenamiento en la nube para poder gestionarlos en tiempo real desde cualquier lugar. Algunos de los beneficios se enumeran a continuación:"



}