import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import LoadManagementImage from "../../../assets/images/LoadManagement.jpg";
import { LABELS as _LABELS } from '../../../Language';

const LABELS=_LABELS.DispatchPage;

function Stats() {
    const [showMore, setShowMore] = useState(false);

    const handleToggle = () => {
        setShowMore(!showMore);
    };

    return (
        <section className="py-14"
        >
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 gap-x-12 items-start justify-between lg:flex md:px-8">
                <div className="mt-6 gap-12 sm:mt-0 md:flex lg:block">
                    <div className="max-w-2xl">
                        <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                            {LABELS.EasyLoadManagement}
                        </h3>
                        <p className="mt-3 max-w-xl text-base font-medium leading-7">
                           {LABELS.OurAI_PoweredDispatchSolutionhelps_you_toManageAll_your_loads_easily}
                        </p>
                        <ul className='mt-6 list-none flex-col space-y-6 font-normal'>
                            <li className='flex items-center'>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                {LABELS.RealTimeGpsTracking}
                            </li>
                            <li className='flex items-center'>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                {LABELS.AutomatedLoadOptimization}
                            </li>
                            {showMore && (
                                <>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                        {LABELS.InstantIFTA_Reporting}
                                    </li>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                        {LABELS.AI_PoweredDriverCoaching}
                                    </li>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                        {LABELS.FleetMaintenanceAlerts}
                                    </li>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                      {LABELS.EfficientRoutePlanning}
                                    </li>
                                </>
                            )}
                        </ul>
                        <button
                            onClick={handleToggle}
                            className="mt-6 px-6 py-3.5 text-white bg-black rounded-full duration-150 hover:bg-indigo-500 active:bg-indigo-700"
                        >
                            {showMore ? LABELS.showLess : LABELS.seeMore}
                            <FontAwesomeIcon icon={showMore ? faArrowDown : faArrowRight} className="ml-2" />
                        </button>
                    </div>
                </div>
                <div className="lg:block lg:max-w-xl lg: mt-5">
                    <img src={LoadManagementImage} className="rounded-lg" alt="loadManagementimage" />
                </div>
            </div>
        </section>
    );
}

export default Stats;
