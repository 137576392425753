import React from 'react';
import realtimeGps from "../../../assets/images/city3.webp";
import FleetTracking from '../../FleetTracking/FleetTracking';
import { LABELS as _LABELS } from '../../../Language';

const LABELS=_LABELS.FleetTracking;

function ExtraBenifitsDetails() {
  return (
   <>
      <section className="">
            <div className=" bg-cover mx-auto px-4 md:text-center md:px-8 border  " style={{ backgroundImage: `url(${realtimeGps})`, height: "10rem" }}>
                <div className="max-w-xl md:mx-auto mt-6 ">
                    <h3 className="text-white text-xl font-semibold sm:text-xl ">
                    {LABELS.WhyShouldYouUseReal_timeGPSTracking}
                    </h3>
                    <p className=" text-white font-bold mt-2">
                    {LABELS.Save_Money_Increase_Productivity_Improve_Service}
                    </p>
                </div>
                
            </div>
        </section>
       <FleetTracking/>
   </>
  )
}

export default ExtraBenifitsDetails;