import React from "react";
import CustomerExprience from "../../Resources/CustomerServices/onboarding.png";
import TrustedPartner from "../../Dispatch/TrustedPartner";
import { Link } from "react-router-dom";
import CustomerFeature from "./CustomerFeature";
import { LABELS as _LABELS } from "../../../Language";

const LABELS = _LABELS.CustomerService;

function CustomerService() {
  return (
    <div>
      <>
        <div
          className="bg-cover bg-center h-screen"
          style={{
            backgroundImage: `url(${CustomerExprience})`,
            height: "40rem",
          }}
        >
          <section className="py-28">
            <div className="max-w-screen-xl mx-auto px-4 md:text-center md:px-8">
              <div className="max-w-xl space-y-3 md:mx-auto">
                <p className="text-white text-4xl sm:text-4xl md:text-4xl lg:text-5xl font-extrabold leading-7 sm:leading-tight">
                  {LABELS.Streamlined_onboarding_for_a_seamless_start_with}
                  <span className="text-blue-600 ">{LABELS.VulcansolsEld}</span>
                </p>
              </div>
              <div className="mt-6">
                <Link
                  to="/demo"
                  className="inline-block py-2 px-4 text-white font-medium bg-gray-800 duration-150 hover:bg-gray-700 active:bg-gray-900 rounded-lg shadow-md hover:shadow-none"
                >
                  {LABELS.RequestDemo}
                </Link>
              </div>
            </div>
          </section>
        </div>

        <TrustedPartner />
        <CustomerFeature />
      </>
    </div>
  );
}

export default CustomerService;
