import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import FreeTrailLogo from "../../../assets/icons/FreeTrailLogo.svg";
import supportlogo from "../../../assets/icons/support.png";
import smartphonelogo from "../../../assets/icons/smartphone.png";
import PlanLogo from "../../../assets/icons/PlanLogo.svg";
import CancelLogo from "../../../assets/icons/CancelLogo.svg";
import NoCreditLogo from "../../../assets/icons/NoCreditLogo.svg";
import BackgroundNoise from "../../../assets/images/Background Noise.jpg";
import { LABELS as _LABELS } from "../../../Language";
import { Link } from "react-router-dom";

const LABELS = _LABELS.HomePage;
function ExtraBenifits() {
  const features = [
    {
      icon: <img src={supportlogo} alt="FreeTrailLogo" style={{width:"60px" ,height:"60px"}}/>,
      title: LABELS.oneMonthfreeTrail,
      path:"/supportAvailable",
    },
    {
      icon: <img src={smartphonelogo} alt="Planlogo" style={{width:"60px" ,height:"60px"}}/>,
      title: LABELS.AndroidIos_Apps_for_drivers,
      path:"/androidIosAppSupport"
    },
    {
      icon: <img src={PlanLogo} alt="Planlogo"  style={{width:"60px" ,height:"60px"}}/>,
      title: LABELS.planAccordingYourNeeds,
      // path:"/planInfomation"
    },
    {
      icon: <img src={CancelLogo} alt="CancelLogo"  style={{width:"60px" ,height:"60px"}}/>,
      title: LABELS.AnyTimeCancelation,
      // path:"/cancelAnytime"
    },
  ];
  return (
    <>
      <div className="bg-[#E6E6E6]">
        <section className="items-center max-w-screen-xl mx-auto md:flex md:px-8 xl:gap-12">
          <div className="flex-1 space-y-3 text-center md:text-left">
            <h3 className="text-2xl text-gray-800 font-bold lg:text-3xl mt-8">
              {LABELS.ExtraBenifits}
            </h3>
            <p className="text-gray-600 leading-relaxed w-full  mx-auto md:mx-0 text-wrap">
              {
                LABELS.Logistics_dispatch_Software_offers_a_wide_array_of_additional_benefits_beyond_its_core_functionality_of_managing_dispatch_operations
              }
            </p>
          </div>
          <Link
            to="/extrabenifitsDetails"
            className="mt-6 flex-1 flex justify-center md:justify-start"
          >
            <button className="mt-6 w-52 h-12 text-white bg-black rounded-full duration-150 hover:bg-indigo-500 active:bg-indigo-700">
              {LABELS.seeMore}
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </button>
          </Link>
        </section>
        <div className="">
          <section className="py-4">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
              <div className="relative max-w-2xl mx-auto sm:text-center">
                <div
                  className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]"
                  style={{
                    background:
                      "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
                  }}
                ></div>
              </div>
              <div className="relative mt-12">
                <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
                  {features.map((item, idx) => (
                    <li
                      key={idx}
                      className="bg-white space-y-3 p-4 border rounded-lg"
                    >
                      <div className="text-indigo-600 " >{item.icon}</div>
                      <h4 className="text-lg text-gray-800 font-semibold " style={{padding:"2px"}}>
                        {item.title}
                      </h4>
                      <Link to={item.path}>
                      <button className="px-6 py-3.5 text-white bg-black rounded-full duration-150 hover:bg-indigo-500 active:bg-indigo-700 ">
                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                      </button>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
        </div>
        {/* Get Ready for a Stress - Free Experience. */}
        <div></div>
      </div>
    </>
  );
}

export default ExtraBenifits;
