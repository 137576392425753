import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import AddFormModal from "./AddFormModal";
import AboutDescription from "./AboutDescription";
// import { format, subDays } from 'date-fns';

function Career() {
  const [showModal, setShowModal] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  // Helper function to get formatted date n days ago
  const getFormattedDate = (daysAgo) => {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - daysAgo);

    // Formatting the date as "Aug 13 2024"
    const options = { year: "numeric", month: "short", day: "numeric" };
    return pastDate.toLocaleDateString("en-US", options);
  };
  const jobs = [
    {
      title: "UI – Front End Developer",
      desc:
        "Currently, ManTech is seeking a motivated, career and customer-oriented Software Developer to join our team in Fort Meade, MD.",
      date: getFormattedDate(2),
      salary: "98,000 USD",
      type: "Full-time",
      location: "Dublin, CA",
      jobDescription: "Job Description",
      jobDetails: {
        title: "UI – Front End Developer",
        skills: [
          "React",
          "JavaScript",
          "Redux",
          "Git&GitHub",
          "Material UI",
          "Axios",
          "Firebase",
          "HTML5",
          "CSS3",
        ],
        experience: "0-2 years",
        numberOfPositions: 1,
        qualification: "B.Tech/ MCA",
      },
    },
    {
      title: "Back End Developer",
      desc:
        "Help us solve problems and develop great user interface tools for our developers.",
      date: getFormattedDate(1),
      salary: "$105,000 USD",
      type: "Part-time",
      location: "Dublin, CA",
      jobDescription: "Job Description",
      jobDetails: {
        title: "Back End Developer",
        skills: [
          "NodeJs",
          "Express js",
          "MySQL",
          "PostgreSQL",
          "MongoDB",
          "RESTful APIs",
        ],
        experience: "0-2 years",
        numberOfPositions: 1,
        qualification: "B.Tech/ MCA",
      },
    },
    {
      title: "Full-Stack Developer",
      desc:
        "Work as part of a small, multi-functional team based in Dublin, CA. This role involves full-stack development, requiring proficiency in both front-end and back-end technologies.",
      date: getFormattedDate(2),
      salary: "163,273 USD",
      type: "Full-time",
      location: "Dublin, CA",
      jobDescription: "Job Description",
      jobDetails: {
        title: "Full-Stack Developer",
        skills: [
          "React",
          "NodeJs",
          "JavaScript",
          "MongoDB",
          "Express js",
          "MySQL",
          "PostgreSQL",
          "MongoDB",
          "RESTful APIs",
          "HTML",
          "CSS",
        ],
        experience: "1-3 years",
        numberOfPositions: 2,
        qualification: "B.Tech/ MCA",
      },
    },
    {
      title: "Android Developer",
      desc:
        "Join our team in Dublin, CA as an Android Developer. This role focuses on developing robust Android applications, contributing to our mobile development initiatives.",
      date: getFormattedDate(2),
      salary: "163,273 USD",
      type: "Full-time",
      location: "Dublin, CA",
      jobDescription: "Job Description",
      jobDetails: {
        title: "Android Developer",
        skills: ["Java", "Kotlin", "Android SDK", "RESTful APIs"],
        experience: "0-2 years",
        numberOfPositions: 1,
        qualification: "B.Tech/ MCA",
      },
    },
    {
      title: "iOS Developer",
      desc:
        "We're looking for an iOS Developer to join our team in Dublin, CA. This role involves creating innovative iOS applications and collaborating closely with our development team.",
      date: getFormattedDate(3),
      salary: "163,273 USD",
      type: "Full-time",
      location: "Dublin, CA",
      jobDescription: "Job Description",
      jobDetails: {
        title: "iOS Developer",
        skills: ["Swift", "Objective-C", "iOS SDK", "RESTful APIs"],
        experience: "1-4 years",
        numberOfPositions: 1,
        qualification: "B.Tech/ MCA",
      },
    },
    {
      title: "Tester",
      desc:
        "Become a crucial part of our development process in Dublin, CA as a Tester. You'll ensure the quality and functionality of our software products through meticulous testing and feedback.",
      date: getFormattedDate(1),
      salary: "163,273 USD",
      type: "Full-time",
      location: "Dublin, CA",
      jobDescription: "Job Description",
      jobDetails: {
        title: "Tester",
        skills: ["Manual Testing", "Automation Testing", "Bug Tracking Tools"],
        experience: "1-3 years",
        numberOfPositions: 1,
        qualification: "B.Tech/ MCA",
      },
    },
    {
      title: "Support & Maintenance",
      desc:
        "Provide essential support and maintenance for our software solutions in Dublin, CA. This role involves ensuring the stability and efficiency of our systems, supporting our user base.",
      date: getFormattedDate(2),
      salary: "163,273 USD",
      type: "Full-time",
      location: "Dublin, CA",
      jobDescription: "Job Description",
      jobDetails: {
        title: "Support & Maintenance",
        skills: ["Troubleshooting", "Software Upgrades", "Customer Support"],
        experience: "1-3 years",
        numberOfPositions: 1,
        qualification: "B.Tech/ MCA",
      },
    },
  ];

  const selectJob = (job) => {
    setSelectedJob(job);
    toggleDescription();
  };

  return (
    <>
      <section className="py-20 relative bg-blue-600">
        <div className="relative z-10 max-w-screen-xl mx-auto px-4 md:text-center md:px-8">
          <div className="max-w-xl md:mx-auto">
            <p className="text-white text-3xl font-semibold sm:text-4xl">
              Career
            </p>
            <div className="text-blue-100 mt-3">
              <FontAwesomeIcon icon={faHouse} className="mr-2" />
              <Link to="/" className="hover:text-orange-500">
                {" "}
                <span>Home</span>
              </Link>
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                className="ml-2 mr-2"
              />
              <span>Career</span>
            </div>
          </div>
        </div>
        <div
          className="absolute top-0 w-full h-full"
          style={{
            background:
              "linear-gradient(268.24deg, rgba(59, 130, 246, 0.76) 50%, rgba(59, 130, 246, 0.545528) 80.61%, rgba(55, 48, 163, 0) 117.35%)",
          }}
        ></div>
      </section>

      <section className="mt-12 max-w-screen-lg mx-auto px-4 md:px-8">
        <div>
          <h1 className="text-gray-800 text-3xl font-semibold">
            Explore The Jobs
          </h1>
        </div>

        <ul className="mt-12 space-y-6">
          {jobs.map((item, idx) => (
            <li key={idx} className="p-5 bg-white rounded-md shadow-sm">
              <div className="justify-between sm:flex">
                <div className="flex-1">
                  <h3 className="text-xl font-medium text-cyan-600">
                    {item.title}
                  </h3>
                  <p className="text-gray-500 mt-2 pr-2">{item.desc}</p>
                </div>
                <div className="mt-5 space-y-4 text-sm sm:mt-0 sm:space-y-2">
                  <span className="flex items-center text-gray-500">
                    {item.date}
                  </span>
                  <button
                    onClick={() => toggleModal()}
                    className="px-4 py-2 text-white bg-indigo-600 rounded-lg duration-150 hover:bg-indigo-700 active:shadow-lg"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
              <div className="mt-4 items-center space-y-4 text-sm sm:flex sm:space-x-4 sm:space-y-0">
                <div>
                  <button
                    onClick={() => selectJob(item)}
                    className="px-4 py-2 text-indigo-600 bg-indigo-50 rounded-lg duration-150 hover:bg-indigo-100 active:bg-indigo-200"
                  >
                    {item.jobDescription}
                  </button>
                </div>
                <span className="flex items-center text-gray-500">
                  {item.type}
                </span>
                <span className="flex items-center text-gray-500">
                  {item.location}
                </span>
              </div>
            </li>
          ))}
        </ul>
        <AboutDescription
          showDescription={showDescription}
          toggleDescription={toggleDescription}
          jobDetails={selectedJob ? selectedJob.jobDetails : null}
        />
        <AddFormModal showModal={showModal} toggleModal={toggleModal} />
      </section>
    </>
  );
}

export default Career;
