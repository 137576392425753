export const LABELS={
    TheAccountingSOftwareWithEasytoUseTools:"THE ACCOUNTING SOFTWARE WITH EASY TO USE TOOLS.",
    FlexibleAccurateBilling:"Flexible, Accurate Billing",
    BudgetReporting:"Budget Reporting",
    DriverSettlements:"Driver Settlements",
    OneclickInvoice:"One click Invoice",
    Create1099:"Create 1099",
    TracktotalCosts:"Track total Costs",
    CheckPrinting:"Check Printing",
    PaystubPrinting:"Paystub Printing",


    VulcansolsSoftwareEliminatesalltheguessworkfrompayroll_and_invoicingYou_will_know_exactly_what_you_owe_your_employees_and_how_much_your_customers_owe_you_and_everything_will_be_broken_down:"Vulcansols Software eliminates all the guesswork from payroll and invoicing. You will know exactly what you owe your employees and how much your customers owe you, and everything will be broken down. Your dispatches, expenses, and invoices will be completely accurate with minimal effort on your part.",
    Customizable_software_to_meet_the_workflow_needs_of_nearly_any_organization:"Customizable software to meet the workflow needs of nearly any organization. Unlimited user seats, giving you the flexibility to use applications across all departments preventing siloes. Intuitive tools provided in one solution to run your entire business, delivering automation without complexity.",
    Eliminate_errors_and_cut_the_timeittakesto_generate_andpayyourstaff:"Eliminate errors and cut the time it takes to generate and pay your staff, drivers. It is always demoralizing for everyone to argue with a driver about how many miles they drove and how much you owe them. Vulcansols Software will eliminate all the guesswork and will let you know exactly what you owe to your driver and how many miles each driver drove.",
    Vulcansols_Software_includes_accountingmodulesthatallows_you_to_quickly_send_edit_andcreate_invoices_with_one_click:"Vulcansols Software includes accounting modules that allows you to quickly send, edit, and create invoices with one click. You can create an invoice for single or multiple loads with just one click.",
    VulcansolsSoftware_helps_you_to_quickly_compile_all_the_information_required_to_submit_government_forms_like1099_withtheclick_of_a_button:"Vulcansols Software helps you to quickly compile all the information required to submit government forms like 1099 with the click of a button.",
    VulcansolsSoftware_helps_you_to_generate_reports_with_one_click_of_a_button_and_you_track_all_your_expenses_and_earnings_at_one_place:"Vulcansols Software helps you to generate reports with one click of a button and you track all your expenses and earnings at one place.",
    VulcansolsSoftware_allows_you_to_generate_all_your_reports_with_one_click_and_then_you_can_easily_download_and_print_each_and_every_report_for_your_record:"Vulcansols Software allows you to generate all your reports with one click and then you can easily download and print each and every report for your record.",
    You_can_easily_generate_Pay_stubs_and_pay_checks_for_each_driver_by_selecting_one_or_multiple_loads_delivered_by_the_driver:"You can easily generate Pay stubs and pay checks for each driver by selecting one or multiple loads delivered by the driver. After generating pay checks and pay stubs you can simply download and then print it.",
}