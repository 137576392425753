import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
// import TrustedPartner from "./TrustedPartner";
import Stats from "./StatsSection/Stats";
import LoadInformation from "./StatsSection/LoadInformation";
import Weather from "./StatsSection/Weather";
import RealtimeUpdate from "./StatsSection/RealtimeUpdate";
import LocationTracking from "./StatsSection/LocationTracking";
import VehicleManagement from "./StatsSection/VehicleManagement";
import DriverManagement from "./StatsSection/DriverManagement";
import CustomReport from "./StatsSection/CustomReport";
import ExpenseManagement from "./StatsSection/ExpenseManagement";
import { LABELS as _LABELS } from "../../Language";

const LABELS = _LABELS.DispatchPage;

const sectionVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, bounce: 0.4 } },
};

// Custom hook
const useAnimateOnInView = (options = { threshold: 0.1 }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView(options);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return { controls, ref };
};

function Dispatch() {
  // Use custom hook for each section
  const headerSection = useAnimateOnInView();
  const trustedPartner = useAnimateOnInView();
  const stats = useAnimateOnInView();
  const loadInformation = useAnimateOnInView();
  const weather = useAnimateOnInView();
  const realtimeUpdate = useAnimateOnInView();
  const locationTracking = useAnimateOnInView();
  const vehicleManagement = useAnimateOnInView();
  const driverManagement = useAnimateOnInView();
  const customReport = useAnimateOnInView();
  const expenseManagement = useAnimateOnInView();

  return (
    <>
      <motion.div
        className="bg-white pb-20 pt-16"
        ref={headerSection.ref}
        initial="hidden"
        animate={headerSection.controls}
        variants={sectionVariants}
      >
        <h2 className="font-semibold text-4xl sm:text-5xl md:text-6xl lg:text-7xl tracking-tight text-center p-6 md:p-10 bg-white">
          {LABELS.PlanningDispatch}
        </h2>
        <article className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 text-base font-medium text-center leading-6 text-[#878C91]">
          <p>
            {
              LABELS.OurLogisticDispatchSoftware_is_a_comprehensive_solution_designToOptimizeAndAutomateTheDispatchingProcessForLogisticsCompanies
            }
          </p>
        </article>
      </motion.div>
      <section>
        <motion.div
          ref={trustedPartner.ref}
          initial="hidden"
          animate={trustedPartner.controls}
          variants={sectionVariants}
        >
          {/*<TrustedPartner />*/}
        </motion.div>
        <motion.div
          ref={stats.ref}
          initial="hidden"
          animate={stats.controls}
          variants={sectionVariants}
        >
          <Stats />
        </motion.div>
        <motion.div
          ref={loadInformation.ref}
          initial="hidden"
          animate={loadInformation.controls}
          variants={sectionVariants}
        >
          <LoadInformation />
        </motion.div>
        <motion.div
          ref={weather.ref}
          initial="hidden"
          animate={weather.controls}
          variants={sectionVariants}
        >
          <Weather />
        </motion.div>
        <motion.div
          ref={realtimeUpdate.ref}
          initial="hidden"
          animate={realtimeUpdate.controls}
          variants={sectionVariants}
        >
          <RealtimeUpdate />
        </motion.div>
        <motion.div
          ref={locationTracking.ref}
          initial="hidden"
          animate={locationTracking.controls}
          variants={sectionVariants}
        >
          <LocationTracking />
        </motion.div>
        <motion.div
          ref={vehicleManagement.ref}
          initial="hidden"
          animate={vehicleManagement.controls}
          variants={sectionVariants}
        >
          <VehicleManagement />
        </motion.div>
        <motion.div
          ref={driverManagement.ref}
          initial="hidden"
          animate={driverManagement.controls}
          variants={sectionVariants}
        >
          <DriverManagement />
        </motion.div>
        <motion.div
          ref={customReport.ref}
          initial="hidden"
          animate={customReport.controls}
          variants={sectionVariants}
        >
          <CustomReport />
        </motion.div>
        <motion.div
          ref={expenseManagement.ref}
          initial="hidden"
          animate={expenseManagement.controls}
          variants={sectionVariants}
        >
          <ExpenseManagement />
        </motion.div>
      </section>
    </>
  );
}

export default Dispatch;
