 export const DriverSafetyNav = [
    {
        label: "Safety Overview",
        items: [
            {
              
                name: 'Safety Overview'
            },
            {
              
                name: 'Set a Fleet Safety Score Goal'
            },
            {
               
                name: 'Fleet Safety Score Trends and Behavior Insights'
            },
            {
             
                name: 'Risk Factors'
            },
            {
              
                name: 'Safety Event Review in the Safety Inbox'
            },
            {
             
                name: 'Safety Event Details'
            },
        ],
    },
    {
        label: "Safety Reports",
        items: [
            {
               
                name: 'Speeding Reports'
            },
            {
               
                name: 'Event Resolution Report'
            },
            {
            
                name: 'Safety Risk Factor Reports'
            },
            {
               
                name: 'Fleet Benchmarks Report'
            },
        ]
    },
   
  
]

export const DriverWorkFlowNav = [
    {
        label: "Samsara Driver App for Administrators",
        items: [
            {
             
                name: 'Non-ELD Driver Timesheets'
            },
            {
              
                name: 'Driver Scores Dashboard'
            },
            {
              
                name: 'Samsara Driver App Location-Based Vehicle Selection'
            },
            {
               
                name: 'Customizable Driver App Settings'
            },
            {
              
                name: 'Add a Custom Tile to Samsara Driver App Home Screen'
            },
            {
             
                name: 'Find Your Driver Fleet ID'
            },
        ],
    },
    {
        label: "Workflows and Resources for Drivers",
        items: [
            {
               
                name: 'Installation Setup'
            },
            {
           
                name: 'Start a Trip'
            },
            {
                
                name: 'HOS Compliance'
            },
            {
           
                name: 'End a Trip'
            },
            {
              
                name: 'Malfunctions and Diagnostic Events'
            },
            {
          
                name: 'Routes'
            },
            {
     
                name: 'Documents and Messages'
            },
            {
            
                name: 'Coaching and Training in the Driver App'
            },
            {
           
                name: 'Driver Portal'
            },
        ]
    },
   
  
]



 export const footerNavs = [
    {
        label: "Vehicle Gateways",
        items: [
            {
         
                name: 'Vehicle Gateway Configuration'
            },
            {
         
                name: 'Vehicle Gateway Installation'
            },
            {
        
                name: 'Vehicle Gateway Overview'
            },
            {
             
                name: 'Vehicle Gateway Cables'
            },
        ],
    },
    {
        label: "Device Health",
        items: [
            {
              
                name: 'Best Practices for Managing Device Health'
            },
            {
           
                name: 'Sensor Health Report'
            },
            {
            
                name: 'Gateway Health Report'
            },
            {
            
                name: 'Camera Health Report'
            },
        ]
    },
    {
        label: "ID Cards and Tokens",
        items: [
            {
         
                name: 'Driver Assignment Using QR Codes'
            },
            {
            
                name: 'Driver and Passenger Identification Hardware Options'
            },
            {
           
                name: 'Install an ID Card Reader'
            },
            {
              
                name: 'ID Cards for Driver Assignment'
            },
            {
               
                name: 'ID Cards for Student Transit'
            },
            {
             
                name: 'Driver ID Tokens'
            },
        ]
    },
    {
        label: "Company",
        items: [
            {
            
                name: 'Partners'
            },
            {
           
                name: 'Team'
            },
            {
              
                name: 'Careers'
            },
            {
              
                name: ''
            },
        ],
    }
]