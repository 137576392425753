export const LABELS={
    eldIntegration:"Intégration ELD",
    SignUp:"Inscription",

    compliance:"Conformité",
    Agreements:"Accords",


    OurtrustedELDPartner_is_Smartelds:"Notre partenaire ELD de confiance est Vulcansols.",
    DriverTracking:"Suivi des Conducteurs",
    GenerateIFTAReport:"Générer le Rapport IFTA",
    SmartDashcam:"Dashcam Intelligente",
    VehicleDiagnostics:"Diagnostic des Véhicules",
    AssetTracking:"Suivi des Actifs",
    VehicleInspections:"Inspections des Véhicules",

    DriverAgreement:"Accord du Conducteur",
    BrokerageAgreement:"Accord de Courtage",
    ShipperConsigneeAgreement:"Accord Expéditeur / Destinataire",

    Vulcansols_has_integrated_leading_with_most_of_ELD_service_providers_Our_integration_helps_you_connect_to_your_ELD_provider_to_monitor_drivers_and_equipments_in_real_time:"Vulcansols s'est intégré avec la plupart des principaux fournisseurs de services ELD. Notre intégration vous aide à vous connecter à votre fournisseur ELD pour surveiller les conducteurs et les équipements en temps réel.",

    
    VulcansolsSafety_not_only_offers_solutions_to_assist_you_in_maintaining_compliance_but_we_deliver_the_news_you_need_to_know_to_stay_in_the_know:"Vulcansols Safety n'offre pas seulement des solutions pour vous aider à maintenir la conformité, mais nous vous livrons les nouvelles que vous devez savoir pour rester informé. Vulcansols est conçu pour améliorer vos canaux de fret et de distribution en maintenant les livraisons que vous gérez sous votre contrôle. Vulcansols vous aide à garder vos répartiteurs, équipements et charges tous sur la même page. Vulcansols aide à gérer et suivre ces livraisons pour les Transporteurs, Courtiers, Logistiques Tiers (3PL), Fournisseurs et Fabricants. Vulcansols est chargé de fonctionnalités qui vous aident à rivaliser dans le monde de la logistique d'aujourd'hui en offrant un niveau de service supérieur à celui de vos concurrents. Créé par des personnes de l'industrie du camionnage qui assurent la fiabilité, Vulcansols s'améliore continuellement pour répondre aux besoins des professionnels du Transport et du Courtage très demandés d'aujourd'hui. Vulcansols est conçu pour les clients avec des fonctionnalités avancées de suivi et d'immobilisation à distance qui maintiennent le suivi de diverses caractéristiques des véhicules telles que l'allumage, la vitesse au-dessus de la limite, etc.",
    Agreements_play_a_vital_role_in_the_trucking_business:"Les accords jouent un rôle vital dans le secteur du transport routier. Les accords vous aident à éviter tout litige potentiel et créent également de la clarté dans votre entreprise. Le logiciel de Vulcansols vous fournit des accords prêts à l'emploi qui peuvent vous aider dans chaque aspect de votre entreprise. Entrez les détails et l'accord est prêt en un seul clic d'un bouton. Certains des types d'accord sont énumérés ci-dessous:",

}
