export const LABELS={
    carrier:"Carrier",
    eldConnect:"ELD Connect",
    LoadTracking:"Load Tracking",
    LoadRouting:"Load Routing",
    Ifta:"IFTA",
    Rates:"Rates",
    Settlements:"Settlements",
    Receivables:"Receivables",
    WayMessaging:"2 Way Messaging",
    Notification:"Notification",
    LoadConfirmation:"Load Confirmation",
    ProofOfDelivery:"Proof Of Delivery",
    LoadBoard:"Load Board",
    letsTalk:"Let's Talk",

   Letconnectyour_existingELD_to_Vulcansols_to_track_HOS_location_at_realtime: "Let's connect your existing ELD to Vulcansols to track HOS, location at realtime.",
   OneClick_enabled_disable_live_load_tracking:"One click enabled/disable live load tracking.",
   Automaticallycalculates_empty_and_loadedmile_based_on_vehicle_current_location_shipper_and_consignee:"Automatically calculates empty and loaded mile based on vehicle current location, shipper and consignee.",
   Automatically_calculates_states_miles_for_you_IFTA_tax_alongWithdispatchwith_thehelpofVehicleLocationShipperAndConsignee:"Automatically calculates states miles for you IFTA tax along with dispatch, with the help of vehicle location, shipper and consignee.",
   StayCompetitiveWithourSpotMarketRateAnalyzerfor_a_comprehensiveLook_at_industry_trends:"Stay competitive with our spot market rate analyzer for a comprehensive look at industry trends",
   Createdriversettlements_Paychecks_with_one_click:"Create driver settlements (Paychecks) with one click.",
   DecideWhenAndHowYouWantTogetPaid_withoutthe_factoringservice_contracts_or_hiddenfees:"Decide when and how you want to get paid–without the factoring service, contracts, or hidden fees.",
   WayMessaging_with_drivers_carriers_via_mobile_app_or_fall_backtoSMS_chat:"2 Way Messaging with drivers/carriers via mobile app or fall back to SMS chat.",
   WebandPushNotificationforloads_at_shippercheckinout_deliveryetc:"Web and push notification for loads, at shipper, check in/out, delivery, etc.",
   Use_the_dependable_load_tracking_software_giving_you_handsfree_checkcalls:"Use the dependable load tracking software giving you hands-free check calls.",
   MakingProofOfDeliveryseamlessdrivercandofromVulcansolsmobileappalongwithdocumentupload:"Making Proof Of Delivery seamless, driver can do from Vulcansols mobile app along with document upload",
   Withoneclickpostavailabletruckafterdelivery:"With one click, post available truck after delivery.",

   Vulcansols_is_well_designed_TMS_for_carrier_to_ease_and_automate_day_to_day_activitiesStay_on_top_of_your_trucking_business_with_our_smart_artificialintelligencebasedcloudsolution:"Vulcansols is well designed TMS for carrier to ease and automate day to day activities. Stay on top of your trucking business with our smart artificial intelligence based cloud solution.",

}