export const LABELS={
    aboutVulcanslsInc:"Acerca de Vulcansols Inc.",
    At_Vulcansols_Incwe_are_dedicated_to_revolutionizing_fleet_management_solutions_acrossThe_USA:"En Vulcansols Inc., estamos dedicados a revolucionar las soluciones de gestión de flotas en todo Estados Unidos. Especializados en servicios integrales que incluyen gestión de cumplimiento, seguimiento GPS, sólidas soluciones contables y gestión de transportistas, potenciamos a las empresas para optimizar sus operaciones con tecnología de vanguardia y experiencia sin igual.",
    OurCommitment_to_Excellence:"Nuestro Compromiso con la Excelencia",
    With_a_deep_commitment_to_innovation_and_reliability:"Con un profundo compromiso con la innovación y la fiabilidad, Vulcansols Inc. se sitúa a la vanguardia de la industria, ofreciendo soluciones a medida que satisfacen las necesidades únicas de cada cliente. Nuestro enfoque integrado garantiza operaciones de flotas sin problemas, mejora el cumplimiento normativo y proporciona información en tiempo real a través de tecnología avanzada de seguimiento GPS.",
    WhyChooseVulcansolsInc: "¿Por qué elegir Vulcansols Inc.?",
    ComprehensiveFleetManagement:"Gestión Integral de Flotas:",
    From_vehicle_tracking_to_maintenance_scheduling_we_streamline_operations_to_maximize_efficiency_and_reduce_costs:"Desde el seguimiento de vehículos hasta la programación de mantenimiento, optimizamos las operaciones para maximizar la eficiencia y reducir costos.",
    Industryleading_Compliance_Solutions:"Soluciones de Cumplimiento Líderes en la Industria:",
    Stay_aheadofregulatory_requirements_with_our_compliance_management_etc:"Manténgase al tanto de los requisitos normativos con nuestras herramientas de gestión de cumplimiento adaptadas a las demandas específicas del sector del transporte.",
    PrecisionGPSTracking:"Seguimiento GPS Preciso:",
    Monitorfleet_movementsin_realtime_optimize_routes_and_improve_driver_safety_with:"Monitoree los movimientos de la flota en tiempo real, optimice rutas y mejore la seguridad del conductor con nuestros sistemas avanzados de seguimiento GPS.",
    EfficientAccountingIntegration:"Integración Eficiente de Contabilidad:",
    Simplifyfinancial_processes_with_our_integrated_accounting_solutions:"Simplifique los procesos financieros con nuestras soluciones integradas de contabilidad diseñadas para gestionar de manera fluida gastos, facturas y nóminas.",
    SupportforCarriers:"Soporte para Transportistas:",
    Facilitate_seamless_communication_and_operations_between_carriers_and_businesses:"Facilite la comunicación y operaciones sin problemas entre transportistas y empresas, asegurando una experiencia cohesionada en la gestión de la cadena de suministro.",
    Discover_theVulcansolsInc_advantage_and_transform_yourfleet_management_operations_today:"Descubra la ventaja de Vulcansols Inc. y transforme hoy sus operaciones de gestión de flotas. Únase a un creciente número de empresas en todo Estados Unidos que confían en nuestra experiencia para impulsar el éxito, mejorar el cumplimiento normativo y alcanzar la excelencia operativa.",
    seeHowVulcansolsIncsimplifiesfleetmanagement:"Vea cómo Vulcansols Inc. simplifica la gestión de flotas",
    Schedule_a_demo_or_start_free_trail:"Programa una demostración y/o comienza una prueba gratuita de nuestro software de mantenimiento de flotas",
    RequestforDemo:"Solicitud de Demostración",
    Contactus:"Contáctenos",
}
