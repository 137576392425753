export const LABELS={
    Recruiting:"Contratación",


    Stepstofollow:"Pasos a seguir:",
    SendTheapplicationlinktodriverviaSMS:"1. Envía el enlace de la aplicación al conductor a través de SMS.",
    Drivercanopenthelinkinmobileordesktop:"2. El conductor puede abrir el enlace en un móvil o en un escritorio.",
    Drivercanfillapplication:"3. El conductor puede completar la aplicación.",
    Oncedriversubmittheapplicationownerwillreceivenotification:"4. Una vez que el conductor envíe la aplicación, el propietario recibirá una notificación.",
    Ownerwillreviewthedriverapplication:"5. El propietario revisará la aplicación del conductor.",
    Vulcansolsmakes_driver_hiring_veryeasy_Driver_Hiring_application_process_is_easy_both_fordriver_and_companyIt_is_designed_togetallthenecessaryinformationofdriverineasywayThisapplicationisavailableforbothmobileanddesktop:"Vulcansols facilita mucho la contratación de conductores. El proceso de aplicación de contratación de conductores es fácil tanto para el conductor como para la empresa. Está diseñado para obtener toda la información necesaria del conductor de manera sencilla. Esta aplicación está disponible tanto para móviles como para escritorios.",

}
