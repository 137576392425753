export const LABELS={
    SupportAvailable:" Assistance disponible 24h/24 et 7j/7",
    ContactSupport:"Contactez le support",
    For_the_fastest_response_please_submit_a_case_online_through_your_Vulcansols_Inc:"Pour la réponse la plus rapide, veuillez soumettre un cas en ligne via votre Vulcansols Inc. Accédez à la section « Contactez-nous » et remplissez le formulaire pour soumettre un ticket. Notre équipe d'assistance est disponible 24h/24 et 7j/7 pour vous aider à résoudre tout problème que vous pourriez rencontrer.",
    Administration:"Administration",
    hours_a_day_7_days_a_week:"24 heures sur 24, 7 jours sur 7.",
    Quick_Contact_Form:"Formulaire de contact rapide",
    Name:"Nom",
    Email:"E-mail",
    Message:"Message",
    SendMessage:"Envoyer le message",
    Submitting:"Soumission...",
    Message_sent_successfully:"Message envoyé avec succès!",
    Something_went_wrong_Please_try_again:"Quelque chose s'est mal passé. Veuillez réessayer.",



    AnytimeCancellation:" Annulation à tout moment",
    Flexibilityfor_Your_Fleet:"Flexibilité pour votre flotte",
    At_Vulcansols_Inc_we_understand_that_business_needs_can_change_rapidly:"Chez Vulcansols Inc., nous comprenons que les besoins des entreprises peuvent évoluer rapidement. C'est pourquoi nous proposons une politique d'annulation à tout moment pour nos services ELD. Vous avez la liberté d’annuler votre forfait à tout moment sans aucune pénalité.",
    Our_ELD_solutions_are_designed_to_provide_maximum_flexibility_and_ease_of_use:"Nos solutions ELD sont conçues pour offrir une flexibilité et une facilité d'utilisation maximales. Que vous agrandissiez ou réduisiez votre activité, notre politique d'annulation à tout moment garantit que vous gardez le contrôle de vos opérations.",
    Join_Vulcansols_Inc_and_experience_the_freedom_and_flexibility_that_comes_with_our_industryleading_ELD_solutions:"Rejoignez Vulcansols Inc. et découvrez la liberté et la flexibilité offertes par nos solutions ELD de pointe.",



    Available_on_Android_and_iOS:"Disponible sur Android et iOS",
    ELD_Solutions_for_Every_Driver:"Solutions ELD pour chaque conducteur",
    Our_ELD_application_is_designed_to_meet_the_needs_of_drivers_on_both_Android_and_iOS_platforms:"Notre application ELD est conçue pour répondre aux besoins des conducteurs sur les plateformes Android et iOS. Restez conforme aux réglementations FMCSA et gérez vos heures de service sans effort grâce à notre application conviviale.",
    Download_on_the:"Télécharger sur le",
}