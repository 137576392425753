import React, { useEffect } from 'react'
import Complaince from "../../assets/images/Accounting/aggrement.svg";
import { LABELS  as _LABELS} from '../../Language';

const LABELS=_LABELS.Compliance;

function Complian() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <section className="py-14">
        <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
          <div className="relative max-w-4xl mx-auto sm:text-center">
            <div className="relative z-10">
              <h3 className="text-gray-600 text-3xl text-center font-semibold sm:text-4xl ">
              {LABELS.compliance}
              </h3>
              <p className="mt-3 ">
               {LABELS.VulcansolsSafety_not_only_offers_solutions_to_assist_you_in_maintaining_compliance_but_we_deliver_the_news_you_need_to_know_to_stay_in_the_know}
              </p>
            </div>
            <div className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
          </div>
        </div>

      </section>
      <div className="sm:flex items-center max-w-screen  justify-around bg-[#f9f9f9]">
        <div className="">
          <div className="image object-center text-center flex justify-center ">
            <img src={Complaince} alt='brokerage' className='w-96 h-64 '/>
          </div>
        </div>
        <div className="sm:w-1/2 p-5">
          <div className="text">
          <h2 class="my-4 font-bold text-3xl text-center sm:text-4xl ">Agreements</h2>
            <p className="text-gray-700">
            {LABELS.Agreements_play_a_vital_role_in_the_trucking_business}
            </p>

          </div>
          <ul className='list-disc ml-6 p-2'>
              <li>{LABELS.DriverAgreement}</li>
              <li>{LABELS.BrokerageAgreement}</li>
              <li>{LABELS.ShipperConsigneeAgreement}</li>
            </ul>
        </div>
      </div>
    </>
  )
}

export default Complian
