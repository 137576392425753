import React from "react";
import { Link } from "react-router-dom";
import { LABELS as _LABELS } from "../../../Language";

const LABELS = _LABELS.AboutPage;

function About() {
  return (
    <section>
      <div className="max-w-screen-xl mx-auto px-4 md:text-center md:px-8">
        <div className=" py-12">
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold leading-9 text-teal-400 mb-4">
              {LABELS.aboutVulcanslsInc}
            </h2>
            <p className="text-lg leading-7 text-gray-700 mb-8">
              {
                LABELS.At_Vulcansols_Incwe_are_dedicated_to_revolutionizing_fleet_management_solutions_acrossThe_US
              }
            </p>
            <div className="flex flex-col">
              <div className="mb-8">
                <h3 className="text-xl font-bold leading-6 text-gray-900 mb-4">
                  {LABELS.OurCommitment_to_Excellence}
                </h3>
                <p className="text-base leading-6 text-gray-700">
                  {LABELS.With_a_deep_commitment_to_innovation_and_reliability}
                </p>
              </div>
              <div className="mb-8">
                <h3 className="text-xl font-bold leading-6 text-gray-900 mb-4">
                  {LABELS.WhyChooseVulcansolsInc}
                </h3>
                <ul className="list-disc pl-5">
                  <li className="mb-3">
                    <span className="font-bold">
                      {LABELS.ComprehensiveFleetManagement}
                    </span>{" "}
                    {
                      LABELS.From_vehicle_tracking_to_maintenance_scheduling_we_streamline_operations_to_maximize_efficiency_and_reduce_costs
                    }
                  </li>
                  <li className="mb-3">
                    <span className="font-bold">
                      {LABELS.Industryleading_Compliance_Solutions}
                    </span>{" "}
                    {
                      LABELS.Stay_aheadofregulatory_requirements_with_our_compliance_management_etc
                    }
                  </li>
                  <li className="mb-3">
                    <span className="font-bold">
                      {LABELS.PrecisionGPSTracking}
                    </span>{" "}
                    {
                      LABELS.Monitorfleet_movementsin_realtime_optimize_routes_and_improve_driver_safety_with
                    }
                  </li>
                  <li className="mb-3">
                    <span className="font-bold">
                      {LABELS.EfficientAccountingIntegration}
                    </span>
                    {
                      LABELS.Simplifyfinancial_processes_with_our_integrated_accounting_solutions
                    }
                  </li>
                  <li className="mb-3">
                    <span className="font-bold">
                      {LABELS.SupportforCarriers}
                    </span>
                    {
                      LABELS.Facilitate_seamless_communication_and_operations_between_carriers_and_businesses
                    }
                  </li>
                </ul>
              </div>
            </div>
            <p className="text-lg leading-7 text-gray-700">
              {
                LABELS.Discover_theVulcansolsInc_advantage_and_transform_yourfleet_management_operations_today
              }
            </p>
          </div>
        </div>
      </div>
      <section className="relative overflow-hidden py-28 px-4 bg-gray-900 md:px-8 ">
        <div className="w-full h-full rounded-full bg-gradient-to-r from-[#58AEF1] to-pink-500 absolute -top-12 -right-14 blur-2xl opacity-10"></div>
        <div className="max-w-xl mx-auto text-center relative">
          <div className="py-2">
            <h3 className="text-xl text-gray-200 font-semibold md:text-xl">
              {LABELS.seeHowVulcansolsIncsimplifiesfleetmanagement}
            </h3>
            <p className="text-gray-300 leading-relaxed mt-3">
              {LABELS.Schedule_a_demo_or_start_free_trail}
            </p>
          </div>
          <div className=" mt-5 items-center justify-center gap-3 sm:flex">
            <Link
              to="/demo"
              className="block w-full mt-2 py-2.5 px-8 text-gray-700 bg-white rounded-md duration-150 hover:bg-gray-100 sm:w-auto"
            >
              {LABELS.RequestforDemo}
            </Link>
            <Link
              to="/contact"
              className="block w-full mt-2 py-2.5 px-8 text-gray-300 bg-gray-700 rounded-md duration-150 hover:bg-gray-800 sm:w-auto"
            >
              {LABELS.Contactus}
            </Link>
          </div>
        </div>
      </section>
    </section>
  );
}

export default About;
