import React, { useEffect } from "react";
import FeatureSection from "./FeatureSection/FeatureSection";
import { LABELS as _LABELS } from "../../Language";

const LABELS = _LABELS.FleetTracking;

function FleetTracking() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <div className="flex flex-col items-center px-4 ">
        <h1 className="font-semibold py-8 text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center">
          {LABELS.GPSFleetTracking}
        </h1>
        <p className="text-base leading-6 font-medium w-full md:w-7/12 text-[#878C91] text-center">
          {
            LABELS.Vulcansols_is_continually_improving_to_meet_the_needs_of_todays_high_demand_for_trucking_and_brokerage_professionals_Vulcansols_is_designed_to_provide_advanced_features_of_Tracking_and_Remote_Immobilization_toourCustomers_that_keeps_theTrack_of_various_features_of_vehicles_like_ignitionSpeed_over_limitEtc
          }
        </p>
        <FeatureSection />
      </div>
    </>
  );
}

export default FleetTracking;
