export const LABELS={
    SupportAvailable:" 24/7 Support Available",
    ContactSupport:"Contact Support",
    For_the_fastest_response_please_submit_a_case_online_through_your_Vulcansols_Inc:'For the fastest response, please submit a case online through your Vulcansols Inc. Navigate to the "Contact Us" section and fill out the form to submit a ticket. Our support team is available 24/7 to assist you with any issues you may encounter.',
    Administration:"Administration",
    hours_a_day_7_days_a_week:"24 hours a day, 7 days a week.",
    Quick_Contact_Form:"Quick Contact Form",
    Name:"Name",
    Email:"Email",
    Message:"Message",
    SendMessage:"Send Message",
    Submitting:"Submitting...",
    Message_sent_successfully:"Message sent successfully!",
    Something_went_wrong_Please_try_again:"Something went wrong. Please try again.",



    AnytimeCancellation:" Anytime Cancellation",
    Flexibilityfor_Your_Fleet:"Flexibility for Your Fleet",
    At_Vulcansols_Inc_we_understand_that_business_needs_can_change_rapidly:"At Vulcansols Inc., we understand that business needs can change rapidly. That’s why we offer an anytime cancellation policy for our ELD services. You have the freedom to cancel your plan at any time without any penalties.",
    Our_ELD_solutions_are_designed_to_provide_maximum_flexibility_and_ease_of_use:"Our ELD solutions are designed to provide maximum flexibility and ease of use. Whether you are scaling up or scaling down, our anytime cancellation policy ensures that you remain in control of your operations.",
    Join_Vulcansols_Inc_and_experience_the_freedom_and_flexibility_that_comes_with_our_industryleading_ELD_solutions:"Join Vulcansols Inc. and experience the freedom and flexibility that comes with our industry-leading ELD solutions.",



    Available_on_Android_and_iOS:"Available on Android and iOS",
    ELD_Solutions_for_Every_Driver:"ELD Solutions for Every Driver",
    Our_ELD_application_is_designed_to_meet_the_needs_of_drivers_on_both_Android_and_iOS_platforms:"Our ELD application is designed to meet the needs of drivers on both Android and iOS platforms. Stay compliant with FMCSA regulations and manage your hours of service effortlessly with our user-friendly app.",
    Download_on_the:"Download on the",
}