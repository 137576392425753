import React from 'react';
import GpsTrackingImage from "../../../assets/images/GpsTracking.svg";
import RouteMapImage from "../../../assets/images/Routemap.svg";
import NotificationImage from "../../../assets/images/NotificationAlert.svg";
import GeoFencesImage from "../../../assets/images/Geo_fences.svg"
import DriverImage from "../../../assets/images/DriverBehaviour.svg";
import ReportAnalyticImage from  "../../../assets/images/Report.svg";
import { LABELS  as _LABELS} from '../../../Language';

const LABELS=_LABELS.FleetTracking;

function FeatureSection() {
    const features = [
        {
            icon:
                <img src={GpsTrackingImage} alt='gpstrack'/>,
            title: LABELS.RealTimeGPSTracking,
            desc: LABELS.TrackinrealtimeEverything_isAccessed_over_the_web_and_mobile_APP
        },
        {
            icon:
            <img src={RouteMapImage} alt='RouteMap'/>,
            title: LABELS.DailyRouteMaps,
            desc: LABELS.ViewalltheJourneysAndRoutesTakenon_a_day
        },
        {
            icon:
            <img src={NotificationImage} alt='Notification'/>,
            title: LABELS.LiveAlertNotifications,
            desc: LABELS.BealertedToJustthe_eventsyouConsiderImportant
        },
        {
            icon:
            <img src={GeoFencesImage} alt='geoFencesImage'/>,
            title: LABELS.CreateGeofences,
            desc: LABELS.CompleteControlandprotectyourassetsAndBusiness,
        },
        {
            icon:
            <img src={DriverImage} alt='driverimage'/>,
            title: LABELS.DriverBehaviour,
            desc: LABELS.SeeSpeedingHarshAccelerationBrakingSharp_turn_unnecessary_idling_etc,
        },
        {
            icon:
            <img src={ReportAnalyticImage} alt='reportAnalytic'/>,
            title: LABELS.ReportsAnalytics,
            desc: LABELS.DataRichreportsGenerate_analytics_to_help_saveyou_time_and_money,
        },
    ]

    return (
        <section className="py-14">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
               
                <div className="relative mt-12">
                    <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {
                            features.map((item, idx) => (
                                <li key={idx} className="bg-white space-y-3 p-4 border rounded-2xl">
                                    <div className="text-indigo-600 pb-3">
                                        {item.icon}
                                    </div>
                                    <h4 className="text-2xl font-semibold leading-10 text-[#010205]">
                                        {item.title}
                                    </h4>
                                    <p className='font-medium text-base leading-5'>
                                        {item.desc}
                                    </p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default FeatureSection