export const LABELS={
  eldIntegration:"Integración de ELD",
  SignUp:"Registrarse",

  compliance:"Cumplimiento",
  Agreements:"Acuerdos",


  OurtrustedELDPartner_is_Smartelds:"Nuestro socio ELD de confianza es Vulcansols.",
  DriverTracking:"Seguimiento de Conductores",
  GenerateIFTAReport:"Generar Informe IFTA",
  SmartDashcam:"Cámara de Tablero Inteligente",
  VehicleDiagnostics:"Diagnóstico de Vehículos",
  AssetTracking:"Seguimiento de Activos",
  VehicleInspections:"Inspecciones de Vehículos",

  DriverAgreement:"Acuerdo del Conductor",
  BrokerageAgreement:"Acuerdo de Corretaje",
  ShipperConsigneeAgreement:"Acuerdo de Remitente/Consignatario",

  Vulcansols_has_integrated_leading_with_most_of_ELD_service_providers_Our_integration_helps_you_connect_to_your_ELD_provider_to_monitor_drivers_and_equipments_in_real_time:"Vulcansols se ha integrado con la mayoría de los principales proveedores de servicios ELD. Nuestra integración te ayuda a conectarte con tu proveedor ELD para monitorear conductores y equipos en tiempo real.",

  
  VulcansolsSafety_not_only_offers_solutions_to_assist_you_in_maintaining_compliance_but_we_deliver_the_news_you_need_to_know_to_stay_in_the_know:"Vulcansols Safety no solo ofrece soluciones para ayudarte a mantener el cumplimiento, sino que también te brindamos las noticias que necesitas saber para estar al tanto. Vulcansols está diseñado para mejorar tus canales de flete y distribución manteniendo bajo tu control las entregas que gestionas. Vulcansols te ayuda a mantener a tus despachadores, equipos y cargas todos en la misma página. Vulcansols ayuda a gestionar y rastrear esas entregas para Transportistas, Corredores, Logística de Terceros (3PL), Proveedores y Fabricantes. Vulcansols está cargado con características que te ayudan a competir en el mundo logístico de hoy ofreciendo un nivel de servicio más alto que tus competidores. Creado por personas de la industria del transporte que aseguran confiabilidad, Vulcansols se está mejorando continuamente para satisfacer las necesidades de los profesionales de Transporte y Corretaje de alta demanda de hoy en día. Vulcansols está diseñado para clientes con características avanzadas de Rastreo e Inmovilización Remota que mantienen el seguimiento de varias características de los vehículos como la ignición, velocidad sobre límite, etc.",
  Agreements_play_a_vital_role_in_the_trucking_business:"Los acuerdos juegan un papel vital en el negocio del transporte por carretera. Los acuerdos te ayudan a evitar cualquier disputa potencial y también crean claridad en tu negocio. El software de Vulcansols te proporciona acuerdos listos para usar que pueden ayudarte en cada aspecto de tu negocio. Ingresa los detalles y el acuerdo está listo con solo un clic de un botón. Algunos de los tipos de acuerdo se enumeran a continuación:",

}
