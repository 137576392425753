export const LABELS={
    ProductsDriverSafety: "Products | Driver Safety",
    Proactively_manage_safety:"Proactively manage safety",
    Getstarted:"Get started",
    ReliableAI:"Reliable AI",
    Collisiondetection:"Collision detection",
    PrivacyMode:"Privacy Mode",
    See_how_it_works:"See how it works ",


    FrequentlyAskedQuestions:"Frequently Asked Questions",
    Whyisdriversafetyimportantwhenusing_anELD:"Why is driver safety important when using an ELD?",
    How_do_ELDs_improve_compliance_with_HOS_regulations:"How do ELDs improve compliance with HOS regulations?",
    How_can_fleet_safety_beimproved:"How can fleet safety be improved?",
    Whatare_the_benefits_of_fleet_safetyprogram:"What are the benefits of fleet safety program?",
    Howdoes_a_dashcam_enhance_vehicle_security:"How does a dashcam enhance vehicle security?",
    How_does_ELD_integration_benefit_fleet_operations:"How does ELD integration benefit fleet operations?",
    FreshFindings:"Fresh Findings: Surveying 1,000 Physical Operations Leaders. 59% Identify Escalating Costs as Top Concern. Can AI be the Saving Grace?",

    All_in_one_security_solution_for_fleet_protection_and_profits:"All in one security solution for fleet protection and profits",
    EfficientlyOverseeFleetSafetyAchieveTangibleResults_and_Gain_Valuable_Time_Back_in_Your_Schedule:"Efficiently Oversee Fleet Safety, Achieve Tangible Results, and Gain Valuable Time Back in Your Schedule. Enhance Accident Prevention and Support Driver Accountability with Cutting-Edge AI Dash Cam Technology.",
    DriveTowardsEnhancedFleetSafetyandReducedRisk_withVulcansols:"Drive Towards Enhanced Fleet Safety and Reduced Risk with Vulcansols' Cutting-Edge AI Platform. Safeguard Your Operations with Real-Time Driver Coaching, Advanced Accident Detection, Instant Exoneration Evidence, and Rigorous Privacy Measures.",


    Answer1:"Driver safety is paramount as ELDs help prevent driver fatigue by ensuring compliance with HOS regulations, reducing the risk of accidents caused by overworked drivers.",
    Answer2:"ELDs automatically track driving hours, rest periods, and breaks, ensuring drivers adhere to HOS regulations and reducing the likelihood of violations.",
    Answer3:"Fleet safety can be improved by integrating advanced safety technologies, conducting regular vehicle maintenance, providing comprehensive driver training, monitoring driver behavior, and enforcing clear safety protocols.",
    Answer4:"A well-designed fleet safety program brings numerous benefits, including reduced accidents, lower associated costs, improved driver morale, enhanced public safety, and compliance with regulatory standards. Additionally, it can lead to lower insurance premiums, increased operational efficiency, and a positive impact on a company’s reputation.",
    Answer5:"In addition to recording while driving, dashcams with parking mode can capture incidents like vandalism or theft attempts when the vehicle is parked",
    Answer6:"Integration allows for streamlined data sharing, improved route planning, enhanced driver management, and better decision-making based on comprehensive data analytics.",

}