import React from 'react'
import Accordion from './AccordionItem'

function KnowledgeBase() {
  return (
  <>
  <div>
    <h1 className='text-center font-bold text-3xl p-10'>Product Documentation</h1>
   <Accordion/>
  </div>
  </>
  )
}

export default KnowledgeBase