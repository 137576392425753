export const LABELS={

    ELDCompliance:"Conformité ELD",
    GPSTracking:"Suivi GPS",
    SmartDashcam:"Caméra de Bord Intelligente",
    TrailerTracking:"Suivi de Remorque",
    ComplianceBoard:"Tableau de Conformité",
    DispatchBoard:"Tableau de Dispatch",
    VehicleMaintenance:"Entretien des Véhicules",
    ReeferTempratureMonitoring:"Surveillance de la Température du Réfrigérateur",
    CustomTruckingSolution:"Solution de Transport Personnalisée",


    next:"Suivant",
    back:"Retour",
    submit:"Soumettre",

    fullName:"Nom Complet",
    phone:"Téléphone",
    EmailAddress:"Adresse Email",
    CompanyName:"Nom de l'Entreprise",

    
    Features_or_productsyouinterested_in:"Fonctionnalités ou produits qui vous intéressent",
    NumberofVehiClesyouOperate:"Nombre de véhicules que vous exploitez",
}
