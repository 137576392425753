import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import customersatifiedimage from "../../../assets/icons/customersatisfied.svg";
import dashboardReport from "../../../assets/images/DashboardReport.svg";
import profileView from "../../../assets/images/profileview.svg"
import pathimage from "../../../assets/images/Path 2.svg"
import { LABELS as _LABELS} from '../../../Language';

const LABELS=_LABELS.DispatchPage;

function CustomReport() {
    
    const [showMore, setShowMore] = useState(false);

    const handleToggle = () => {
        setShowMore(!showMore);
    };
  return (
    <section className="py-14">
    <div className="max-w-screen-xl mx-auto px-4 text-gray-600 gap-x-12 items-start justify-between lg:flex md:px-8">
    <div class='flex flex-col md:flex-row gap-3 bg-[#ECECEC] rounded-2xl p-2'>
    <div class='flex-1'>
        <div class='h-48 md:h-24 m-3 bg-[#ffff] rounded-xl flex justify-center items-center gap-2'>
            <div>
                <img src={customersatifiedimage}  alt='customreport'/>
            </div>
            <div class='w-48 md:w-46 h-16'>
                <p class='font-medium mt-3 text-center'>98%</p>
                <p class='font-normal text-[#98A2B3] text-center'>{LABELS.CustomerSatisfaction}</p>
            </div>
        </div>
        <div class='bg-[#ffff] rounded-xl m-3 p-4'>
            <p class='font-medium p-2 text-center'>{LABELS.DashboardReport}</p>
            <img src={dashboardReport} class='p-2 mx-auto' alt='dashboardReport'/>
        </div>
    </div>
    <div class='flex-1'>
        <div class='bg-[#ffff] rounded-lg'>
            <p class='font-medium text-lg leading-10 ml-3 text-center'>67m+</p>
            <p class='font-normal text-[#98A2B3] leading-7 ml-3 text-center'>{LABELS.Profileviews}</p>
            <img src={profileView} class='mt-3 p-3 mx-auto' alt='prfilereview' />
        </div>
        <div class='bg-[#ffff] rounded-xl mt-3'>
            <p class='font-medium text-lg p-2 text-center'>10,254</p>
            <p class='font-normal text-[#98A2B3] p-2 text-center'>{LABELS.ProjectDonethisyear}</p>
            <img src={pathimage} class='p-2 mx-auto' alt='pathimage' />
        </div>
    </div>
</div>

        <div className="mt-6 gap-12 sm:mt-0 md:flex lg:block">
            <div className="max-w-2xl">
                <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                {LABELS.CustomReport}
                </h3>
                <p className="mt-3 max-w-xl">
                 {LABELS.Our_AI_PoweredDispatchSolutionprovidesthefeatureofcustomReports}
                </p>
                <ul className='mt-6 list-none flex-col space-y-6'>
                            <li className='flex items-center'>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                {LABELS.profitloss}
                            </li>
                            <li className='flex items-center'>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                {LABELS.stateMilage}
                            </li>
                            {showMore && (
                                <>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                        {LABELS.driverpayment}
                                    </li>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                        {LABELS.TruckMilage}
                                    </li>
                                    
                                </>
                            )}
                        </ul>
                        <button
                            onClick={handleToggle}
                            className="mt-6 px-6 py-3.5 text-white bg-black rounded-full duration-150 hover:bg-indigo-500 active:bg-indigo-700"
                        >
                            {showMore ? LABELS.showLess: LABELS.seeMore}
                            <FontAwesomeIcon icon={showMore ? faArrowDown : faArrowRight} className="ml-2" />
                        </button>
            </div>

        </div>
    </div>
</section>
  )
}

export default CustomReport
