import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLaughBeam } from '@fortawesome/free-solid-svg-icons';
import MacbookPro from "../../../assets/images/Macbookpro.svg";
import Ellipse from "../../../assets/images/Ellipse 3678.svg";
import IconwithCircle from "../../../assets/icons/Iconwithcircle.svg";
import AccesCircleIcon from "../../../assets/icons/Accescircle.svg";
import SoftwareCircleIcon from "../../../assets/icons/softwareIconCircle.svg";
import "./Style.css"
import { LABELS  as _LABELS} from '../../../Language';

const LABELS=_LABELS.HomePage;


function NewBenifits() {
  return (
    <div className='bg-black flex flex-col md:flex-row justify-center gap-4 items-center p-4 md:p-8 rounded-3xl ml-5 mr-5 hidden: hide-on-specific-size mt-3'>
      <div className='p-4 md:p-8'>
        <div className="max-w-xl">
          <h3 className="text-3xl font-semibold sm:text-4xl text-white mt-9">
            {LABELS.OurEngineeringTeam}
          </h3>
          <p className="text-gray-600 mt-3 text-white">
           {LABELS.AlogisticsDomainExpertEngineeringTeamis_a_Specialized_group_of_professionals_focused_on_designing_developing_and_implementing_solutions_within_the_Logistics_and_supplyChainManagementDomain}
          </p>
        </div>
        <button className="mt-6 px-6 py-3.5 text-white bg-gray-600 rounded-full duration-150 hover:bg-indigo-500 active:bg-indigo-700">
          {LABELS.learnMore}
          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </button>
        <div className="mt-6">
          <img src={MacbookPro} alt="Macbook Pro" />
        </div>
      </div>
      <div className='flex justify-center items-center mt-6 md:mt-0 ' style={{ position: 'relative' }}>
    <img src={Ellipse} className='' alt='ellipse'/>
    <div className='text-white flex  w-96 h-20 border border-gray-600 rounded-xl' style={{ position: 'absolute', top: '13%', left: '60%', transform: 'translate(-50%, -50%)' }}>
      <img src={IconwithCircle} alt='iconwithCircle'/>
      <h1 className='w-72 p-2'>{LABELS.AvailableForMobile}</h1>
    </div>
    <div className='text-white flex justify-center items-center w-96 h-20  border border-gray-600 rounded-xl ' style={{ position: 'absolute', top: '35%', left: '40%', transform: 'translate(-50%, -50%)' }}>
      <img src={IconwithCircle} alt='iconwithcircle'/>
      <h1 className='w-80 '>{LABELS.MultipleUserWithDifferentRoleandAcces}</h1>
    </div>
    <div className='text-white flex justify-center items-center w-96 h-20 border border-gray-600 rounded-xl' style={{ position: 'absolute', top: '58%', left: '30%', transform: 'translate(-50%, -50%)' }}>
      <img src={SoftwareCircleIcon} alt='softwareRequire'/>
      <h1 className='w-72 p-2'>{LABELS.NoAdditionalSoftwareRequire}</h1>
    </div>
    <div className='text-white flex justify-center items-center w-70 h-20 border border-gray-600 rounded-xl' style={{ position: 'absolute', top: '80%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <img src={AccesCircleIcon} alt='icon'/>
      <h1 className='w-72 p-2'>{LABELS.AccesFromAnyWhere}</h1>
    </div>
</div>

    </div>
  );
}

export default NewBenifits;
