import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Content from './Content';
import Vehicle from './Vehicle';
import AddForm from './AddForm';
import axios from 'axios'; 
import { LABELS as _LABELS } from '../../Language';

const LABELS = _LABELS.Demo;

function Stepper() {
    const [Steps, SetStep] = useState({
        stepsCount: [1, 2, 3],
        currentStep: 1
    });
    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        company: '',
        selectedItems: [],
        selectedVehicles: []
    });
    const [selectedItems, setSelectedItems] = useState([]);
    const [completedSteps, setCompletedSteps] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState([]);

    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            selectedItems: selectedItems,
            selectedVehicles: selectedVehicles
        }));
    }, [selectedItems, selectedVehicles]);

    const toggleSelectItem = (title) => {
        setSelectedItems(prevSelectedItems => {
            const newSelectedItems = prevSelectedItems.includes(title) 
                ? prevSelectedItems.filter(item => item !== title) 
                : [...prevSelectedItems, title];
            setFormData(prev => ({ ...prev, selectedItems: newSelectedItems }));
            return newSelectedItems;
        });
    };

    const toggleSelectVehicle = (value) => {
        const newSelectedVehicles = [value]; // Only allow one selection
        setSelectedVehicles(newSelectedVehicles);
        if(selectedVehicles.includes(value)){
            setSelectedVehicles(selectedVehicles.filter(option => option !== value));
        }else{
            setSelectedVehicles(newSelectedVehicles);
        }
        setFormData(prev => ({ ...prev, selectedVehicles: newSelectedVehicles }));
    };
   
    const handleNext = () => {
        if (Steps.currentStep === 1 && selectedItems.length === 0) {
            toast.error('🦄 Please select at least one item to proceed!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        if (Steps.currentStep === 2 && selectedVehicles.length === 0) {
            toast.error('🦄 Please select a vehicle to proceed!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        if (Steps.currentStep < Steps.stepsCount.length) {
            SetStep(prev => ({ ...prev, currentStep: prev.currentStep + 1 }));
            setCompletedSteps([...completedSteps, Steps.currentStep]);
        }
    };

    const handleBack = () => {
        if (Steps.currentStep > 1) {
            SetStep(prev => ({ ...prev, currentStep: prev.currentStep - 1 }));
            setCompletedSteps(completedSteps.filter(step => step !== Steps.currentStep - 1));
        }
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 1:
                return <Content selectedItems={selectedItems} toggleSelectItem={toggleSelectItem} />;
            case 2:
                return <Vehicle selectedVehicles={selectedVehicles} toggleSelectVehicle={toggleSelectVehicle} />;
            case 3:
                return <AddForm formData={formData} setFormData={setFormData} />;
            default:
                return null;
        }
    };

    const handleSubmit = async () => {
        if (!formData.name || !formData.number || !formData.email) {
            toast.error('🦄 Please fill out all required fields before submitting!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }

        try {
            const response = await axios.post('https://osritapi.com/api/issues/contactus', formData);

            // console.log("Form submitted successfully:", response.data);
            setFormData({
                name: '',
                number: '',
                email: '',
                company: '',
                selectedItems: [],
                selectedVehicles: []
            });
            // console.log("Response data:", response.data);
            setCompletedSteps([]);
            setSelectedItems([]);
            setSelectedVehicles([]);
            SetStep({ stepsCount: [1, 2, 3], currentStep: 1 });

            toast.success('Form submitted successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('🦄 Error submitting form. Please try again later!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        }
    };

    return (
        <>
            <div className="max-w-lg mx-auto px-4 sm:px-0 text-center mt-8">
                <ul aria-label="Steps" className="flex items-center mb-4">
                    {Steps.stepsCount.map((item, idx) => (
                        <li key={idx} aria-current={Steps.currentStep === idx + 1 ? "step" : false} className="flex-1 last:flex-none flex items-center">
                            <div className={`w-8 h-8 rounded-full border-2 flex-none flex items-center justify-center ${completedSteps.includes(idx + 1) ? "bg-green-600 border-green-600" : Steps.currentStep === idx + 1 ? "border-indigo-600" : ""}`}>
                                {completedSteps.includes(idx + 1) ? (
                                    <svg fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                    </svg>
                                ) : (
                                    <span className="text-black text-xs">{(idx + 1).toString().padStart(2, '0')}</span>
                                )}
                            </div>
                            <hr className={`w-full border ${idx + 1 === Steps.stepsCount.length ? "hidden" : Steps.currentStep > idx + 1 ? "border-indigo-600" : ""}`} />
                        </li>
                    ))}
                </ul>
            </div>

            <section>
                {renderStepContent(Steps.currentStep)}
            </section>

            <div className="flex justify-center space-x-4 mt-4">
                <button onClick={handleBack} className="px-4 py-2 text-white bg-black rounded-full disabled:opacity-50" disabled={Steps.currentStep === 1}>
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    {LABELS.back}
                </button>
                {Steps.currentStep < Steps.stepsCount.length ? (
                    <button onClick={handleNext} className="px-6 py-3.5 text-white bg-black rounded-full duration-150 hover:bg-indigo-500 active:bg-indigo-700">
                      {LABELS.next}
                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                    </button>
                ) : (
                    <button onClick={handleSubmit} className="px-4 py-2 bg-green-600 text-white rounded-md">
                        {LABELS.submit}
                    </button>
                )}
            </div>
            <ToastContainer />
        </>
    );
}

export default Stepper;
