import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import WeatherImage from "../../../assets/images/weatherReport.webp";
import { LABELS as _LABELS } from '../../../Language';

const LABELS=_LABELS.DispatchPage;

function Weather() {
    const [showMore, setShowMore] = useState(false);

    const handleToggle = () => {
        setShowMore(!showMore);
    };
  return (
    <section className="py-14"
        >
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 gap-x-12 items-start justify-between lg:flex md:px-8">
                <div className="mt-6 gap-12 sm:mt-0 md:flex lg:block">
                    <div className="max-w-2xl">
                        <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                        {LABELS.WeatherReports}
                        </h3>
                        <p className="mt-3 max-w-xl">
                        {LABELS.Weather_reports_are_very_important_in_trucking_ItIsNotPossible_forhumans_to_controlWeather_and_to_overcome_that_VulcansolsSoftwarewillprovideyou_10_daysweatherforecasting_soyou_can_plan_your_loads_accordingly}
                        </p>
                        <ul className='mt-6 list-none flex-col space-y-6'>
                            <li className='flex items-center'>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                {LABELS.DriverTracking}
                            </li>
                            <li className='flex items-center'>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                {LABELS.driversafetyTips}
                            </li>
                            {showMore && (
                                <>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                        {LABELS.weatherImpactAnalysis}
                                    </li>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                        {LABELS.weatherRelatedRoutesOptimize}
                                    </li>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                        {LABELS.AssetTracking}
                                    </li>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                        {LABELS.VehicleInspections}
                                    </li>
                                </>
                            )}
                        </ul>
                        <button
                            onClick={handleToggle}
                            className="mt-6 px-6 py-3.5 text-white bg-black rounded-full duration-150 hover:bg-indigo-500 active:bg-indigo-700"
                        >
                            {showMore ? "Show Less" : "See More"}
                            <FontAwesomeIcon icon={showMore ? faArrowDown : faArrowRight} className="ml-2" />
                        </button>
                    </div>
                </div>
                <div className="lg:block lg:max-w-xl lg: mt-5">
                    <img src={WeatherImage} className="rounded-lg" alt="Weatherimage" style={{height:"400px",width:"600px", borderRadius:"10px"}} />
                </div>
            </div>
        </section>
  )
}

export default Weather