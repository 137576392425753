export const LABELS={
    aboutVulcanslsInc:"About Vulcansols Inc.",
    At_Vulcansols_Incwe_are_dedicated_to_revolutionizing_fleet_management_solutions_acrossThe_USA:"At Vulcansols Inc., we are dedicated to revolutionizing fleet management solutions across the USA. Specializing in comprehensive services including compliance management, GPS tracking, robust accounting solutions, and carrier management, we empower businesses to optimize their operations with cutting-edge technology and unparalleled expertise.",
    OurCommitment_to_Excellence:"Our Commitment to Excellence",
    With_a_deep_commitment_to_innovation_and_reliability:"With a deep commitment to innovation and reliability, Vulcansols Inc. stands at the forefront of the industry, delivering tailored solutions that meet the unique needs of each client. Our integrated approach ensures seamless fleet operations, enhances regulatory compliance, and provides real-time insights through advanced GPS tracking technology.",
    WhyChooseVulcansolsInc: "Why Choose Vulcansols Inc.?",
    ComprehensiveFleetManagement:"Comprehensive Fleet Management:",
    From_vehicle_tracking_to_maintenance_scheduling_we_streamline_operations_to_maximize_efficiency_and_reduce_costs:"From vehicle tracking to maintenance scheduling, we streamline operations to maximize efficiency and reduce costs.",
    Industryleading_Compliance_Solutions:"Industry-leading Compliance Solutions:",
    Stay_aheadofregulatory_requirements_with_our_compliance_management_etc:"Stay ahead of regulatory requirements with our compliance management tools tailored to the specific demands of the transportation sector.",
    PrecisionGPSTracking:"Precision GPS Tracking:",
    Monitorfleet_movementsin_realtime_optimize_routes_and_improve_driver_safety_with:"Monitor fleet movements in real-time, optimize routes, and improve driver safety with our state-of-the-art GPS tracking systems.",
    EfficientAccountingIntegration:"Efficient Accounting Integration:",
    Simplifyfinancial_processes_with_our_integrated_accounting_solutions:"Simplify financial processes with our integrated accounting solutions designed to seamlessly manage expenses, invoices, and payroll.",
    SupportforCarriers:"Support for Carriers:",
    Facilitate_seamless_communication_and_operations_between_carriers_and_businesses:"Facilitate seamless communication and operations between carriers and businesses, ensuring a cohesive supply chain management experience.",
    Discover_theVulcansolsInc_advantage_and_transform_yourfleet_management_operations_today:"Discover the Vulcansols Inc. advantage and transform your fleet management operations today. Join a growing number of businesses across the USA who rely on our expertise to drive success, enhance compliance, and achieve operational excellence.",
    seeHowVulcansolsIncsimplifiesfleetmanagement:"See how Vulcansols Inc, simplifies fleet management",
    Schedule_a_demo_or_start_free_trail:"Schedule a demo and/or start a free trial of our Fleet Maintenance Software",
    RequestforDemo:"Request for Demo",
    Contactus:"Contact us",
}