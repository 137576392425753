import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import truckWithMobilePhone from "../../../assets/images/truckwithmobile.png"
import peopleIcon from "../../../assets/icons/PeopleIcon.svg"
import ProgressBar from "../../../assets/icons/Progress Indicatior.svg"
import UserAvtar from "../../../assets/icons/userAvtar.svg";
import Graphimage from "../../../assets/icons/graph.svg";
import frame320image from "../../../assets/icons/frame320.svg";
import UserLogo from "../../../assets/icons/userlogo.svg";
import cycleChart from "../../../assets/icons/cycleChart.svg"
import { LABELS as _LABELS } from '../../../Language';

const LABELS = _LABELS.DispatchPage;

function VehicleManagement() {
    const [showMore, setShowMore] = useState(false);

    const handleToggle = () => {
        setShowMore(!showMore);
    };
    return (
        <section className="py-14">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 gap-x-12 items-start justify-between lg:flex md:px-8 ">
                <div className="p-2 flex flex-col md:flex-row gap-4 bg-[#ECECEC] rounded-2xl">
                    <div className='w-full md:w-64 h-auto flex flex-col justify-between ml-3 mt-3 mb-2'>
                    <img src={truckWithMobilePhone} className='hidden md:block object-cover h-auto  ' alt="Truck with Mobile Phone" />

                        <div className='bg-[#ffff] rounded-xl hidden md:block'>
                            <div className='h-20 flex p-2 gap-3'>
                                <div>
                                    <img src={peopleIcon} alt="People Icon" />
                                </div>
                                <div className='w-32 h-16  '>
                                    <p>{LABELS.TotalOverview}</p>
                                    <p>$12000</p>
                                </div>
                            </div>
                            <div className='p-2'>
                                <p>{LABELS.SinceLastMonth}</p>
                                <img src={ProgressBar} alt="Progress Bar" />
                            </div>
                        </div>
                    </div>
                    <div className='w-full md:w-80 h-auto'>
                        <div className='w-full h-auto bg-[#ffff] rounded-xl mt-3'>
                            <div className='flex justify-between'>
                                <div className='w-20 h-10 border-l-2 border-black ml-2 mt-2'>
                                    <p className='p-1 -mt-2'>{LABELS.Tracking}</p>
                                    <p className='p-1 -mt-2 font-medium text-xs'>124</p>
                                </div>
                                <img src={UserAvtar} className='mr-3' alt="User Avatar" />
                            </div>
                            <img src={Graphimage} alt="Graph Image" className="w-full" />
                        </div>
                        <div className='w-full bg-[#ffff] mt-3 rounded-xl'>
                            <div className='flex justify-between'>
                                <div className='border-l-2 border-[#FF7A00] w-full md:w-52 ml-2 mt-2'>
                                    <p className='ml-2'>{LABELS.TotalVehicleManagement}</p>
                                    <img src={frame320image} alt="Frame 320 Image" className='ml-2' />
                                </div>
                                <img src={UserLogo} className='mr-3' alt="User Logo" />
                            </div>
                            <img src={cycleChart} alt="Cycle Chart" className="w-full" />
                        </div>
                    </div>
                </div>

                <div className="mt-6 gap-12 sm:mt-0 md:flex lg:block">
                    <div className="max-w-2xl">
                        <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                            {LABELS.VehicleManagement}
                        </h3>
                        <p className="mt-3 max-w-xl">
                            {LABELS.OurAIPoweredDispatchSolution_can_help_you_inManaging_theInformation_of_all_your_tractors_and_trailers_in_one_place}
                        </p>
                        <ul className='mt-6 list-none flex-col space-y-6'>
                            <li className='flex items-center'>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                {LABELS.report}
                            </li>
                            <li className='flex items-center'>
                                <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                {LABELS.tracking}
                            </li>
                            {showMore && (
                                <>
                                    <li className='flex items-center'>
                                        <FontAwesomeIcon icon={faCircleCheck} className='mr-2 text-2xl' />
                                        {LABELS.document}
                                    </li>

                                </>
                            )}
                        </ul>
                        <button
                            onClick={handleToggle}
                            className="mt-6 px-6 py-3.5 text-white bg-black rounded-full duration-150 hover:bg-indigo-500 active:bg-indigo-700"
                        >
                            {showMore ? LABELS.showLess : LABELS.seeMore}
                            <FontAwesomeIcon icon={showMore ? faArrowDown : faArrowRight} className="ml-2" />
                        </button>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default VehicleManagement