import React, { useEffect } from 'react'
import Billingimage from "../../assets/images/Accounting/billing.svg";
import BudgetReport from "../../assets/images/Accounting/report.svg";
import DriverSettlementimage from "../../assets/images/Accounting/driversettlements.svg";
import oneclickinvoice from "../../assets/images/Accounting/oneclick.svg"
import create99image from "../../assets/images/Accounting/create1099.svg";
import paystubprinting from "../../assets/images/Accounting/paystubprinting.svg";
import checkprinting from "../../assets/images/Accounting/checkprinting.svg";
import totalcostimage from "../../assets/images/Accounting/trackcosts.svg";
import { LABELS  as _LABELS} from '../../Language';


const LABELS=_LABELS.Accounting;
function Accounting() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
        <div className="relative max-w-2xl mx-auto sm:text-center">
          <div className="relative z-10">
            <h3 className="text-gray-800 text-2xl font-semibold sm:text-xl p-10">
             {LABELS.TheAccountingSOftwareWithEasytoUseTools}
            </h3>

          </div>
          <div className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
        </div>
      </div>
      <div className="sm:flex items-center max-w-screen  justify-center gap-x-10  p-10 ">
        <div className=" ">
          <div className="image object-center text-center flex justify-center ">
            <img src={Billingimage} alt='brokerage' className='w-96 h-64 ' />
          </div>
        </div>
        <div className="sm:w-1/2 p-5 ">
          <div className="text">
            <h2 class="my-4 font-bold text-3xl text-center sm:text-4xl ">{LABELS.FlexibleAccurateBilling}</h2>
            <p className="text-gray-700">
            {LABELS.VulcansolsSoftwareEliminatesalltheguessworkfrompayroll_and_invoicingYou_will_know_exactly_what_you_owe_your_employees_and_how_much_your_customers_owe_you_and_everything_will_be_broken_down}
            </p>
          </div>

        </div>
      </div>
      <div className="sm:flex items-center max-w-screen  justify-center gap-x-10 bg-slate-100 p-10 ">
        <div className="sm:w-1/2 p-5 ">
          <div className="text">
            <h2 class="my-4 font-bold text-3xl text-center sm:text-4xl ">{LABELS.BudgetReporting}</h2>
            <p className="text-gray-700">
             {LABELS.Customizable_software_to_meet_the_workflow_needs_of_nearly_any_organization}
            </p>
          </div>
        </div>
        <div className=" ">
          <div className="image object-center text-center flex justify-center">
            <img src={BudgetReport} alt='brokerage' className='w-96 h-64 ' />
          </div>
        </div>
      </div>
      <div className="sm:flex items-center max-w-screen  justify-center gap-x-10 p-10 ">
        <div className=" ">
          <div className="image object-center text-center flex justify-center ">
            <img src={DriverSettlementimage} alt='brokerage' className='w-96 h-64 ' />
          </div>
        </div>
        <div className="sm:w-1/2 p-5 ">
          <div className="text">
            <h2 class="my-4 font-bold text-3xl text-center sm:text-4xl ">{LABELS.DriverSettlements}</h2>
            <p className="text-gray-700">
              {LABELS.Eliminate_errors_and_cut_the_timeittakesto_generate_andpayyourstaff}
            </p>
          </div>
        </div>
      </div>

      <div className="sm:flex items-center max-w-screen  justify-center gap-x-10 bg-slate-100 p-10 ">
        <div className="sm:w-1/2 p-5 ">
          <div className="text">
            <h2 class="my-4 font-bold text-3xl text-center sm:text-4xl ">{LABELS.OneclickInvoice}</h2>
            <p className="text-gray-700">
             {LABELS.Vulcansols_Software_includes_accountingmodulesthatallows_you_to_quickly_send_edit_andcreate_invoices_with_one_click}
            </p>
          </div>
        </div>
        <div className=" ">
          <div className="image object-center text-center flex justify-center">
            <img src={oneclickinvoice} alt='brokerage' className='w-96 h-64 ' />
          </div>
        </div>
      </div>

      {/*  ......Create 1099 part----- */}

      <div className="sm:flex items-center max-w-screen  justify-center gap-x-10  p-10 ">
        <div className=" ">
          <div className="image object-center text-center flex justify-center ">
            <img src={create99image} alt='brokerage' className='w-96 h-64 ' />
          </div>
        </div>
        <div className="sm:w-1/2 p-5 ">
          <div className="text">
            <h2 class="my-4 font-bold text-3xl text-center sm:text-4xl ">{LABELS.Create1099}</h2>
            <p className="text-gray-700">
             {LABELS.VulcansolsSoftware_helps_you_to_quickly_compile_all_the_information_required_to_submit_government_forms_like1099_withtheclick_of_a_button}
            </p>
          </div>
        </div>
      </div>

      <div className="sm:flex items-center max-w-screen  justify-center gap-x-10 bg-slate-100 p-10 ">
        <div className="sm:w-1/2 p-5 ">
          <div className="text">
            <h2 class="my-4 font-bold text-3xl text-center sm:text-4xl ">{LABELS.TracktotalCosts}</h2>
            <p className="text-gray-700">
              {LABELS.VulcansolsSoftware_helps_you_to_generate_reports_with_one_click_of_a_button_and_you_track_all_your_expenses_and_earnings_at_one_place}
            </p>
          </div>
        </div>
        <div className=" ">
          <div className="image object-center text-center flex justify-cente">
            <img src={totalcostimage} alt='brokerage' className='w-96 h-64 ' />
          </div>
        </div>
      </div>
      <div className="sm:flex items-center max-w-screen  justify-center gap-x-10  p-10 ">
        <div className=" ">
          <div className="image object-center text-center flex justify-center ">
            <img src={checkprinting} alt='brokerage' className='w-96 h-64 ' />
          </div>
        </div>
        <div className="sm:w-1/2 p-5 ">
          <div className="text">
            <h2 class="my-4 font-bold text-3xl text-center sm:text-4xl ">{LABELS.CheckPrinting}</h2>
            <p className="text-gray-700">
             {LABELS.VulcansolsSoftware_allows_you_to_generate_all_your_reports_with_one_click_and_then_you_can_easily_download_and_print_each_and_every_report_for_your_record}
            </p>
          </div>
        </div>
      </div>

      <div className="sm:flex items-center max-w-screen  justify-center gap-x-10 bg-slate-100 p-10 ">
        <div className="sm:w-1/2 p-5 ">
          <div className="text">
            <h2 class="my-4 font-bold text-3xl text-center sm:text-4xl ">{LABELS.PaystubPrinting}</h2>
            <p className="text-gray-700">
              {LABELS.You_can_easily_generate_Pay_stubs_and_pay_checks_for_each_driver_by_selecting_one_or_multiple_loads_delivered_by_the_driver}
            </p>
          </div>
        </div>
        <div className=" ">
          <div className="image object-center text-center flex justify-center ">
            <img src={paystubprinting} alt='brokerage' className='w-96 h-64 ' />
          </div>
        </div>
      </div>
    </>
  )
}

export default Accounting
