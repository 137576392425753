import React from 'react'


const tableItems = [
    {
        feature: "Price",
        Standard: "$0.00",
        Premium: "$49.00/Driver",
        Enterprise: "$99.00/Driver"
    },
    {
        feature: "Support channel",
        Standard: "Email",
        Premium: "Email & phone",
        Enterprise: "Email, chat & phone"
    },
    {
        feature: "Hours of operation",
        Standard: "Business hours",
        Premium: "Business hours",
        Enterprise: "24/7"
    },
    {
        feature: "Support priority",
        Standard: "Standard",
        Premium: "High Priority",
        Enterprise: "Highest Priority"
    },
    {
        feature: "Response time",
        Standard: "< 24 Hours",
        Premium: "< 4 Hours",
        Enterprise: "< 30 Minutes"
    },
    {
        feature: "Driver training",
        Standard: "At additional fee",
        Premium: "Included",
        Enterprise: "Included"
    },
    {
        feature: "Compliance",
        Standard: "At additional fee",
        Premium: "At additional fee",
        Enterprise: "Included"
    },
    {
        feature: "Vehicle maintenance",
        Standard: "At additional fee",
        Premium: "At additional fee",
        Enterprise: "Included"
    },

]

function PlanInformation() {
  return (
    <div className="max-w-screen-xl mx-auto px-4 md:px-8">
            <div className="max-w-lg">
                <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
                Plan according your needs
                </h3>
                <p className="text-gray-600 mt-2">
                Customer Support Package
                </p>
            </div>
            <div className="mt-12 shadow-sm border rounded-lg overflow-x-auto">
                <table className="w-full table-auto text-sm text-left">
                    <thead className="bg-gray-50 text-gray-600 font-medium border-b">
                        <tr>
                            <th className="py-3 px-6">Features</th>
                            <th className="py-3 px-6">Standard</th>
                            <th className="py-3 px-6">Premium</th>
                            <th className="py-3 px-6">Enterprise</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 divide-y">
                        {
                            tableItems.map((item, idx) => (
                                <tr key={idx}>
                                    <td className="px-6 py-4 whitespace-nowrap font-bold text-black">{item.feature}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{item.Standard}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{item.Premium}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{item.Enterprise}</td>
                                    
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
               
            </div>
            <div className='mt-2'>
                    <p>*All plans are subjected to availability.</p>
                    <p>*Business hours - 9 Am to 5 Pm, Monday to Friday.</p>
                    <p>*Switching to higher plan may take up to 1 week, as we will need to scale resources.</p>

                </div>
        </div>
  )
}

export default PlanInformation