export const LABELS={
    ProductsDriverSafety: "Produits | Sécurité du Conducteur",
    Proactively_manage_safety:"Gérer proactivement la sécurité",
    Getstarted:"Commencer",
    ReliableAI:"IA Fiable",
    Collisiondetection:"Détection de Collision",
    PrivacyMode:"Mode de Confidentialité",
    See_how_it_works:"Voir comment ça marche ",


    FrequentlyAskedQuestions:"Questions Fréquemment Posées",
    Whyisdriversafetyimportantwhenusing_anELD:"Pourquoi la sécurité des conducteurs est-elle importante lors de l'utilisation d'un ELD?",
    How_do_ELDs_improve_compliance_with_HOS_regulations:"Comment les ELD améliorent-ils la conformité avec les réglementations HOS?",
    How_can_fleet_safety_beimproved:"Comment la sécurité de la flotte peut-elle être améliorée?",
    Whatare_the_benefits_of_fleet_safetyprogram:"Quels sont les avantages du programme de sécurité de la flotte?",
    Howdoes_a_dashcam_enhance_vehicle_security:"Comment une dashcam améliore-t-elle la sécurité des véhicules?",
    How_does_ELD_integration_benefit_fleet_operations:"Comment l'intégration des ELD bénéficie-t-elle aux opérations de la flotte?",
    FreshFindings:"Nouvelles Fraîches: Enquête auprès de 1 000 Leaders d'Opérations Physiques. 59 % Identifient les Coûts en Hausse comme Principale Préoccupation. L'IA Peut-Elle Être la Planche de Salut?",

    All_in_one_security_solution_for_fleet_protection_and_profits:"Solution de Sécurité Tout-en-un pour la Protection et les Profits de la Flotte",
    EfficientlyOverseeFleetSafetyAchieveTangibleResults_and_Gain_Valuable_Time_Back_in_Your_Schedule:"Surveillez Efficacement la Sécurité de la Flotte, Obtenez des Résultats Tangibles et Récupérez un Temps Précieux dans Votre Agenda. Améliorez la Prévention des Accidents et Soutenez la Responsabilité des Conducteurs avec une Technologie de Dash Cam à IA de Pointe.",
    DriveTowardsEnhancedFleetSafetyandReducedRisk_withVulcansols:"Avancez Vers une Sécurité de Flotte Améliorée et un Risque Réduit avec la Plateforme à IA de Vulcansols. Protégez Vos Opérations avec un Coaching en Temps Réel pour les Conducteurs, une Détection Avancée des Accidents, une Preuve Instantanée d'Exonération et des Mesures de Confidentialité Rigoureuses.",


    Answer1:"La sécurité des conducteurs est primordiale car les ELD aident à prévenir la fatigue des conducteurs en garantissant le respect des réglementations HOS, réduisant ainsi le risque d'accidents causés par des conducteurs surmenés.",
    Answer2:"Les ELD suivent automatiquement les heures de conduite, les périodes de repos et les pauses, garantissant que les conducteurs respectent les réglementations HOS et réduisant la probabilité de violations.",
    Answer3:"La sécurité de la flotte peut être améliorée en intégrant des technologies de sécurité avancées, en effectuant un entretien régulier des véhicules, en proposant une formation complète des conducteurs, en surveillant le comportement des conducteurs et en appliquant des protocoles de sécurité clairs.",
    Answer4:"Un programme de sécurité de flotte bien conçu apporte de nombreux avantages, notamment une réduction des accidents, des coûts associés inférieurs, une amélioration de la moral des conducteurs, une meilleure sécurité publique et le respect des normes réglementaires. De plus, il peut entraîner des primes d'assurance plus faibles, une efficacité opérationnelle accrue et un impact positif sur la réputation de l'entreprise.",
    Answer5:"En plus d'enregistrer pendant la conduite, les dashcams avec mode de stationnement peuvent capturer des incidents comme le vandalisme ou les tentatives de vol lorsque le véhicule est stationné.",
    Answer6:"L'intégration permet un partage de données simplifié, une meilleure planification des itinéraires, une gestion améliorée des conducteurs et une prise de décision améliorée basée sur une analyse de données exhaustive.",
}
