import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Grouplogo from "../../../assets/images/home/canva/9.png";
import Planlogo from "../../../assets/images/plan.png";
import png_transparent from "../../../assets/images/png-transparent-gps.svg";
import { LABELS as _LABELS } from "../../../Language";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import verticalbar from "../../../assets/images/verticalline.svg";

const LABELS = _LABELS.HomePage;

const useIsSmallDevice = () => {
  const [isSmallDevice, setIsSmallDevice] = useState(false);

  useEffect(() => {
    const checkSize = () => {
      setIsSmallDevice(window.innerWidth <= 740);
    };

    checkSize();
    window.addEventListener("resize", checkSize);

    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, []);

  return isSmallDevice;
};

function MainSection() {
  const isSmallDevice = useIsSmallDevice();
  return (
    <>
      <section className="relative max-w-screen-xl mx-auto px-4 md:px-8 ">
        <div className="absolute top-0 left-0 w-full h-full bg-white opacity-40"></div>
        <div className="relative z-10 gap-5 items-center lg:flex  p-12">
          <div className="flex-1 max-w-lg py-5 sm:mx-auto text-center lg:max-w-max lg:text-left mb-10 ">
            <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-gray-800 font-semibold tracking-tight">
              {LABELS.You_created_your_company_let_us_create_your_brand}
            </h3>
            <p className="text-gray-500 leading-relaxed mt-3">
              {
                LABELS.Our_mission_is_to_revolutionize_the_transport_industry_through_innovative_technology_solutions_enhancing_efficiency_safety_and_sustainability
              }
            </p>
            <div className="flex flex-col md:flex-row items-center mb-6 gap-4 p-4 w-full max-w-md mx-auto md:max-w-none md:w-auto">
              <button className="px-3 py-1.5 sm:px-4 sm:py-2 text-sm text-white duration-150 bg-indigo-600 rounded-full hover:bg-indigo-500 active:bg-indigo-700 ">
                {LABELS.Start_your_Free_Trail_Now}
                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </button>
              <Link
                to="/demo"
                className="px-3 py-1.5 text-sm text-gray-700 duration-100 border rounded-lg hover:border-indigo-600 active:shadow-lg"
              >
                {LABELS.Request_Demo}
                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </Link>
            </div>
          </div>

          {isSmallDevice ? (
            <div className="flex justify-center mt-5 w-full">
              <div className="relative w-96 p-2 ">
                <div className="flex gap-3 w-auto h-56  mx-auto">
                  <div className="h-52 w-56">
                    <img
                      src={Grouplogo}
                      className="w-full h-full object-fill"
                      alt="truckpng"
                    />
                  </div>
                  <div className="relative h-52 w-60 bg-[#F0F0F0] rounded-2xl p-2">
                    <p className="text-center text-xl font-bold overflow-hidden text-ellipsis">
                      <CountUp
                        start={0}
                        end={120000}
                        duration={5}
                        separator=","
                      />
                      +
                    </p>
                    <div className="mt-4">
                      <p className="text-[#5C5D5F] text-sm text-center leading-6">
                        {
                          LABELS.DrivingYourTransportBusinessForwardwithSmartTechnologySolutions
                        }
                      </p>
                      <div className="h-2 w-24 bg-neutral-200 dark:bg-neutral-600 ml-3 mt-3.5 overflow-hidden">
                        <div
                          className="h-2 bg-black"
                          style={{ width: "45%" }}
                        ></div>
                      </div>
                      <div className="absolute w-14 h-14 border-4 border-black rounded-full flex justify-center items-center transform -translate-x-32 -translate-y-1/4 top-0 left-1/2 -ml-6 bg-white">
                        <img src={png_transparent} alt="png_transparent" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 w-auto mx-auto">
                  <div className="bg-black text-white rounded-xl">
                    <div className="flex flex-row justify-items-center items-center p-6">
                      <div className="border-t-2 border-white w-12 mr-3"></div>
                      <p className="text-xs">AI-Powered Dispatch Solution</p>
                      <div className="border-t-2 border-white w-12 ml-3"></div>
                    </div>
                    <div className="flex flex-row gap-3">
                      <div className="w-52 ml-6">
                        <p className="text-base">
                          {LABELS.PrecisionDispatch_PoweredbyAi}
                        </p>
                      </div>
                      <div className="flex flex-row gap-3 p-2">
                        <img
                          src={verticalbar}
                          className="-mt-7"
                          alt="verticlebarimage"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="relative flex-1 mt-5   w-2/3 mx-auto ">
              <div className="flex  gap-3">
                <div className="h-64 w-72 ">
                  <img src={Grouplogo} className="w-72 h-64" alt="truckpng" />
                </div>
                <div className="h-64 w-64  bg-[#F0F0F0] rounded-2xl ">
                  <p className="text-center text-3xl p-6 font-bold">
                    <CountUp
                      start={0}
                      end={120000}
                      duration={3}
                      separator=","
                    />
                    +
                  </p>
                  <p className="ml-8 mr-8 text-[#5C5D5F] font-medium text-base ">
                    {
                      LABELS.DrivingYourTransportBusinessForwardwithSmartTechnologySolutions
                    }
                  </p>
                  <div className="h-2 w-48 bg-neutral-200 dark:bg-neutral-600 ml-6 mt-7">
                    <div
                      className="h-2 bg-black"
                      style={{ width: "45%" }}
                    ></div>
                  </div>
                  <div className="absolute w-24 h-24 border-8 border-black rounded-full flex justify-center items-center transform -translate-x-1/2 -translate-y-1/2 top-0 left-1/2 -ml-9 bg-white">
                    <img src={png_transparent} alt="png_transparent" />
                  </div>
                </div>
              </div>
              <div className=" mx-auto mt-2">
                <div className=" bg-black text-white rounded-xl">
                  <div className="flex flex-row justify-items-center items-center p-6">
                    <div className="border-t-2 border-white ... w-16 mr-3"></div>
                    <p className="text-xs">AI-Powered Dispatch Solution </p>
                    <div className="border-t-2 border-white ... w-16 ml-3"></div>
                  </div>
                  <div className="flex flex-row gap-3">
                    <div className="w-52 font-semibold  ml-6 ">
                      <p className="text-xl">
                        {LABELS.PrecisionDispatch_PoweredbyAi}
                      </p>
                    </div>
                    <div className=" flex flex-row gap-3">
                      <img
                        src={verticalbar}
                        className="-mt-10"
                        alt="verticlebarimage"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default MainSection;
