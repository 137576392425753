export const LABELS={
    carrier:"Transporteur",
    eldConnect:"Connexion ELD",
    LoadTracking:"Suivi des Chargements",
    LoadRouting:"Routage des Chargements",
    Ifta:"IFTA",
    Rates:"Tarifs",
    Settlements:"Règlements",
    Receivables:"Créances",
    WayMessaging:"Messagerie Bidirectionnelle",
    Notification:"Notification",
    LoadConfirmation:"Confirmation de Chargement",
    ProofOfDelivery:"Preuve de Livraison",
    LoadBoard:"Tableau des Chargements",
    letsTalk:"Discutons",

   Letconnectyour_existingELD_to_Vulcansols_to_track_HOS_location_at_realtime: "Connectons votre ELD existant à Vulcansols pour suivre l'emplacement des HOS en temps réel.",
   OneClick_enabled_disable_live_load_tracking:"Activez/désactivez le suivi en direct des chargements en un clic.",
   Automaticallycalculates_empty_and_loadedmile_based_on_vehicle_current_location_shipper_and_consignee:"Calcule automatiquement les miles à vide et chargés en fonction de l'emplacement actuel du véhicule, de l'expéditeur et du destinataire.",
   Automatically_calculates_states_miles_for_you_IFTA_tax_alongWithdispatchwith_thehelpofVehicleLocationShipperAndConsignee:"Calcule automatiquement les miles des États pour votre taxe IFTA en même temps que le dispatch, avec l'aide de la localisation du véhicule, de l'expéditeur et du destinataire.",
   StayCompetitiveWithourSpotMarketRateAnalyzerfor_a_comprehensiveLook_at_industry_trends:"Restez compétitif avec notre analyseur de tarifs du marché spot pour une vision complète des tendances de l'industrie.",
   Createdriversettlements_Paychecks_with_one_click:"Créez des règlements de conducteur (chèques de paie) en un clic.",
   DecideWhenAndHowYouWantTogetPaid_withoutthe_factoringservice_contracts_or_hiddenfees:"Décidez quand et comment vous souhaitez être payé, sans contrats de service de factorisation ni frais cachés.",
   WayMessaging_with_drivers_carriers_via_mobile_app_or_fall_backtoSMS_chat:"Messagerie bidirectionnelle avec les conducteurs/transporteurs via l'application mobile ou par SMS en cas de secours.",
   WebandPushNotificationforloads_at_shippercheckinout_deliveryetc:"Notification web et push pour les chargements, au check-in/out de l'expéditeur, à la livraison, etc.",
   Use_the_dependable_load_tracking_software_giving_you_handsfree_checkcalls:"Utilisez le logiciel de suivi de chargement fiable qui vous offre des appels de vérification mains libres.",
   MakingProofOfDeliveryseamlessdrivercandofromVulcansolsmobileappalongwithdocumentupload:"Facilitez la preuve de livraison, le conducteur peut le faire depuis l'application mobile d'Vulcansols avec le téléchargement de documents.",
   Withoneclickpostavailabletruckafterdelivery:"Publiez le camion disponible après la livraison en un clic.",

   Vulcansols_is_well_designed_TMS_for_carrier_to_ease_and_automate_day_to_day_activitiesStay_on_top_of_your_trucking_business_with_our_smart_artificialintelligencebasedcloudsolution:"Vulcansols est un TMS bien conçu pour les transporteurs afin de faciliter et d'automatiser les activités quotidiennes. Restez au sommet de votre entreprise de transport avec notre solution cloud intelligente basée sur l'intelligence artificielle.",
}
