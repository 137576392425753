export const LABELS={
    ProductsDriverSafety: "Productos | Seguridad del Conductor",
    Proactively_manage_safety:"Gestiona proactivamente la seguridad",
    Getstarted:"Empezar",
    ReliableAI:"IA Confiable",
    Collisiondetection:"Detección de Colisiones",
    PrivacyMode:"Modo de Privacidad",
    See_how_it_works:"Ver cómo funciona ",


    FrequentlyAskedQuestions:"Preguntas Frecuentes",
    Whyisdriversafetyimportantwhenusing_anELD:"¿Por qué es importante la seguridad del conductor al usar un ELD?",
    How_do_ELDs_improve_compliance_with_HOS_regulations:"¿Cómo mejoran los ELDs el cumplimiento de las regulaciones de HOS?",
    How_can_fleet_safety_beimproved:"¿Cómo se puede mejorar la seguridad de la flota?",
    Whatare_the_benefits_of_fleet_safetyprogram:"¿Cuáles son los beneficios del programa de seguridad de la flota?",
    Howdoes_a_dashcam_enhance_vehicle_security:"¿Cómo mejora una dashcam la seguridad del vehículo?",
    How_does_ELD_integration_benefit_fleet_operations:"¿Cómo beneficia la integración de ELD a las operaciones de la flota?",
    FreshFindings:"Descubrimientos Recientes: Encuestando a 1,000 Líderes de Operaciones Físicas. El 59% Identifica los Costos en Escalada como la Principal Preocupación. ¿Puede ser la IA la Salvación?",

    All_in_one_security_solution_for_fleet_protection_and_profits:"Solución de seguridad integral para protección y beneficios de flotas",
    EfficientlyOverseeFleetSafetyAchieveTangibleResults_and_Gain_Valuable_Time_Back_in_Your_Schedule:"Supervise Eficientemente la Seguridad de la Flota, Logre Resultados Tangibles y Recupere Tiempo Valioso en Su Agenda. Mejore la Prevención de Accidentes y Apoye la Responsabilidad del Conductor con Tecnología de Dash Cam de IA de Vanguardia.",
    DriveTowardsEnhancedFleetSafetyandReducedRisk_withVulcansols:"Avance Hacia una Mayor Seguridad de Flota y Menor Riesgo con la Plataforma de IA de Vanguardia de Vulcansols. Proteja Sus Operaciones con Coaching en Tiempo Real para el Conductor, Detección Avanzada de Accidentes, Evidencia Instantánea de Exoneración y Rigurosas Medidas de Privacidad.",


    Answer1:"La seguridad del conductor es primordial ya que los ELDs ayudan a prevenir la fatiga del conductor al garantizar el cumplimiento de las regulaciones de HOS, reduciendo el riesgo de accidentes causados por conductores sobrecargados.",
    Answer2:"Los ELDs rastrean automáticamente las horas de conducción, los periodos de descanso y las pausas, asegurando que los conductores cumplan con las regulaciones de HOS y reduciendo la probabilidad de violaciones.",
    Answer3:"La seguridad de la flota se puede mejorar integrando tecnologías de seguridad avanzadas, realizando un mantenimiento regular de los vehículos, brindando capacitación integral a los conductores, monitoreando el comportamiento de los conductores y aplicando protocolos de seguridad claros.",
    Answer4:"Un programa de seguridad de flota bien diseñado trae numerosos beneficios, incluyendo la reducción de accidentes, costos asociados más bajos, mejora de la moral del conductor, mayor seguridad pública y cumplimiento de estándares regulatorios. Además, puede llevar a primas de seguros más bajas, mayor eficiencia operativa e impacto positivo en la reputación de una empresa.",
    Answer5:"Además de grabar mientras se conduce, las dashcams con modo de estacionamiento pueden capturar incidentes como vandalismo o intentos de robo cuando el vehículo está estacionado.",
    Answer6:"La integración permite un intercambio de datos más eficiente, una mejor planificación de rutas, una gestión mejorada de conductores y una mejor toma de decisiones basada en análisis de datos exhaustivos.",
}
