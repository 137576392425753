export const LABELS={
    GPSFleetTracking:"Suivi GPS de Flotte",
    RealTimeGPSTracking:"Suivi GPS en Temps Réel",
    DailyRouteMaps:"Cartes de Route Quotidiennes",
    LiveAlertNotifications:"Notifications d'Alerte en Direct",
    CreateGeofences:"Créer des Géo-barrières",
    DriverBehaviour:"Comportement du Conducteur",
    ReportsAnalytics:"Rapports et Analytiques",

     
    WhyShouldYouUseReal_timeGPSTracking:"Pourquoi devriez-vous utiliser le suivi GPS en temps réel ?",
    Save_Money_Increase_Productivity_Improve_Service:"Économiser de l'argent. Augmentation de la productivité. Améliorer le service.",


    TrackinrealtimeEverything_isAccessed_over_the_web_and_mobile_APP:"Suivez en temps réel. Tout est accessible via le web et l'application mobile.",
    ViewalltheJourneysAndRoutesTakenon_a_day:"Visualisez tous les trajets et itinéraires pris dans une journée.",
    BealertedToJustthe_eventsyouConsiderImportant:"Soyez alerté seulement des événements que vous jugez importants.",
    CompleteControlandprotectyourassetsAndBusiness:"Contrôle complet et protection de vos actifs et de votre entreprise.",
    SeeSpeedingHarshAccelerationBrakingSharp_turn_unnecessary_idling_etc:"Voyez les excès de vitesse, les accélérations et freinages brusques, les virages serrés, les ralenties inutiles, etc.",
    DataRichreportsGenerate_analytics_to_help_saveyou_time_and_money:"Des rapports riches en données génèrent des analyses pour vous faire gagner du temps et de l'argent.",


    Vulcansols_is_continually_improving_to_meet_the_needs_of_todays_high_demand_for_trucking_and_brokerage_professionals_Vulcansols_is_designed_to_provide_advanced_features_of_Tracking_and_Remote_Immobilization_toourCustomers_that_keeps_theTrack_of_various_features_of_vehicles_like_ignitionSpeed_over_limitEtc:"Vulcansols s'améliore continuellement pour répondre aux besoins des professionnels du transport routier et du courtage d'aujourd'hui. Osrit est conçu pour fournir des fonctionnalités avancées de suivi et d'immobilisation à distance à nos clients, qui permettent de suivre diverses caractéristiques des véhicules telles que l'allumage, la vitesse limite, etc.",
}
